import { ErrorLayout } from "../../../components/layouts";
import { ErrorImage } from "../../../components/images";
import { Container, Typography } from "@mui/material";
import lightTheme from "../../../styles/theme/lightTheme";


const Error404 = () => {
  return (
    <Container
      maxWidth={"lg"}
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        rowGap: "20px"
      }}
    >
      <ErrorImage>Error 404</ErrorImage>
      <Typography
        sx={{
          fontFamily: "Century Gothic Bold",
          fontSize: 14,
          lineHeight: "150%",
          letterSpacing: "0.2px",
          color: lightTheme.palette.text.primary
        }}
      >
        Cette page est introuvable</Typography>
    </Container>

  )
}

export default Error404;