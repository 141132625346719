import React, { useEffect, useState } from 'react'
import { Box, Container, Skeleton, styled, Typography } from '@mui/material'
import { CustomCard } from '../../../../../components/cards'
import jobers1 from '../../../../../assets/images/jobers-1.svg'
import jobersHover1 from '../../../../../assets/images/jobers-1-hover.svg'
import jobers2 from '../../../../../assets/images/jobers-2.svg'
import jobersHover2 from '../../../../../assets/images/jobers-2-hover.svg'
import jobers3 from '../../../../../assets/images/jobers-3.svg'
import jobersHover3 from '../../../../../assets/images/jobers-3-hover.svg'
import { SectionLayoutBottom } from '../../../../../components/layouts'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'
import '../../../../../styles/components/_skeleton.scss'

const JobersCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  columnGap: '15px',
  overflowX: 'auto',
  marginLeft: '-25px',
  marginRight: '-25px',
  paddingLeft: '25px',
  paddingRight: '25px',

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
    columnGap: '30px',
  },
}))

export default function CommunityHome(data) {
  const [hover1, setHover1] = useState(false)
  const [hover2, setHover2] = useState(false)
  const [hover3, setHover3] = useState(false)
  const { t } = useTranslation()
  const [communitys, setCommunitys] = useState([])

  useEffect(() => {
    setCommunitys(data.commentRejoindre)
  }, [data.commentRejoindre])

  return (
    <SectionLayoutBottom>
      <Container
        maxWidth={'lg'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: '50px',
        }}
      >
        <>
          {communitys.map((community, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                rowGap: '50px',
              }}
            >
              {!community.titre ? (
                <Skeleton
                  variant={'text'}
                  animation={'wave'}
                  className={'skeleton-title'}
                />
              ) : (
                <Typography
                  variant={'h2'}
                  sx={{
                    textTransform: 'uppercase',
                    color: '#FFFFFF',
                    textAlign: 'center',
                  }}
                >
                  {community.titre}
                </Typography>
              )}

              {!community.detail ? (
                <Box>
                  <Skeleton
                    variant={'text'}
                    animation={'wave'}
                    className={'skeleton-text'}
                  />
                  <Skeleton
                    variant={'text'}
                    animation={'wave'}
                    className={'skeleton-text'}
                  />
                  <Skeleton
                    variant={'text'}
                    animation={'wave'}
                    className={'skeleton-text-50'}
                  />
                </Box>
              ) : (
                <Typography
                  variant={'body2'}
                  sx={{
                    color: '#FFFFFF',
                    width: { xs: '100%', lg: '60%' },
                    alignSelf: 'center',
                  }}
                >
                  <Markup content={community.detail} />
                </Typography>
              )}
            </div>
          ))}
        </>

        <JobersCardContainer>
          <CustomCard
            onMouseOver={() => setHover1(true)}
            onMouseOut={() => setHover1(false)}
            picture={hover1 ? jobersHover1 : jobers1}
            /*textRainbow={"basic"}*/
          >
            1. {t('home.community1')}
          </CustomCard>
          <CustomCard
            onMouseOver={() => setHover2(true)}
            onMouseOut={() => setHover2(false)}
            picture={hover2 ? jobersHover2 : jobers2}
            /*textRainbow={"basic"}*/
          >
            2. {t('home.community2')}
          </CustomCard>
          <CustomCard
            onMouseOver={() => setHover3(true)}
            onMouseOut={() => setHover3(false)}
            picture={hover3 ? jobersHover3 : jobers3}
            /*textRainbow={"rainbow"}*/
          >
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            3. {t('home.community3')}
          </CustomCard>
        </JobersCardContainer>
      </Container>
    </SectionLayoutBottom>
  )
}
