import React, { useEffect, useState } from 'react'
import { MasonryLayoutCompany } from '../../../../../components/layouts'
import { CustomButton } from '../../../../../components/buttons'
import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPhone,
  faCircleExclamation,
  faCircleCheck,
  faPen,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../../styles/theme/lightTheme'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'
import { Slide, ToastContainer, toast } from 'react-toastify'
import '../../../../../styles/components/_box.scss'
import BiBlockTable from '../../../../../components/tables/BiBlockTable'
import moment from 'moment/moment'
import { useNavigate, useParams } from 'react-router-dom'
import {
  faArrowLeft,
  faHome,
  faMapMarker,
} from '@fortawesome/pro-solid-svg-icons'
import { GedTable, OffreTable } from '../../../../../components/tables'
import {
  ChoiceOfferModal,
  EditDocuments,
  EditInfos,
  DeleteGeneralModal,
  EditBesoinEntreprise,
  EditEvent,
  EditList,
} from '../../../../../components/modals'
import { useForm } from 'react-hook-form'
import EditCommentaire from '../../../../../components/modals/EditCommentaire'
import BesoinTable from '../../../../../components/tables/BesoinTable'
import { ROLE_ENTREPRISE, VALEURS } from '../../../../../data'
import DetailBesoinEntreprise from '../../../../../components/modals/DetailBesoinEntreprise'
import OffreEntrepriseTable from '../../../../../components/tables/OffreEntrepriseTable'

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const TitleSection = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const ProfilContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '45px',
  justifyContent: 'space-between',
}))

const WrapperProfilText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '5px',
}))

const BannerProfil = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 40,
  backgroundColor: lightTheme.palette.primary.b6,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
}))

const WrapperBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const BannerProfilContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const ProspectCompanyProfil = () => {
  const [openEvent, setOpenEvent] = useState(false)
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [openBesoin, setOpenBesoin] = useState(false)
  const [openDetailBesoin, setOpenDetailBesoin] = useState(false)
  const [openCommentaire, setOpenCommentaire] = useState(false)
  const [openValeurs, setOpenValeurs] = useState(false)
  const [loadingInscription, setLoadingInscription] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  const [besoins, setBesoins] = useState([])
  const [besoin, setBesoin] = useState([])
  const [commentaire, setCommentaire] = useState([])
  const [dateRdv, setDateRdv] = useState([])
  const [dateRdvPasse, setDateRdvPasse] = useState([])
  const [isFile, setIsFile] = useState([])
  const [role, setRole] = useState('')
  const [event, setEvent] = useState({})
  let navigate = useNavigate()

  const params = useParams()

  const handleOpenEvent = (event) => {
    setEvent(event)
    setOpenEvent(true)
  }
  const handleCloseEvent = () => {
    setOpenEvent(false)
    fetchData()
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    //fetchData()
  }
  const handleOpenDoc = () => setOpenDoc(true)
  const handleCloseDoc = () => {
    setOpenDoc(false)
    //fetchData()
  }
  const handleOpenValeurs = () => setOpenValeurs(true)
  const handleCloseValeurs = () => {
    setOpenValeurs(false)
    //fetchData()
  }
  const handleOpenCommentaire = () => setOpenCommentaire(true)
  const handleCloseCommentaire = () => {
    setOpenCommentaire(false)
    //fetchData()
  }
  const handleOpenBesoin = () => {
    setOpenBesoin(true)
    setBesoin('')
  }
  const handleCloseBesoin = () => {
    setOpenBesoin(false)
    //fetchData()
  }
  const handleCloseDetailBesoin = () => {
    setOpenDetailBesoin(false)
    //fetchData()
  }
  const handleSaveBesoin = () => {
    setOpenBesoin(false)
    getBesoinsCompany(snapShot.entreprise?.id, page)
  }

  const handleOpenOffer = () => setOpenOffre(true)
  const handleCloseOffer = () => {
    setOpenOffre(false)
    //fetchData()
  }
  const handleSave = () => {
    fetchData()
    setOpen(false)
    setOpenDoc(false)
    setOpenBesoin(false)
    setOpenCommentaire(false)
    setOpenValeurs(false)
  }

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openDeleteBesoinModal, setOpenDeleteBesoinModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => removeFile()
  const handleCloseDeleteBesoinModal = () => setOpenDeleteBesoinModal(false)
  const handleConfirmDeleteBesoinModal = () => removeBesoin()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [fileDelete, setFileDelete] = useState()
  const [besoinDelete, setBesoinDelete] = useState()
  const [openOffre, setOpenOffre] = useState(false)
  const [offres, setOffres] = useState([])
  const [offreSelect, setOffreSelect] = useState('')
  const [offresSelect, setOffresSelect] = useState([])
  const [loadingOffer, setLoadingOffer] = useState(true)
  const [loadingBesoin, setLoadingBesoin] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const { handleSubmit } = useForm({ mode: 'onBlur' })
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const saveEvent = () => {
    role === ROLE_ENTREPRISE
      ? navigate(
          `/admin/gestion-client/profil-client-entreprise/${params.userId}`
        )
      : navigate(
          `/admin/gestion-client/profil-client-candidat/${params.userId}`
        )
  }

  useEffect(() => {
    if (params.userId) fetchData()
  }, [params.userId])

  const fetchData = async () => {
    try {
      let result = await Api.user.detail(params.userId)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const res = handling.data
        getBesoinsCompany(res.entreprise?.id, page)
        setSnapShot(res)
        setLoading(false)
        const currentDate = new Date()
        setDateRdv(
          res.entreprise?.calendriers
            .filter((item) => currentDate < new Date(item.dateDebut))
            .sort((a, b) => a.id - b.id)
        )
        setDateRdvPasse(
          res.entreprise?.calendriers
            .filter((item) => new Date(item.dateDebut) < currentDate)
            .sort((a, b) => a.id - b.id)
        )
        //setBesoin(res.entreprise?.besoin)
        setRole(res.role)
        setCommentaire(res.commentaire)
        let userOffres = []
        res.entrepriseOffres.map((userOffre, i) => {
          userOffres.push({
            id: userOffre.id,
            offre: `/api/offres_entreprises/${userOffre.offre.id}`,
            prix: userOffre.prix,
          })
        })
        setOffresSelect(userOffres)
        let docs = []
        //if (res.candidat?.cv) docs.push({ type: 'cv', nom: res.candidat?.cv })
        if (res.documents) {
          res.documents.map((doc, i) => {
            docs.push(doc)
          })
        }

        setIsFile(docs)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  useEffect(() => {
    getOffers()
  }, [])

  const getBesoinsCompany = async (idCompany, page) => {
    setLoadingBesoin(true)
    try {
      let result = await Api.besoinEntreprise.listWithFilter({
        entreprise: idCompany,
        page: page,
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingBesoin(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling
        setLoadingBesoin(false)
        setBesoins(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
      } else {
        setLoadingBesoin(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingBesoin(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const getOffers = async () => {
    try {
      let result = await Api.siteVitrine.getOffersEntreprise({})

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingOffer(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoadingOffer(false)
        setOffres(res)
      } else {
        setLoadingOffer(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingOffer(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const handleChangeSelect = (evt) => {
    const inputValue = evt.target.value
    setOffreSelect(inputValue)
  }

  const handleClickOffreEntreprise = (durreSelect, statusSelect) => {
    handleSubmit(onSubmit(durreSelect, statusSelect))
  }

  const onSubmit = async (durreSelect, statusSelect) => {
    setLoadingButton(true)

    let offresSelect = {
      offre: `/api/offres_entreprises/${offreSelect.id}`,

      prix: offreSelect[`prix${durreSelect}`]
        ? parseInt(offreSelect[`prix${durreSelect}`])
        : 0,
      prixPromo: offreSelect[`prix${durreSelect}`]
        ? parseInt(offreSelect[`prix${durreSelect}`])
        : 0,
      periode: parseInt(durreSelect),
      status: statusSelect,
      utilisateur: `/api/utilisateurs/${snapShot.id}`,
    }
    setOffresSelect(offresSelect)

    try {
      const result = await Api.offerUtilisateur.add(offresSelect, snapShot.role)

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        //setSnapShot(res);
        setLoadingButton(false)
        setOpenOffre(false)
        fetchData()
        toast.success('La proposition a bien été envoyée!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
    } catch (error) {
      setLoadingButton(false)
    }
    setLoadingButton(false)
  }

  const sendInscriptionPropos = async () => {
    setLoadingInscription(true)

    try {
      const result = await Api.user.edit(params.userId, {
        status: 'proposition_inscription',
      })
      const handling = handlingErrors(result)

      if (handling.ok) {
        toast.success('La proposition a été envoyé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
      setLoadingInscription(false)
    } catch (error) {
      setLoadingInscription(false)
    }
  }
  const handleEditBesoin = (besoin) => {
    setOpenBesoin(true)
    setBesoin(besoin)
  }
  const handleDetailBesoin = (besoin) => {
    setOpenDetailBesoin(true)
    setBesoin(besoin)
  }
  const handleRemoveBesoin = (idBesoin) => {
    setOpenDeleteBesoinModal(true)
    setBesoinDelete(idBesoin)
  }
  const handlePageChange = (event, page) => {
    setPage(page)
    getBesoinsCompany(snapShot.entreprise?.id, page)
  }
  const handleRemove = (idFile) => {
    setOpenDeleteModal(true)
    setFileDelete(idFile)
  }
  const removeBesoin = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.besoinEntreprise.delete(besoinDelete)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexBesoin = besoin.findIndex((bs) => bs.id === besoinDelete)

        if (indexBesoin > -1) {
          besoin.splice(indexBesoin, 1)
        }
        setBesoin(besoin)
        setOpenDeleteBesoinModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
  }
  const removeFile = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.document.delete(fileDelete)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexFile = isFile.findIndex((file) => file.id === fileDelete)

        if (indexFile > -1) {
          isFile.splice(indexFile, 1)
        }
        setIsFile(isFile)
        setOpenDeleteModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingDelete(false)
  }

  const handlePayementOffre = (offre) => {}
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <div style={{ width: '100%' }}>
      <Button
        sx={{
          margin: '10px',
        }}
        onClick={() =>
          navigate('/admin/gestion-prospect/liste-prospects-entreprises')
        }
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          fontSize={15}
          color={lightTheme.palette.primary.b2}
        />{' '}
        <Typography
          variant={'body2'}
          color={lightTheme.palette.primary.b2}
          textAlign={'right'}
          marginLeft={1}
        >
          {'Retour'}
        </Typography>
      </Button>
      <MasonryLayoutCompany
        type={'entreprise'}
        handleOpenDoc={handleOpenDoc}
        handleOpenBesoin={handleOpenBesoin}
        handleOpenCommentaire={handleOpenCommentaire}
        handleOpenOffer={handleOpenOffer}
        handleOpenValeurs={handleOpenValeurs}
        profil={
          <ProfilContainer>
            <TitleContainer>
              <TitleSection>Profil</TitleSection>
              <IconContainer onClick={handleOpen}>
                <FontAwesomeIcon
                  icon={faPen}
                  fontSize={15}
                  color={lightTheme.palette.primary.b2}
                />
              </IconContainer>
            </TitleContainer>
            <WrapperProfilText>
              <Typography
                sx={{
                  fontFamily: 'Century Gothic Bold',
                  fontSize: { xs: 20 },
                  lineHeight: '120%',
                  color: lightTheme.palette.text.primary,
                  textAlign: 'center',
                  display: { xs: 'none', lg: 'block' },
                  textTransform: 'uppercase',
                }}
              >
                {snapShot.entreprise?.raisonSocial}
              </Typography>
              <Typography
                variant={'subtitle2'}
                textAlign={'center'}
                textTransform={'capitalize'}
              >
                {snapShot.prenom} {snapShot.nom}
              </Typography>
              <Typography
                variant={'body2'}
                color={lightTheme.palette.grey.grey}
                textAlign={'center'}
              >
                {snapShot.entreprise?.fonctions?.titre}
              </Typography>
            </WrapperProfilText>
            <BannerProfilContainer>
              <WrapperBanner>
                {/* {snapShot.langues?.length > 0 && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faComment} />
                    <Typography>{snapShot.langues}</Typography>
                  </BannerProfil>
                )} */}
                <BannerProfil>
                  <FontAwesomeIcon icon={faMapMarker} />

                  <Typography
                    textAlign={'right'}
                    title={snapShot.adresse}
                    style={{
                      width: '90%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      lineHeight: '16px',
                    }}
                  >
                    {snapShot.adresse}
                  </Typography>
                </BannerProfil>
                <BannerProfil>
                  <FontAwesomeIcon icon={faHome} />
                  <Typography>
                    {snapShot.codePostal} / {snapShot.ville} / {snapShot.pays}
                  </Typography>
                </BannerProfil>
                <BannerProfil>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <Typography
                    textAlign={'right'}
                    title={snapShot.email}
                    style={{
                      width: '90%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {snapShot.email}
                  </Typography>
                </BannerProfil>
                <BannerProfil>
                  <FontAwesomeIcon icon={faPhone} />
                  <Typography>{snapShot.telephone}</Typography>
                </BannerProfil>
                {/* {snapShot.dateNaissance && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faCakeCandles} />
                    <Typography>{snapShot.dateNaissance}</Typography>
                  </BannerProfil>
                )} */}

                {/* {snapShot.statusMarital && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faRingsWedding} />
                    <Typography>{snapShot.statusMarital}</Typography>
                  </BannerProfil>
                )} */}
                {loadingInscription ? (
                  <CircularProgress />
                ) : (
                  <CustomButton
                    displayIcon={'none'}
                    handleClick={sendInscriptionPropos}
                  >
                    Proposer l'inscription
                  </CustomButton>
                )}
              </WrapperBanner>
            </BannerProfilContainer>
          </ProfilContainer>
        }
        besoinClient={
          besoins && (
            <BesoinTable
              besoins={besoins}
              loading={loadingBesoin}
              handleRemove={handleRemoveBesoin}
              handleEdit={handleEditBesoin}
              handleDetailBesoin={handleDetailBesoin}
              handlePageChange={handlePageChange}
              count={count}
              page={page}
              role={'ROLE_ADMIN'}
            />
          )
        }
        commentaireAdmin={
          <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
            <div style={{ whiteSpace: 'pre-line' }}>{commentaire}</div>
          </Typography>
        }
        offre={
          snapShot.entrepriseOffres && (
            <OffreEntrepriseTable
              offres={snapShot.entrepriseOffres}
              handlePayementOffre={handlePayementOffre}
            />
          )
        }
        ged={
          isFile ? (
            <GedTable
              files={isFile}
              dateFile={moment(snapShot.date)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY H:mm')}
              handleRemove={handleRemove}
            />
          ) : (
            <GedTable />
          )
        }
        rendezVous={
          <BiBlockTable
            rdvAVenir={dateRdv}
            rdvPasse={dateRdvPasse}
            heureAVenir={''}
            handleOpenEvent={handleOpenEvent}
            type={'entreprise'}
          />
        }
        feedback={dateRdv}
        /* relation={<BiBlockTable rdvAVenir={dateRelation} heureAVenir={''} />} */
        displayParams={true}
        displayCompetencesSection={'none'}
        competences={[]}
        displaySoftskilSection={'none'}
        softskils={[]}
        displayValeurSection={'display'}
        valeurs={snapShot.entreprise.valeurs}
      />

      {open && (
        <EditInfos
          open={open}
          onClose={handleClose}
          id={params.userId}
          onSave={handleSave}
          role={'ROLE_ADMIN'}
          type={role}
        />
      )}

      {openCommentaire && (
        <EditCommentaire
          open={openCommentaire}
          onClose={handleCloseCommentaire}
          onSave={handleSave}
          id={params.userId}
        />
      )}

      {openBesoin && (
        <EditBesoinEntreprise
          open={openBesoin}
          onClose={handleCloseBesoin}
          onSave={handleSaveBesoin}
          id={snapShot.entreprise?.id}
          besoin={besoin}
          role={'ROLE_ADMIN'}
        />
      )}

      {openDetailBesoin && (
        <DetailBesoinEntreprise
          open={openDetailBesoin}
          onClose={handleCloseDetailBesoin}
          besoin={besoin}
          role={'ROLE_ENTREPRISE'}
        />
      )}

      {openValeurs && (
        <EditList
          open={openValeurs}
          onClose={handleCloseValeurs}
          onSave={handleSave}
          id={snapShot.entreprise?.id}
          type={'valeurs'}
          values={snapShot.entreprise?.valeurs}
          datas={VALEURS['fr']}
          role={'entreprise'}
        />
      )}

      {openDoc && (
        <EditDocuments
          open={openDoc}
          onClose={handleCloseDoc}
          onSave={handleSave}
          id={params.userId}
          role={ROLE_ENTREPRISE}
        />
      )}

      {openOffre && (
        <ChoiceOfferModal
          open={openOffre}
          onClose={handleCloseOffer}
          items={offres}
          handleClick={handleSubmit(onSubmit)}
          handleClickOffreEntreprise={handleClickOffreEntreprise}
          value={offreSelect}
          handleChange={handleChangeSelect}
          loading={loadingButton}
          role={'ROLE_ADMIN'}
        />
      )}

      {openDeleteModal && (
        <DeleteGeneralModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleConfirmDeleteModal}
          loading={loadingDelete}
        />
      )}

      {openDeleteBesoinModal && (
        <DeleteGeneralModal
          open={openDeleteBesoinModal}
          onClose={handleCloseDeleteBesoinModal}
          onConfirm={handleConfirmDeleteBesoinModal}
          loading={loadingDelete}
        />
      )}

      {openEvent && (
        <EditEvent
          open={openEvent}
          onClose={handleCloseEvent}
          id={params.userId}
          //role={role}
          event={event}
          onSave={handleCloseEvent}
          showParams={true}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </div>
  )
}

export default ProspectCompanyProfil
