import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
  Autocomplete,
  TextField,
  Tabs,
  Tab,
  TableContainer,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { SelectInputBasic, TextInputClassic } from '../inputs'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { phoneRegExp } from '../../config'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faKey,
  faKeySkeleton,
} from '@fortawesome/pro-regular-svg-icons'
import { userLogout } from '../../store/slices/userAuthReducer'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import {
  countries,
  disponibilitesList,
  disponibleSousList,
  LANGUES,
  niveauLanguesList,
  ROLE_CANDIDAT,
  ROLE_ENTREPRISE,
  statusList,
  statusMaritalList,
} from '../../data'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import { faSquarePlus, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  minHeight: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const TablePanelBox = styled(TabPanel)(({ theme }) => ({
  margin: '20px 0',
  minHeight: 280,
  backgroundColor: 'rgb(227, 230, 255, 0.5)',
  '& .MuiBox-root': {
    padding: 0,
  },
}))

const BodyTab = styled(Tab)(({ theme }) => ({
  color: lightTheme.palette.grey.main,
  fontFamily: 'Century Gothic Bold',
  fontSize: 12,
  letterSpacing: '0.2px',
  lineHeight: '140%',

  '&.Mui-selected': {
    background: lightTheme.palette.primary.b6,
    color: lightTheme.palette.primary.main,
  },
}))

const EditInfos = ({
  open,
  onClose,
  onSave,
  handleOpenPassword,
  id,
  role,
  type,
}) => {
  const { t, i18n } = useTranslation()
  const proSchema = yup.object({
    prenom: yup.string().required(t('errorMessage.required')),
    nom: yup.string().required(t('errorMessage.required')),
    email: yup
      .string()
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.required')),
    telephone: yup
      .string()
      .required(t('errorMessage.required'))
      .matches(phoneRegExp, t('errorMessage.phone'))
      .nullable(),
    entreprise: yup.object({
      raisonSocial: yup.string().required(t('errorMessage.required')),
      //fonctions: yup.mixed().required(t('errorMessage.required')),
    }),
    /* dateNaissance: yup.string('Ce champ doit être une date valide.'), */
    /* statusMarital: yup.string().required(t('errorMessage.required')), */
    codePostal: yup.number().required(t('errorMessage.zipCodeValid')),
  })

  const parSchema = yup.object({
    prenom: yup.string().required(t('errorMessage.required')),
    nom: yup.string().required(t('errorMessage.required')),
    email: yup
      .string()
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.required')),
    telephone: yup
      .string()
      .required(t('errorMessage.required'))
      .matches(phoneRegExp, t('errorMessage.phone'))
      .nullable(),
    /*entreprise.raisonSocial: yup.string().required("Merci de renseigner ce champ."),*/
    /* dateNaissance: yup.string('Ce champ doit être une date valide.'), */
    /* statusMarital: yup.string().required(t('errorMessage.required')), */
    codePostal: yup.number().required(t('errorMessage.zipCodeValid')),
  })
  const [loadingInfos, setLoadingInfos] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingPoste, setLoadingPoste] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  const [statusMarital, setStatusMarital] = useState('celibataire')
  const dispatch = useDispatch()
  const [fonctions, setFonctions] = useState([])
  const [fonctionSelect, setFonctionSelect] = useState('1')
  const [dateValue, setDateValue] = useState(dayjs(new Date()).format('YYYY'))
  const [langues, setLangues] = useState([])
  const [dateChange, setDateChange] = useState(false)
  const [postes, setPostes] = useState([])
  const [posteSelect, setPosteSelect] = useState()
  const [posteOpen, setPosteOpen] = React.useState(false)
  const [errorsJob, setErrorsJob] = useState(false)
  const [openLangues, setOpenLangues] = useState(false)
  const [disponibilitesSelect, setDisponibilitesSelect] = useState(
    disponibilitesList[0]
  )
  const [disponibleSousSelect, setDisponibleSousSelect] = useState(
    disponibleSousList[0]
  )
  const [dateDisponibleValue, setDateDisponibleValue] = useState(new Date())
  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms (5 seconds)
  const [selectedCountry, setSelectedCountry] = useState({
    label: 'Switzerland',
  })
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [selectedCity, setSelectedCity] = useState('Genève')
  const [selectedState, setSelectedState] = useState()
  const [refresh, setRefresh] = useState(1)
  const [statusSelect, setStatusSelect] = useState(statusList[0])
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(type === ROLE_ENTREPRISE ? proSchema : parSchema),
  })

  useEffect(() => {
    type === ROLE_ENTREPRISE ? getFonctions() : getPostes()
    handleCountryChange(true, selectedCountry, '', '')
  }, [id])

  /*  useEffect(() => {
    console.log('langues', langues)
  }, [refresh]) */

  const handleCountryChange = async (fromFetch, country, city, etat) => {
    setSelectedCountry(country)
    try {
      const result = await Api.countriesCities.getCitiesByCountry(country.label)
      const res = handlingErrors(result)

      const result2 = await Api.countriesCities.getStatesByCountry(
        country.label
      )
      const res2 = handlingErrors(result2)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCities(res.data.data)
        if (fromFetch) {
          fetchData()
        }
        if (city === '') {
          setSelectedCity(res.data.data[0])
        } else {
          setSelectedCity(city)
        }
      }

      if (res2.ok) {
        setStates(res2.data.data.states)
        if (etat === '') {
          setSelectedState(res.data.data.states[0])
        } else {
          setSelectedState({ name: etat })
        }
      }
    } catch (error) {
      console.debug(error)
    }
  }

  const getPostes = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingPoste(true)

    try {
      const result = await Api.poste.listWithFilter({
        status: true,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setLoadingPoste(false)
        setPostes(res.data)
        const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1))
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingPoste(false)
  }

  const handleStatusMaritalSelect = (event) => {
    const inputValue = event.target.value
    setStatusMarital(inputValue)
  }

  const fetchData = async () => {
    setLoadingInfos(true)
    try {
      let result = await Api.user.detail(id)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        console.log('erreur')
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setStatusSelect(statusList.find((stat) => stat.id === res.status))
        if (res.statusMarital) setStatusMarital(res.statusMarital)
        if (res.entreprise?.fonctions?.id)
          setFonctionSelect(res.entreprise?.fonctions?.id)
        if (res.candidat?.poste) setPosteSelect(res.candidat?.poste)
        if (res.candidat?.typeDisponibilite)
          setDisponibilitesSelect(
            disponibilitesList[res.candidat?.typeDisponibilite - 1]
          )
        if (res.candidat?.disponibleSous)
          setDisponibleSousSelect(
            disponibleSousList[res.candidat?.disponibleSous - 1]
          )

        if (res.candidat?.disponible) {
          setDateDisponibleValue(res.candidat?.disponible)
        }

        if (res.candidat?.typeTempsTravail) {
          setSelectedTypeTempsTravail(res.candidat?.typeTempsTravail)
        }

        if (res.candidat?.tempPartiel) {
          setTempPartiel(res.candidat?.tempPartiel)
        }
        if (res.userLangues) setLangues(res.userLangues)

        if (res.dateNaissance) {
          setDateValue('01/01/' + res.dateNaissance)
        }
        setSelectedCountry({ label: res.pays })
        setSelectedCity(res.ville)
        setSelectedState({ name: res.etat })
        handleCountryChange(false, { label: res.pays }, res.ville, res.etat)
        reset(res)
        setLoadingInfos(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleFonctionSelect = (event) => {
    const inputValue = event.target.value
    setFonctionSelect(inputValue)
  }
  const changeDate = (dateValue) => {
    setDateValue(dateValue)
    setDateChange(true)
  }
  const changeDateDisponible = (dateValue) => {
    setDateDisponibleValue(dateValue)
  }
  const getFonctions = async () => {
    try {
      let result = await Api.fonction.listWithFilter({
        status: true,
      })
      setFonctions(result)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoading(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        setFonctions(res)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)

    const {
      candidat,
      userOffres,
      entrepriseOffres,
      roles,
      admin,
      date,
      lang,
      offre,
      parent,
      password,
      status,
      documents,
      calendriers,
      userAteliers,
      ...rest
    } = data

    if (dateValue)
      rest.dateNaissance = dateChange
        ? dateValue.format('YYYY')
        : dateValue.split('/')[1] +
          '/' +
          dateValue.split('/')[0] +
          '/' +
          dateValue.split('/')[2]
    if (role !== ROLE_ENTREPRISE && role !== ROLE_CANDIDAT) {
      rest.status = statusSelect.id
    } else {
      delete rest.status
    }

    if (type === ROLE_ENTREPRISE) {
      rest.entreprise.fonctions = `/api/fonctions/${fonctionSelect}`
      delete rest.entreprise.calendriers
    } else {
      rest.candidat = {}
      rest.candidat.id = snapShot.candidat.id
      rest.candidat.poste = `/api/postes/${posteSelect.id}`
      rest.candidat.typeDisponibilite = disponibilitesSelect.id
      rest.candidat.disponibleSous = disponibleSousSelect.id
      rest.candidat.disponible = dateDisponibleValue
      rest.candidat.typeTempsTravail = selectedTypeTempsTravail
      rest.candidat.tempPartiel = tempPartiel
    }

    rest.pays = selectedCountry.label
    rest.ville = selectedCity
    rest.etat = selectedState.name
    if (langues.length > 0) {
      rest.langues = langues.map((item) => item.langue)
      let lngs = []
      langues.map((lng, index) =>
        lngs.push({
          langue: lng.langue,
          niveau: lng.niveau,
          /* utilisateur: '/api/utilisateurs/' + id, */
        })
      )
      //console.log(lngs)
      rest.userLangues = lngs
    } else {
      rest.langues = []
      rest.userLangues = []
    }

    delete rest.role

    try {
      const result = await Api.user.edit(id, rest)
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
        onSave()
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenLangues = () => {
    setOpenLangues(!openLangues)
  }

  const handleAddLangue = () => {
    langues.push({ langue: LANGUES[24], niveau: niveauLanguesList[0].id })
    setLangues(langues)
    setRefresh(Math.random())
  }

  const theme = useTheme()
  const [val, setVal] = useState(0)
  const handleChange = (event, newValue) => {
    setVal(newValue)
  }

  const handleChangeIndex = (index) => {
    setVal(index)
  }

  const [selectedTypeTempsTravail, setSelectedTypeTempsTravail] =
    useState('plein')
  const handleChangeType = (event) => {
    const inputValue = event.target.value
    setSelectedTypeTempsTravail(inputValue)
  }

  const MAX = 100
  const MIN = 20
  const marks = [
    {
      value: MIN,
      label: MIN + '%',
    },
    {
      value: MIN + 10,
      label: '',
    },
    {
      value: MIN + 20,
      label: MIN + 20 + '%',
    },
    {
      value: MIN + 30,
      label: '',
    },
    {
      value: MIN + 40,
      label: MIN + 40 + '%',
    },
    {
      value: MIN + 50,
      label: '',
    },
    {
      value: MIN + 60,
      label: MIN + 60 + '%',
    },
    {
      value: MIN + 70,
      label: '',
    },
    {
      value: MAX,
      label: MAX + '%',
    },
  ]
  const [tempPartiel, setTempPartiel] = useState(MIN)
  const handleChangeTime = (_, newValue) => {
    setTempPartiel(newValue)
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
      style={{ overflow: 'auto' }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {t('box.title.generalInfos')}
        </Typography>
        <Box sx={{ width: '100%' }}>
          {type !== ROLE_ENTREPRISE && (
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={val}
                onChange={handleChange}
                indicatorColor="none"
                variant="fullWidth"
                aria-label="full width tabs example"
                sx={{
                  backgroundColor: lightTheme.palette.grey.light,
                }}
              >
                <BodyTab label={t('profile.generalInfos')} {...a11yProps(0)} />
                <BodyTab
                  label={t('profile.generalInfosMore')}
                  {...a11yProps(1)}
                />
                <BodyTab label={t('filter.languages')} {...a11yProps(2)} />
              </Tabs>
            </Box>
          )}
          <BoxContainer>
            {loadingInfos ? (
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 20,
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <form>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={val}
                  onChangeIndex={handleChangeIndex}
                  sx={{
                    '&::-webkit-scrollbar-track': {
                      background: lightTheme.palette.primary.b5,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: lightTheme.palette.primary.b3,
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: lightTheme.palette.primary.b2,
                    },
                  }}
                >
                  <TablePanelBox value={0} index={0} dir={theme.direction}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: 'flex',
                        flexDirection: {
                          xs: 'column',
                          sm: 'row',
                        },
                      }}
                    >
                      {role !== ROLE_ENTREPRISE && role !== ROLE_CANDIDAT && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>Statut</FormLabel>
                          <Autocomplete
                            disableClearable
                            value={statusSelect ? statusSelect : {}}
                            id="statusSelect"
                            sx={{ width: '100%', height: '45px' }}
                            onChange={(event, newValue) => {
                              setStatusSelect(newValue)
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(option) => option.titre}
                            options={statusList}
                            renderInput={(params) => (
                              <TextField
                                id="text-disponibilitesSelect"
                                {...params}
                                sx={{ backgroundColor: '#fff' }}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                          />
                          {errors.status && (
                            <Typography className={'error-message'}>
                              {errors.status.message}
                            </Typography>
                          )}
                        </Grid>
                      )}
                      {type === ROLE_ENTREPRISE && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <FormLabel className={'labelForm'}>
                              {t('box.companyName')}
                            </FormLabel>
                            <TextInputClassic
                              placeholder={t('box.companyName')}
                              type={'text'}
                              register={{
                                ...register('entreprise.raisonSocial'),
                              }}
                            />
                            {errors.entreprise?.raisonSocial && (
                              <Typography className={'error-message'}>
                                {errors.entreprise?.raisonSocial?.message}
                              </Typography>
                            )}
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <FormLabel className={'labelForm'}>
                              {t('contact.function')}
                            </FormLabel>
                            <SelectInputBasic
                              items={fonctions}
                              value={fonctionSelect}
                              /* register={{ ...register('entreprise.fonctions') }} */
                              handleChange={handleFonctionSelect}
                              type={'border'}
                            />
                          </Grid>
                        </>
                      )}

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <FormLabel className={'labelForm'}>
                          {t('contact.lastName')}
                        </FormLabel>
                        <TextInputClassic
                          placeholder={t('contact.lastName')}
                          width={'100%'}
                          register={{ ...register('nom') }}
                        />
                        {errors.nom && (
                          <Typography className={'error-message'}>
                            {errors.nom.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <FormLabel className={'labelForm'}>
                          {t('contact.firstName')}
                        </FormLabel>
                        <TextInputClassic
                          placeholder={t('contact.firstName')}
                          width={'100%'}
                          register={{ ...register('prenom') }}
                        />
                        {errors.prenom && (
                          <Typography className={'error-message'}>
                            {errors.prenom.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <FormLabel className={'labelForm'}>
                          {t('contact.number')}
                        </FormLabel>
                        <TextInputClassic
                          placeholder={t('contact.number')}
                          width={'100%'}
                          register={{ ...register('telephone') }}
                        />
                        {errors.telephone && (
                          <Typography className={'error-message'}>
                            {errors.telephone.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <FormLabel className={'labelForm'}>
                          {t('contact.emailAdress')}
                        </FormLabel>
                        <TextInputClassic
                          placeholder={t('contact.emailAdress')}
                          width={'100%'}
                          register={{ ...register('email') }}
                        />
                        {errors.email && (
                          <Typography className={'error-message'}>
                            {errors.email.message}
                          </Typography>
                        )}
                      </Grid>
                      {type !== ROLE_ENTREPRISE && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <FormLabel className={'labelForm'}>
                              {t('box.birthYear')}
                            </FormLabel>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={i18n.language}
                            >
                              <DatePicker
                                openTo="year"
                                views={['year']}
                                onChange={changeDate}
                                value={dateValue}
                                inputFormat={'YYYY'}
                                register={{ ...register('dateNaissance') }}
                                renderInput={({
                                  inputRef,
                                  inputProps,
                                  InputProps,
                                }) => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: '100%',
                                      height: 45,
                                      backgroundColor:
                                        lightTheme.palette.primary.b2,
                                      borderRadius: '5px',

                                      '& .css-1laqsz7-MuiInputAdornment-root': {
                                        height: '100%',
                                        width: '50px',
                                        margin: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',

                                        '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':
                                          {
                                            margin: 0,
                                            color: '#FFFFFF',
                                          },
                                      },
                                    }}
                                  >
                                    <input
                                      ref={inputRef}
                                      {...inputProps}
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '5px 0 0 5px',
                                        border: '1px solid #9E9E9E',
                                        padding: '10px 15px',
                                        fontFamily: 'Century Gothic',
                                        fontSize: 14,
                                        lineHeight: '140%',
                                        letterSpacing: '0.2px',
                                        color: lightTheme.palette.text.primary,
                                        backgroundColor: '#FFFFFF',
                                      }}
                                      register={{
                                        ...register('dateNaissance'),
                                      }}
                                    />
                                    {InputProps?.endAdornment}
                                  </Box>
                                )}
                              />
                            </LocalizationProvider>
                            {errors.dateNaissance && (
                              <Typography className={'error-message'}>
                                {errors.dateNaissance.message}
                              </Typography>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <FormLabel className={'labelForm'}>
                              {t('box.nationality')}
                            </FormLabel>
                            <TextInputClassic
                              placeholder={t('box.nationality')}
                              width={'100%'}
                              register={{ ...register('nationalite') }}
                            />
                          </Grid>
                        </>
                      )}
                      {type !== ROLE_ENTREPRISE && false && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('box.maritalStatus')}
                          </FormLabel>

                          <SelectInputBasic
                            items={statusMaritalList}
                            value={statusMarital}
                            handleChange={handleStatusMaritalSelect}
                            //register={{ ...register('statusMarital') }}
                            type={'border'}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <FormLabel className={'labelForm'}>
                          {t('contact.address')}
                        </FormLabel>
                        <TextInputClassic
                          placeholder={t('contact.address')}
                          width={'100%'}
                          register={{ ...register('adresse') }}
                        />
                        {errors.adresse && (
                          <Typography className={'error-message'}>
                            {errors.adresse.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <FormLabel className={'labelForm'}>
                          {t('contact.country')}
                        </FormLabel>
                        <Autocomplete
                          disableClearable
                          sx={{
                            width: '100%',
                            backgroundColor: '#fff',
                          }}
                          value={selectedCountry ? selectedCountry : {}}
                          onChange={(event, newValue) => {
                            handleCountryChange(false, newValue, '', '')
                          }}
                          id="country-select"
                          options={countries}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label} ({option.code})
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <FormLabel className={'labelForm'}>
                          {t('contact.state')}
                        </FormLabel>
                        <Autocomplete
                          sx={{
                            width: '100%',
                            backgroundColor: '#fff',
                          }}
                          value={selectedState ? selectedState : null}
                          onChange={(event, newValue) => {
                            setSelectedState(newValue)
                          }}
                          freeSolo={true}
                          id="state-select"
                          options={states}
                          autoHighlight
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              onChange={(event) => {
                                setSelectedState({ name: event.target.value })
                              }}
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <FormLabel className={'labelForm'}>
                          {t('contact.city')}
                        </FormLabel>
                        <Autocomplete
                          sx={{
                            width: '100%',
                            backgroundColor: '#fff',
                          }}
                          value={selectedCity ? selectedCity : null}
                          onChange={(event, newValue) => {
                            setSelectedCity(newValue)
                          }}
                          freeSolo={true}
                          id="country-select"
                          options={cities}
                          autoHighlight
                          //getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              {option}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              onChange={(event) => {
                                setSelectedCity(event.target.value)
                              }}
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <FormLabel className={'labelForm'}>
                          {t('contact.zipCode')}
                        </FormLabel>
                        <TextInputClassic
                          placeholder={t('contact.zipCode')}
                          width={'100%'}
                          register={{ ...register('codePostal') }}
                        />
                        {errors.codePostal && (
                          <Typography className={'error-message'}>
                            {errors.codePostal.message}
                          </Typography>
                        )}
                      </Grid>

                      {type === ROLE_ENTREPRISE && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <FormLabel className={'labelForm'}>
                            {t('box.businessPresentation')}
                          </FormLabel>
                          <TextareaInputClassic
                            placeholder={t('box.businessPresentationText')}
                            width={'100%'}
                            register={{
                              ...register('entreprise.presentation'),
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </TablePanelBox>
                  <TablePanelBox value={1} index={1} dir={theme.direction}>
                    {type !== ROLE_ENTREPRISE && (
                      <>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: 'flex',
                            flexDirection: {
                              xs: 'column',
                              sm: openLangues ? 'column' : 'row',
                            },
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <FormLabel className={'labelForm'}>
                              {t('filter.poste')}
                            </FormLabel>
                            <Autocomplete
                              disableClearable
                              value={posteSelect ? posteSelect : {}}
                              id="asynchronous-postes"
                              sx={{ width: '100%', height: '45px' }}
                              open={posteOpen}
                              onChange={(event, newValue) => {
                                setPosteSelect(newValue)
                              }}
                              onKeyDown={(event, newInputValue) => {
                                clearTimeout(typingTimer)
                                if (event.target.value) {
                                  typingTimer = setTimeout(function () {
                                    getPostes(event.target.value)
                                  }, doneTypingInterval)
                                }
                              }}
                              onOpen={() => {
                                setPosteOpen(true)
                              }}
                              onClose={() => {
                                setPosteOpen(false)
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.titre === value.titre
                              }
                              getOptionLabel={(option) =>
                                role !== ROLE_ENTREPRISE &&
                                role !== ROLE_CANDIDAT
                                  ? option.titre + ' (' + option.titreEn + ')'
                                  : i18n.language === 'fr'
                                  ? option.titre
                                  : option.titreEn
                              }
                              options={postes}
                              loading={loadingPoste}
                              renderInput={(params) => (
                                <TextField
                                  id="text-asynchronous-postes"
                                  {...params}
                                  sx={{ backgroundColor: '#fff' }}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {loadingPoste ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                            {errorsJob && (
                              <Typography className={'error-message'}>
                                {t('errorMessage.poste')}
                              </Typography>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <FormLabel className={'labelForm'}>
                              {t('box.available')}
                            </FormLabel>
                            <Autocomplete
                              disableClearable
                              value={
                                disponibilitesSelect ? disponibilitesSelect : {}
                              }
                              id="disponibilitesSelect"
                              sx={{ width: '100%', height: '45px' }}
                              onChange={(event, newValue) => {
                                setDisponibilitesSelect(newValue)
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.titre === value.titre
                              }
                              getOptionLabel={(option) => option.titre}
                              options={disponibilitesList}
                              renderInput={(params) => (
                                <TextField
                                  id="text-disponibilitesSelect"
                                  {...params}
                                  sx={{ backgroundColor: '#fff' }}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          {disponibilitesSelect.id === 2 && (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '5px',
                              }}
                            >
                              <FormLabel className={'labelForm'}>
                                {t('box.availableWhen')}
                              </FormLabel>
                              <Autocomplete
                                disableClearable
                                value={
                                  disponibleSousSelect
                                    ? disponibleSousSelect
                                    : {}
                                }
                                id="disponibleSousSelect"
                                sx={{ width: '100%', height: '45px' }}
                                onChange={(event, newValue) => {
                                  setDisponibleSousSelect(newValue)
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.titre === value.titre
                                }
                                getOptionLabel={(option) => option.titre}
                                options={disponibleSousList}
                                renderInput={(params) => (
                                  <TextField
                                    id="text-disponibleSousSelect"
                                    {...params}
                                    sx={{ backgroundColor: '#fff' }}
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          {disponibilitesSelect.id === 4 && (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '5px',
                              }}
                            >
                              <FormLabel className={'labelForm'}>
                                {t('box.availableWhen')}
                              </FormLabel>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={'fr'}
                              >
                                <DatePicker
                                  /* openTo="year"
                            views={['year']} */
                                  onChange={changeDateDisponible}
                                  value={dateDisponibleValue}
                                  inputFormat={'DD/MM/YYYY'}
                                  register={{ ...register('disponible') }}
                                  renderInput={({
                                    inputRef,
                                    inputProps,
                                    InputProps,
                                  }) => (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: 45,
                                        backgroundColor:
                                          lightTheme.palette.primary.b2,
                                        borderRadius: '5px',

                                        '& .css-1laqsz7-MuiInputAdornment-root':
                                          {
                                            height: '100%',
                                            width: '50px',
                                            margin: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',

                                            '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':
                                              {
                                                margin: 0,
                                                color: '#FFFFFF',
                                              },
                                          },
                                      }}
                                    >
                                      <input
                                        ref={inputRef}
                                        {...inputProps}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          borderRadius: '5px 0 0 5px',
                                          border: '1px solid #9E9E9E',
                                          padding: '10px 15px',
                                          fontFamily: 'Century Gothic',
                                          fontSize: 14,
                                          lineHeight: '140%',
                                          letterSpacing: '0.2px',
                                          color:
                                            lightTheme.palette.text.primary,
                                          backgroundColor: '#FFFFFF',
                                        }}
                                        register={{ ...register('disponible') }}
                                      />
                                      {InputProps?.endAdornment}
                                    </Box>
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                          )}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          ></Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <FormLabel className={'labelForm'}>
                              {t('box.timeWorkType')}
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                onChange={handleChangeType}
                                checked={selectedTypeTempsTravail === 'plein'}
                                value="plein"
                                control={<Radio />}
                                label={t('box.timeWorkType1')}
                              />
                              <FormControlLabel
                                onChange={handleChangeType}
                                checked={selectedTypeTempsTravail === 'partiel'}
                                value="partiel"
                                control={<Radio />}
                                label={t('box.timeWorkType2')}
                              />
                            </RadioGroup>
                          </Grid>
                          {selectedTypeTempsTravail === 'partiel' && (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '5px',
                              }}
                            >
                              <FormLabel className={'labelForm'}>
                                {t('box.timeWorkType2')}
                              </FormLabel>
                              <Box sx={{ width: '90%', margin: '0' }}>
                                <Slider
                                  marks={marks}
                                  step={10}
                                  value={tempPartiel}
                                  valueLabelDisplay="auto"
                                  min={MIN}
                                  max={MAX}
                                  onChange={handleChangeTime}
                                />
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                  </TablePanelBox>
                  <TablePanelBox value={2} index={2} dir={theme.direction}>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: 'flex',
                        maxHeight: 450,
                        overflow: 'auto',
                        flexDirection: {
                          xs: 'column',
                          sm: openLangues ? 'column' : 'row',
                        },
                      }}
                    >
                      {langues.map((lng, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            sx={{
                              padding: 1,
                            }}
                          >
                            <Autocomplete
                              sx={{
                                width: '100%',
                                backgroundColor: '#fff',
                              }}
                              options={LANGUES}
                              value={lng.langue}
                              freeSolo
                              onChange={(event, newValue) => {
                                langues[index].langue = newValue
                                setLangues(langues)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={t('box.addLanguages')}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            sx={{
                              padding: 1,
                            }}
                          >
                            <Autocomplete
                              disableClearable
                              value={niveauLanguesList[lng.niveau - 1]}
                              sx={{ width: '100%', height: '45px' }}
                              onChange={(event, newValue) => {
                                langues[index].niveau = newValue.id
                                setLangues(langues)
                                setRefresh(Math.random())
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.titre === value.titre
                              }
                              getOptionLabel={(option) => option.titre}
                              options={niveauLanguesList}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{ backgroundColor: '#fff' }}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={1}
                            sx={{
                              padding: 1,
                              alignContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              langues.splice(index, 1)
                              setLangues(langues)
                              setRefresh(Math.random())
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              fontSize={20}
                              style={{ marginTop: '10px' }}
                            />
                          </Grid>
                        </Grid>
                      ))}

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box
                          width={'100%'}
                          border={'1px dotted #666'}
                          padding={'10px !important'}
                          borderRadius={1}
                          sx={{ cursor: 'pointer' }}
                          onClick={handleAddLangue}
                        >
                          <FontAwesomeIcon
                            icon={faSquarePlus}
                            fontSize={20}
                            color={'#666'}
                            style={{ margin: '0 5px -2px 0' }}
                          />
                          {t('box.addOtherLanguage')}
                        </Box>
                      </Grid>
                    </Grid>
                  </TablePanelBox>
                </SwipeableViews>
              </form>
            )}
          </BoxContainer>
        </Box>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          <BorderButtonPurple
            displayIcon={'yes'}
            icon={<FontAwesomeIcon icon={faKey} />}
            handleClick={handleOpenPassword}
          >
            {t('button.editPassword')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleSubmit(onSubmit)}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditInfos
