import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material'
import lightTheme from '../../../styles/theme/lightTheme'
import { useTranslation } from 'react-i18next'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { Document, Page, pdfjs } from 'react-pdf'
import { FILE_URL } from '../../../config'
import { Markup } from 'interweave'

const Politiques = () => {
  const { t, i18n } = useTranslation()
  const [politique, setPolitique] = useState([])
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setLoading] = useState(false)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  useEffect(() => {
    getData(i18n.language)
  }, [i18n.language])

  const getData = async (lang) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.listWithFilter({
        section: ['pc'],
        lang: lang,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setPolitique(res.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) {
    return (
      <main style={{ marginTop: '80px' }}>
        <CircularProgress />
      </main>
    )
  }

  return (
    <main>
      <Container
        maxWidth={'lg'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '120px',
          paddingBottom: '120px',
          rowGap: '120px',
          flexDirection: 'column',
        }}
      >
        {politique.map((item) => (
          <>
            <Typography
              variant={'h1'}
              color={lightTheme.palette.text.primary}
              textAlign={'center'}
            >
              {item.titre}
            </Typography>
            {item.fichier ? (
              <div className="pdfDiv">
                <Document
                  loading={<CircularProgress />}
                  renderMode={'canvas'}
                  file={`${FILE_URL}${item.fichier?.split('/').pop()}`}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} renderMode="canvas" />
                </Document>
                <div>
                  <Button onClick={previousPage} disabled={pageNumber == 1}>
                    {'< '}
                    {t('button.previous')}
                  </Button>{' '}
                  {pageNumber || (numPages ? 1 : '--')}/{numPages || '--'}{' '}
                  <Button onClick={nextPage} disabled={pageNumber >= numPages}>
                    {t('button.next')}
                    {' >'}
                  </Button>
                </div>
              </div>
            ) : (
              <Box>
                <Typography variant={'body1'}>
                  <Markup content={item.detail} />
                </Typography>
              </Box>
            )}
          </>
        ))}
      </Container>
    </main>
  )
}

export default Politiques
