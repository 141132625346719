import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
  FormControl,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { SelectBasic, SelectInputBasic, TextInput } from '../inputs'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { phoneRegExp } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../../store/slices/userAuthReducer'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import { useTranslation } from 'react-i18next'
import { ROLE_ENTREPRISE } from '../../data'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import { duree, dureeEntre, horaires } from '../../pages/Admin/Calendar/config'

const TitleSection = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  color: lightTheme.palette.primary.main,
  letterSpacing: '0.2px',
  textTransform: 'uppercase',
}))

const SelectControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  /*justifyContent: "center",
  alignItems: "center",
  columnGap: "20px",*/
  /*flexDirection: "row",*/

  flexDirection: 'column',

  '& .day-label': {
    fontFamily: 'Century Gothic Bold',
    fontSize: 15,
    lineHeight: '140%',
    letterSpacing: '0.2px',
    color: lightTheme.palette.text.primary,
  },
}))

const SlotLayout = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '15px',
}))

const SlotContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const SlotSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '20px',
}))

const SlotBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '10px',
}))

const schema = yup.object({
  dureeRdv: yup.string().notOneOf([''], 'Merci de sélectionner une valeur'),
  dureeEntreRdv: yup
    .string()
    .notOneOf([''], 'Merci de sélectionner une valeur'),
  disponibilites: yup.mixed().required(),
})

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const EditDisponibilite = ({ open, onClose, onSave, openDelete, id, role }) => {
  const { t } = useTranslation()

  const [loadingInfos, setLoadingInfos] = useState(true)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  const [isDuree, setIsDuree] = useState('')
  const [isDureeEntre, setIsDureeEntre] = useState('')
  const [slotDefaultValue, setSlotDefaultValue] = useState([])

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const handleDureeSelect = (event) => {
    const inputValue = event.target.value
    setIsDuree(inputValue)
  }

  const handleDureeEntreSelect = (event) => {
    const inputValue = event.target.value
    setIsDureeEntre(inputValue)
  }

  const handleChangeSelect = (event) => {
    const inputValue = event.target.value
    const slotDay = event.target.dataset.day
    const slotTime = event.target.dataset.time
    slotDefaultValue.forEach((slot, index) => {
      if (slot[0] === slotDay) {
        switch (slotTime) {
          case '1':
            slot[1][0] = inputValue + '-' + slot[1][0].split('-')[1]
            break
          case '2':
            slot[1][0] = slot[1][0].split('-')[0] + '-' + inputValue
            break
          case '3':
            slot[1][1] = inputValue + '-' + slot[1][1].split('-')[1]
            break
          case '4':
            slot[1][1] = slot[1][1].split('-')[0] + '-' + inputValue
            break
        }
      }
    })
    setSlotDefaultValue(slotDefaultValue)
  }

  useEffect(() => {
    fetchData()
  }, [id, dispatch, reset])

  const fetchData = async () => {
    setLoadingInfos(true)
    try {
      let result = await Api.user.detail(id)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        console.log('erreur')
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setSlotDefaultValue(res.entreprise?.disponibilites)
        setIsDuree(res.entreprise?.dureeRdv)
        setIsDureeEntre(res.entreprise?.dureeEntreRdv)
        reset(res)
        setLoadingInfos(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const addDisponibilites = async () => {
    const result = await Api.company.edit(snapShot.entreprise.id, {
      disponibilites: slotDefaultValue,
      dureeRdv: parseInt(isDuree),
      dureeEntreRdv: parseInt(isDureeEntre),
    })
    const handling = handlingErrors(result)

    if (handling.status === 401) {
      toast.error(t('successMessage.sessionExpired'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
      return
    }

    if (handling.ok) {
      toast.success('Vos disponibilités ont bien été mise à jour !', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleCheck}
            fontSize={25}
            color={lightTheme.palette.success.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {t('box.title.editDisponibilite')}
        </Typography>
        <BoxContainer>
          {loadingInfos ? (
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <div style={{ width: '100%' }}>
              <form>
                <Box
                  className={'BoxContainer'}
                  sx={{
                    padding: '20px 25px',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '30px',
                    margin: '0px',
                    width: '100%',
                  }}
                >
                  <TitleSection>Choix temporelles</TitleSection>
                  <Grid container spacing={2} className={'gridContainer'}>
                    <Grid item xs={6}>
                      <SelectControl fullWidth>
                        <FormLabel className={'day-label'}>
                          Durée des RDV
                        </FormLabel>

                        <SelectBasic
                          items={duree}
                          defaultValue={snapShot?.entreprise?.dureeRdv}
                          handleChange={handleDureeSelect}
                        />
                      </SelectControl>
                    </Grid>
                    <Grid item xs={6}>
                      <SelectControl fullWidth>
                        <FormLabel className={'day-label'}>
                          Durée entre les RDV
                        </FormLabel>

                        <SelectBasic
                          items={dureeEntre}
                          defaultValue={snapShot?.entreprise?.dureeEntreRdv}
                          handleChange={handleDureeEntreSelect}
                        />
                      </SelectControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  className={'BoxContainer'}
                  sx={{
                    padding: '20px 25px',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '30px',
                    margin: '0px',
                    marginTop: '25px',
                    width: '100%',
                    maxHeight: '400px',
                    overflow: 'auto',
                  }}
                >
                  <TitleSection>
                    Jours et horaires de disponibilités
                  </TitleSection>
                  <Grid container spacing={3} className={'gridContainer'}>
                    {snapShot &&
                      snapShot.entreprise &&
                      snapShot.entreprise.disponibilites.map((item) => (
                        <Grid item xs={12}>
                          <SelectControl fullWidth>
                            <FormLabel className={'day-label'}>
                              {item[0]}
                            </FormLabel>
                            <SlotLayout>
                              <SlotContainer>
                                <Typography>matin :</Typography>
                                <SlotSection>
                                  <SlotBox>
                                    <FormControl>
                                      <SelectBasic
                                        items={horaires}
                                        dataDay={item[0]}
                                        dataTime={1}
                                        defaultValue={
                                          item[1][0].split('-')[0] &&
                                          item[1][0]
                                            .split('-')[0]
                                            .substring(0, 5)
                                        }
                                        handleChange={handleChangeSelect}
                                      />
                                    </FormControl>
                                    <p>à</p>
                                    <FormControl>
                                      <SelectBasic
                                        items={horaires}
                                        dataDay={item[0]}
                                        dataTime={2}
                                        defaultValue={
                                          item[1][0].split('-')[1] &&
                                          item[1][0]
                                            .split('-')[1]
                                            .substring(0, 5)
                                        }
                                        handleChange={handleChangeSelect}
                                      />
                                    </FormControl>
                                  </SlotBox>
                                </SlotSection>
                              </SlotContainer>

                              <SlotContainer>
                                <Typography>après-midi :</Typography>
                                <SlotSection>
                                  <SlotBox>
                                    <FormControl>
                                      <SelectBasic
                                        items={horaires}
                                        dataDay={item[0]}
                                        dataTime={3}
                                        defaultValue={
                                          item[1][1].split('-')[0] &&
                                          item[1][1]
                                            .split('-')[0]
                                            .substring(0, 5)
                                        }
                                        handleChange={handleChangeSelect}
                                      />
                                    </FormControl>
                                    <p>à</p>
                                    <FormControl>
                                      <SelectBasic
                                        items={horaires}
                                        dataDay={item[0]}
                                        dataTime={4}
                                        defaultValue={
                                          item[1][1].split('-')[1] &&
                                          item[1][1]
                                            .split('-')[1]
                                            .substring(0, 5)
                                        }
                                        handleChange={handleChangeSelect}
                                      />
                                    </FormControl>
                                  </SlotBox>
                                  {/* <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} /> */}
                                </SlotSection>
                              </SlotContainer>
                            </SlotLayout>
                          </SelectControl>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </form>
            </div>
          )}
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton displayIcon={'none'} handleClick={addDisponibilites}>
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditDisponibilite
