import React from 'react'
import imageHeader from '../../assets/images/header.jpg'
import { Box, styled, Typography } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'

const HeaderBox = styled(Box)(({ theme, isArticle }) => ({
  width: '100%',
  height: 200,
  borderRadius: '0 0 100px 100px',
  overflow: 'hidden',

  [theme.breakpoints.up('sm')]: {
    height: isArticle ? 360 : 420,
    borderRadius: isArticle ? '0 0 100px 100px' : '0 0 150px 150px',
  },
  [theme.breakpoints.up('lg')]: {
    height: isArticle ? 360 : 550,
    borderRadius: isArticle ? '0 0 100px 100px' : '0 0 175px 175px',
  },
}))

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',

  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}))

export default function HeaderCard({ title, img, alt, isArticle }) {
  return (
    <HeaderBox isArticle={isArticle}>
      <ImageContainer>
        <img src={img} alt={`Jobertech - ${alt}`} />
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            background: lightTheme.palette.grey.dark,
            opacity: '0.2',
            zIndex: 1,
          }}
        />
        <Typography
          component={'h1'}
          sx={{
            position: 'absolute',
            top: '35px',
            color: '#FFFFFF',
            zIndex: 1,
            textTransform: 'uppercase',
            width: '100%',
            textAlign: 'center',
            fontFamily: 'Century Gothic Bold',
            fontSize: { xs: 14, sm: 25, lg: 35 },
            lineHeight: '150%',
          }}
        >
          {title}
        </Typography>
      </ImageContainer>
    </HeaderBox>
  )
}
