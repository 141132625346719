import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../../../components/headings'
import { Box, styled } from '@mui/material'
import '../../../../../styles/components/_box.scss'
import { SearchInputBasic } from '../../../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faCirclePlus,
} from '@fortawesome/pro-regular-svg-icons'
import { ListTableProspects } from '../../../../../components/tables'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { useNavigate } from 'react-router-dom'
import { BorderButtonPurple } from '../../../../../components/buttons'
import AppPagination from '../../../../../components/paginations'
import { DeleteProfilModal } from '../../../../../components/modals'
import { debounce } from '../../../../../config'
import { ROLE_ENTREPRISE } from '../../../../../data'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const ListProspectCompany = () => {
  let navigate = useNavigate()
  const [prospectsCompany, setProspectsCompany] = useState([])
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [openCard, setOpenCard] = useState('')
  const [role, setRole] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [userId, setUserId] = useState()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)
  const handleConfirmDeleteModal = () => confirmRemoveProfil()
  const [page, setPage] = useState((sessionStorage.getItem('pageProspectCompany')) ? parseInt(sessionStorage.getItem('pageProspectCompany')) : 1)
  const [count, setCount] = useState(0)

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearchQuery(value)
  }, 800)

  useEffect(() => {
    getProspectCompany(page)
  }, [id, role, searchQuery])

  const handleRemoveProfil = (idUser) => {
    setOpenDeleteModal(true)
    setUserId(idUser)
  }

  const confirmRemoveProfil = async () => {
    setLoadingDelete(true)
    try {
      const result = await Api.user.delete(userId)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setOpenDeleteModal(false)
        const indexCandidate = prospectsCompany.findIndex(
          (candidate) => candidate.id === userId
        )
        if (indexCandidate > -1) {
          prospectsCompany.splice(indexCandidate, 1)
        }
        setProspectsCompany(prospectsCompany)
        setLoadingDelete(false)
      }

      if (res.status === 0) {
        getProspectCompany(page)
        setLoadingDelete(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      getProspectCompany(page)
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getProspectCompany(page)
    sessionStorage.setItem('pageProspectCompany', page)
  }

  const getProspectCompany = async (page) => {
    setLoading(true)
    setRole(ROLE_ENTREPRISE)

    let payload = {
      search: searchQuery,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    try {
      const result = await Api.user.listWithPaginationAndFilterAndOrderByDate({
        role: ROLE_ENTREPRISE,
        status: 'classic',
        page: page,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setProspectsCompany(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data.sort(( a, b) => ( a.date < b.date ? 1 : -1));
        console.log("sort :", sort) */
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleClickToProfil = (userId) => {
    navigate(`/admin/gestion-prospect/profil-prospect-entreprise/${userId}`)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading displayIconReturn={'none'}>
          Liste des prospects entreprises
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          <WrapperButton sx={{ columnGap: '15px' }}>
          <SearchInputBasic onChange={handleSearchChange} />
            <BlockButton>
              <BorderButtonPurple
                width={{ xs: 45, sm: 230, lg: 265 }}
                displayText={{ xs: 'none', sm: 'block' }}
                icon={<FontAwesomeIcon icon={faCirclePlus} fontSize={15} />}
                handleClick={() =>
                  navigate('/admin/gestion-client/ajouter-client-entreprise')
                }
              >
                Ajouter une entreprise
              </BorderButtonPurple>
            </BlockButton>
          </WrapperButton>
          <ListTableProspects
            type={'entreprise'}
            items={prospectsCompany}
            setId={setId}
            loading={loading}
            handleClick={handleClickToProfil}
            handleRemove={handleRemoveProfil}
            openCard={openCard}
          />

          <AppPagination
            handlePageChange={handlePageChange}
            count={count}
            page={page}
          />
        </Box>
      </div>

      <DeleteProfilModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
        loading={loadingDelete}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default ListProspectCompany
