import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  Typography,
  styled,
} from '@mui/material'
import { QuadrantCircleLayout } from '../../../../components/layouts'
import { SectionsHeading } from '../../../../components/headings'
import { useTranslation } from 'react-i18next'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import AppPagination from '../../../../components/paginations'
import { Markup } from 'interweave'
import lightTheme from '../../../../styles/theme/lightTheme'
import { debounce, truncate } from '../../../../config'
import logoArticle from '../../../../assets/images/logos/tinyLogo-article.svg'
import logoVideo from '../../../../assets/images/logos/tinyLogo-video.svg'
import logoPodcast from '../../../../assets/images/logos/tinyLogo-podcast.svg'
import { useNavigate } from 'react-router-dom'
import {
  SearchInputBasic,
  SelectInputObject,
} from '../../../../components/inputs'

const GridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
}))
const ImageContainer = styled(Box)(({ theme }) => ({
  height: 200,
  borderRadius: '25px 0 25px 0',
  position: 'relative',
  overflow: 'hidden',

  '& .image': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  [theme.breakpoints.up('sm')]: {
    height: 180,
  },

  [theme.breakpoints.up('lg')]: {},
}))

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export default function Articles() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [articles, setArticles] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  const [search, setSearch] = useState('')

  const [filter, setFilter] = useState([
    {
      id: 0,
      titre: t('filter.contentType'),
      filtre: ['article', 'video', 'podcast'],
    },
    { id: 1, titre: t('filter.article'), filtre: 'article' },
    { id: 2, titre: t('filter.video'), filtre: 'video' },
    { id: 3, titre: t('filter.podcast'), filtre: 'podcast' },
  ])

  const [order, setOrder] = useState([
    {
      id: 0,
      titre: t('filter.sortDateAsc'),
      filtre: 'datePublication',
      type: 'asc',
    },
    {
      id: 1,
      titre: t('filter.sortDateDesc'),
      filtre: 'datePublication',
      type: 'desc',
    },
    { id: 2, titre: t('filter.sortTitle'), filtre: 'titre', type: 'asc' },
  ])

  const [filterSelect, setFilterSelect] = useState(filter[0])
  const [orderSelect, setOrderSelect] = useState(order[0])

  useEffect(() => {
    // Observer les changements de langue
    const languageChangeHandler = () => {
      setFilter([
        {
          id: 0,
          titre: t('filter.contentType'),
          filtre: ['article', 'video', 'podcast'],
        },
        { id: 1, titre: t('filter.article'), filtre: 'article' },
        { id: 2, titre: t('filter.video'), filtre: 'video' },
        { id: 3, titre: t('filter.podcast'), filtre: 'podcast' },
      ])
      setOrder([
        {
          id: 0,
          titre: t('filter.sortDateAsc'),
          filtre: 'datePublication',
          type: 'asc',
        },
        {
          id: 1,
          titre: t('filter.sortDateDesc'),
          filtre: 'datePublication',
          type: 'desc',
        },
        { id: 2, titre: t('filter.sortTitle'), filtre: 'titre', type: 'asc' },
      ])

      /* setTimeout(() => {
        setFilterSelect(filter[0])
        setOrderSelect(order[0])
        alert('ccc')
      }, 2200) */
    }

    i18n.on('languageChanged', languageChangeHandler)
    return () => {
      i18n.off('languageChanged', languageChangeHandler)
    }
  }, [i18n])

  useEffect(() => {
    console.log(filter, filterSelect)
    setFilterSelect(filter.find((obj) => obj.id === filterSelect.id))
  }, [filter])
  useEffect(() => {
    setOrderSelect(order.find((obj2) => obj2.id === orderSelect.id))
  }, [order])

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  const handleFilterSelect = (event) => {
    const inputValue = event.target.value
    setFilterSelect(inputValue)
  }

  const handleOrderSelect = (event) => {
    const inputValue = event.target.value
    setOrderSelect(inputValue)
  }

  useEffect(() => {
    setPage(1)
    getArticle(1)
  }, [i18n.language, search, filterSelect, orderSelect])

  const handlePageChange = (event, page) => {
    setPage(page)
    getArticle(page)
  }

  const getArticle = async (page) => {
    let payload = {
      search: search,
      lang: i18n.language,
      type: filterSelect.filtre,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    setLoading(true)

    try {
      const result = await Api.siteVitrine.getArticles(
        page,
        payload,
        orderSelect.filtre,
        orderSelect.type
      )

      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        console.log(res.data['hydra:totalItems'])
        setArticles(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <main>
      <Box
        width={'100%'}
        component={'section'}
        sx={{
          padding: { xs: '110px 0 130px 0', md: '100px 0 ', lg: '80px 0' },
        }}
      >
        <Box width={'100%'} position={'relative'}>
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '40px',
            }}
          >
            <SectionsHeading type={'animation'}>
              {t('home.ourContent')}
            </SectionsHeading>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item lg={3} md={4} xs={12}>
                <SelectInputObject
                  items={filter}
                  value={filterSelect}
                  handleChange={handleFilterSelect}
                />
              </Grid>
              <Grid item lg={3} md={4} xs={12}>
                <SelectInputObject
                  items={order}
                  value={orderSelect}
                  handleChange={handleOrderSelect}
                />
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: { xs: 'none', md: 'none', lg: 'initial' },
                }}
              ></Grid>
              <Grid item lg={4} md={4} xs={12}>
                <SearchInputBasic
                  width={'100%'}
                  onChange={handleSearchChange}
                />
              </Grid>
            </Grid>

            {loading ? (
              <Grid container>
                <GridItem item lg={12} alignItems={'center'}>
                  <CircularProgress />
                </GridItem>
              </Grid>
            ) : (
              <Grid container spacing={5}>
                {articles.map((item, index) => (
                  <GridItem
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    key={index}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    {!item.banniere ? (
                      <Skeleton
                        variant={'rounded'}
                        animation={'wave'}
                        width={'100%'}
                        height={{ xs: 100, sm: 135 }}
                      />
                    ) : (
                      <ImageContainer>
                        <Typography
                          sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            background: '#FFFFFF',
                            zIndex: '1',
                            padding: '5px 10px',
                            fontSize: '12px !important',
                            textTransform: 'capitalize',
                          }}
                        >
                          <img
                            style={{
                              marginRight: '5px',
                              marginBottom: '-2px',
                            }}
                            src={
                              item.type == 'article'
                                ? logoArticle
                                : item.type == 'video'
                                ? logoVideo
                                : logoPodcast
                            }
                            alt={item.type}
                          />
                          {item.type}
                        </Typography>
                        <img
                          src={item.banniere}
                          /* alt={`Jobertech - ${item.photos[0].titre}`} */
                          className={'image'}
                        />
                      </ImageContainer>
                    )}

                    {!item.titre ? (
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        height={14}
                      />
                    ) : (
                      <Typography
                        sx={{
                          textAlign: 'left',
                          textTransform: 'uppercase',
                          fontFamily: 'Century Gothic Bold',
                          fontSize: { sm: 12, lg: 14 },
                          color: lightTheme.palette.text.primary,
                          lineHeight: '140%',
                          letterSpacing: '0.2px',
                        }}
                      >
                        {/* {item.id} --  */}
                        {item.titre}
                      </Typography>
                    )}

                    {!item.descriptif ? (
                      <Box width={'100%'} display={'block'}>
                        <Skeleton
                          variant={'text'}
                          animation={'wave'}
                          height={14}
                        />
                        <Skeleton
                          variant={'text'}
                          animation={'wave'}
                          width={'50%'}
                          height={14}
                        />
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          textAlign: 'justify',
                          fontSize: { sm: 10, lg: 12 },
                        }}
                      >
                        <Markup content={truncate(item.descriptif)} />
                      </Typography>
                    )}
                    <Grid
                      container
                      direction="row"
                      sx={{
                        height: 75,
                      }}
                    ></Grid>
                    <Grid
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        width: 'calc(100% - 40px)',
                      }}
                    >
                      <Typography
                        sx={{
                          textAlign: 'right',
                        }}
                      >
                        <Button
                          size="small"
                          onClick={() => {
                            navigate('/article/' + item.id)
                          }}
                          sx={{
                            width: 'fitContent',
                            fontWeight: 'bold',
                          }}
                        >
                          {t('button.more')}
                        </Button>
                      </Typography>
                      <hr
                        style={{
                          width: '100%',
                          border: '1px solid #F76E11',
                          borderColor:
                            item.type == 'article'
                              ? '#F76E11'
                              : item.type == 'video'
                              ? '#BF4FCE'
                              : '#96CEB4',
                        }}
                      />
                    </Grid>
                  </GridItem>
                ))}
                <Grid item xs={0} sm={12}>
                  <AppPagination
                    handlePageChange={handlePageChange}
                    count={count}
                    page={page}
                    pageSize={6}
                  />
                </Grid>
              </Grid>
            )}
          </Container>
        </Box>
      </Box>
    </main>
  )
}
