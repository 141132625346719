import { Box, styled, Typography } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import logo from "../../assets/images/logos/errorlogo.png";

const ContainerImage = styled(Box)(({theme}) => ({
  width: "100%",
  height: 130,
  borderRadius: "40px",
  backgroundColor: lightTheme.palette.primary.b4,
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
  padding: "25px",
  fontFamily: "Century Gothic Bold",
  fontSize: 40,
  lineHeight: "120%",
  letterSpacing: "0.05em",
  color: "#FFFFFF",
  textTransform: "uppercase",

  [lightTheme.breakpoints.up("md")]: {
    width: 500,
    height: 160,
    borderRadius: "60px",
    fontSize: 50,
  },
}));

const LogoError = styled(Box)(({theme}) => ({
  position: "absolute",
  top: "-60px",
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  width: 100,
  height: 100,
  margin: "0 auto",

  [lightTheme.breakpoints.up("md")]: {
    width: 150,
    height: 150,
    top: "-85px",
  },
}));

const ErrorImage = ({children}) => {
  return (
    <ContainerImage>
      <LogoError>
        <img src={logo} alt={"Jobertech"}/>
      </LogoError>
      {children}
    </ContainerImage>
  )
}

export default ErrorImage;