import { Box, Container, Typography } from '@mui/material'
import { CircleLayout, QuadrantCircleLayout } from './index'
import { Link, Outlet } from 'react-router-dom'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import meetupIcon from '../../assets/images/icons/meetup-black.png'
import logo from '../../assets/images/logos/logo.svg'

const InscriptionLayout = () => {
  return (
    <div className={'ContactLayout'}>
      <Box
        sx={{
          height: '100vh',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <QuadrantCircleLayout
          right={0}
          top={'-100px'}
          display={{ xs: 'none', sm: 'block' }}
        />
        <Container maxWidth={'lg'} sx={{ padding: '50px 0', height: '100%' }}>
          <Link
            to={'/'}
            style={{
              textAlign: 'center',
              display: 'block',
            }}
          >
            <Box
              component={'img'}
              src={logo}
              alt={'Jobertech'}
              sx={{ width: { xs: 180, lg: 'auto' } }}
            />
          </Link>
          <Outlet />
        </Container>
        <CircleLayout
          bottom={'-200px'}
          left={'-200px'}
          display={{ xs: 'none', sm: 'block' }}
        />
        <Container
          maxWidth={'lg'}
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 20,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: { xs: 'initial', lg: 'flex' },
              columnGap: '10px',
              alignItems: 'center',
            }}
          >
            <Typography
              variant={'caption'}
              color={lightTheme.palette.text.primary}
            >
              Si vous rencontrez un problème, vous pouvez nous contacter à
              l’adresse suivante :
            </Typography>
            <Box
              component={'span'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '10px',
              }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ fontSize: 15, color: lightTheme.palette.grey.dark }}
              />
              <a href={'mailto:contact@jobertech.com'} rel={'noreferrer'}>
                <Typography
                  variant={'caption'}
                  color={lightTheme.palette.text.primary}
                >
                  contact@jobertech.com
                </Typography>
              </a>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'initial', lg: 'flex' },
              columnGap: '10px',
            }}
          >
            <Typography
              variant={'caption'}
              color={lightTheme.palette.text.primary}
            >
              Suivez-nous sur les réseaux sociaux !
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '10px',
              }}
            >
              <a
                href={'https://www.linkedin.com/company/jobertechcorp/?'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  style={{ fontSize: 15, color: lightTheme.palette.grey.dark }}
                />
              </a>
              <a
                href={'https://www.instagram.com/jobertech/'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ fontSize: 15, color: lightTheme.palette.grey.dark }}
                />
              </a>
              <a
                href={'https://az-az.facebook.com/JoberTech/'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ fontSize: 15, color: lightTheme.palette.grey.dark }}
                />
              </a>
              <a
                href={
                  'https://www.youtube.com/channel/UC8YZ2pn8zIzx_GnJw_F6fdQ'
                }
                target={'_blank'}
                rel={'noreferrer'}
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  style={{ fontSize: 15, color: lightTheme.palette.grey.dark }}
                />
              </a>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  )
}

export default InscriptionLayout
