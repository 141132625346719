import React from "react";
import { Outlet } from "react-router-dom";
import { AppNavbar } from "../navbar";
import { Box, Container, Typography } from "@mui/material";
import { CircleLayout, QuadrantCircleLayout } from "./index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import lightTheme from "../../styles/theme/lightTheme";
import { faFacebook, faInstagram, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons";
import meetupIcon from "../../assets/images/icons/meetup-black.png";
import { useTranslation } from "react-i18next";

const ContactLayout = () => {
  const { t } = useTranslation();

  return (
    <div className={"ContactLayout"}>
      <AppNavbar />
      <Box
        sx={{
          height: { xs: "calc(100vh - 100px)", lg: "calc(100vh - 150px)" },
          position: "relative",
          overflow: "auto"
        }}
      >
        <QuadrantCircleLayout right={0} top={"-100px"} display={{ xs: "none", sm: "block" }} />
        <Outlet />
        <CircleLayout bottom={"-200px"} left={"-200px"} display={{ xs: "none", sm: "block" }} />
        <Container
          maxWidth={"lg"}
          sx={{
            position: "fixed",
            left: 0,
            right: 0,
            bottom: 0,
            padding: 2,
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#fff"
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: "10px",
              alignItems: "center"
            }}
          >
            <Typography variant={"caption"} color={lightTheme.palette.text.primary}>
              {t('contact.problems')}
            </Typography>
            <Box
              component={"span"}
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px"
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: 15, color: lightTheme.palette.grey.dark }} />
              <a href={"mailto:contact@jobertech.com"} rel={"noreferrer"}>
                <Typography variant={"caption"} color={lightTheme.palette.text.primary}>
                  contact@jobertech.com
                </Typography>
              </a>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: "10px"
            }}
          >
            <Typography variant={"caption"} color={lightTheme.palette.text.primary}>
              {t('contact.follow')}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <a href={"https://www.linkedin.com/company/jobertechcorp/?"} target={"_blank"} rel={"noreferrer"}>
                <FontAwesomeIcon icon={faLinkedinIn} style={{ fontSize: 15, color: lightTheme.palette.grey.dark }} />
              </a>
              <a href={"https://www.instagram.com/jobertech/"} target={"_blank"} rel={"noreferrer"}>
                <FontAwesomeIcon icon={faInstagram} style={{ fontSize: 15, color: lightTheme.palette.grey.dark }} />
              </a>
              <a href={"https://az-az.facebook.com/JoberTech/"} target={"_blank"} rel={"noreferrer"}>
                <FontAwesomeIcon icon={faFacebook} style={{ fontSize: 15, color: lightTheme.palette.grey.dark }} />
              </a>
              <a href={"https://www.youtube.com/watch?v=0jC2LzB7vfY"} target={"_blank"} rel={"noreferrer"}>
                <FontAwesomeIcon icon={faYoutube} style={{ fontSize: 15, color: lightTheme.palette.grey.dark }} />
              </a>

            </Box>
          </Box>

        </Container>
      </Box>

    </div>
  )
}

export default ContactLayout;