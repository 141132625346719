import React from "react";
import { Box, Skeleton, styled } from "@mui/material";
import smallLogo from "../../assets/images/logos/smallLogo.svg";

const CustomBox = styled(Box)(({theme, alignSelf, justifyContent, alignItems}) => ({
    display: "flex",
    alignItems: alignItems,
    flexDirection: "column",
    width: "100%",
    alignSelf: alignSelf,
}));

const ImagesContainer = styled(Box)(({theme}) => ({
    width: "max-content",
    position: "relative",
}));

const LogoContainer = styled(Box)(({theme}) => ({
    width: 18,
    height: 18,
    position: "absolute",
    top: "-24%",
    left: "40%",
    zIndex: 1,

    img: {
        position: "absolute",
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },

    [theme.breakpoints.up("sm")]: {
        width: 38,
        height: 38,
    },
    [theme.breakpoints.up("lg")]: {
        width: 47,
        height: 47,
    },
}))

const CirclesContainer = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: 70,
    height: 35,

    [theme.breakpoints.up("sm")]: {
        width: 160,
        height: 80
    },
    [theme.breakpoints.up("lg")]: {
        width: 200,
        height: 100
    },

}));

const CircleBox1 = styled(Box)(({theme}) => ({
    width: 35,
    height: 35,
    borderRadius: "100%",
    overflow: "hidden",
    border: "3px solid #FFFFFF",
    position: "absolute",
    left: "5%",
    backgroundColor: "#FFFFFF",

    img: {
        position: "absolute",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        backgroundColor: "rgba(51, 51, 51, 0.11)"
    },

    [theme.breakpoints.up("sm")]: {
        width: 80,
        height: 80,
    },
    [theme.breakpoints.up("lg")]: {
        width: 100,
        height: 100,
    },
}));

const CircleBox2 = styled(Box)(({theme}) => ({
    width: 35,
    height: 35,
    borderRadius: "100%",
    overflow: "hidden",
    border: "3px solid #FFFFFF",
    position: "absolute",
    right: "5%",
    zIndex: -1,
    backgroundColor: "#FFFFFF",

    img: {
        position: "absolute",
        width: "100%",
        height: "100%",
        objectFit: "cover",
        backgroundColor: "rgba(51, 51, 51, 0.11)",
    },



    [theme.breakpoints.up("sm")]: {
        width: 80,
        height: 80,
    },
    [theme.breakpoints.up("lg")]: {
        width: 100,
        height: 100,
    },
}));

export default function DoubleRoundedImage({picture1, picture2, alignSelf, alignItems, alt}) {
    return (
        <CustomBox alignSelf={alignSelf} alignItems={alignItems}>
            <ImagesContainer>
                <LogoContainer>
                    <img src={smallLogo} alt={"Jobertech"}/>
                </LogoContainer>
                <CirclesContainer>
                    <CircleBox1>
                        <img src={picture1} alt={alt}/>
                    </CircleBox1>
                    <CircleBox2>
                        <img src={picture2} alt={alt}/>
                    </CircleBox2>
                </CirclesContainer>
            </ImagesContainer>
        </CustomBox>
    )
}