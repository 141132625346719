import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, styled } from '@mui/material'
import { ListTable } from '../../../components/tables'
import { AdminHeading } from '../../../components/headings'
import { SearchInputBasic } from '../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faSliders,
} from '@fortawesome/pro-regular-svg-icons'
import { FiltersModal } from '../../../components/modals'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../styles/theme/lightTheme'
import { useNavigate, useParams } from 'react-router-dom'
import UserProfilHeading from '../../../components/headings/UserProfilHeading'
import DetailCandidat from '../../../components/modals/DetailCandidat'
import { useSelector } from 'react-redux'
import { debounce, hasPermission } from '../../../config'
import { useTranslation } from 'react-i18next'
import { acces_match } from '../../../data'
import NoAccess from '../Errors/NoAccess'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const BlockButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '15px',
  alignItems: 'center',
}))

const MatchingCandidate = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userData = useSelector((state) => state.userAuth.user)
  const hasPerm = hasPermission(userData.data, acces_match)
  const [open, setOpen] = useState(false)
  const [candidates, setCandidates] = useState([])
  const [loading, setLoading] = useState(false)
  const [openDetailCandidat, setOpenDetailCandidat] = useState(false)
  const [id, setId] = useState(null)
  const [candidat, setCandidat] = useState(null)
  const [search, setSearch] = useState('')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [userId, setUserId] = useState()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [besoinEntreprise, setBesoinEntreprise] = useState()

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  const handleDetailCandidat = (candidat) => {
    setOpenDetailCandidat(true)
    setCandidat(candidat)
  }
  const handleCloseDetailCandidat = () => {
    setOpenDetailCandidat(false)
    //fetchData()
  }
  const params = useParams()

  const handleCloseDetailCandidatAndRefresh = () => {
    setOpenDetailCandidat(false)
    getDetailBesoin(params.besoinId)
  }

  useEffect(() => {
    if (hasPerm) {
      getDetailBesoin(params.besoinId)
    } else {
      alertEntreprise()
    }
  }, [params.besoinId])

  const alertEntreprise = async () => {
    try {
      const result = await Api.company.alertEmail('type2')

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

    } catch (error) {}
  }

  const getDetailBesoin = async (besoinId) => {
    let payload = {
      search: search,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoading(true)

    try {
      const result = await Api.besoinEntreprise.matchingCandidatesByOffre(
        besoinId
      )

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setBesoinEntreprise(res.data.besoin)
        setCandidates(res.data.candidats)
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }
  const handleClickIcon = () => {
    navigate(`/user`)
  }
  const handleClickToProfil = (candidatId, candidat) => {
    handleDetailCandidat(candidat)
  }

  const handleRemoveProfil = (idUser) => {
    setOpenDeleteModal(true)
    setUserId(idUser)
  }

  /*if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );*/

  return (
    <>
      <div style={{ width: '100%' }}>
        {/*{!openCard ? (
          <>*/}
        <UserProfilHeading
          displayIconReturn={'display'}
          handleClickIcon={handleClickIcon}
        >
          {t('profile.entreprise.matching.title')} "
          {besoinEntreprise?.poste?.titre}"
        </UserProfilHeading>
        {!hasPerm ? (
          <NoAccess />
        ) : (
          <Box
            className={'BoxContainer'}
            sx={{
              padding: '30px',
              display: 'flex',
              flexDirection: 'column',
              rowGap: { xs: '30px', sm: '55px', lg: '70px' },
            }}
          >
            {/*<Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <SearchInputBasic
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
              </Box>*/}

            <WrapperButton sx={{ columnGap: '15px' }}>
              <SearchInputBasic onChange={handleSearchChange} />
              {/* <BlockButton>
              <BorderButtonPurple
                width={{ xs: 45, sm: 230, lg: 265 }}
                displayText={{ xs: 'none', sm: 'block' }}
                icon={<FontAwesomeIcon icon={faCirclePlus} fontSize={15} />}
                handleClick={() =>
                  navigate('/admin/gestion-client/ajouter-client-candidat')
                }
              >
                Ajouter un candidat
              </BorderButtonPurple>
            </BlockButton> */}
            </WrapperButton>
            <ListTable
              type={'candidat'}
              items={candidates}
              setId={setId}
              loading={loading}
              handleClick={handleClickToProfil}
              handleRemove={handleRemoveProfil}
              listeMatching={true}
              matchingPost={besoinEntreprise?.poste?.titre}
            />
          </Box>
        )}
        {/*</>
        ) : (
          <CandidateProfil id={id} />
        )}*/}
      </div>

      <FiltersModal open={open} onClose={handleClose} />

      {openDetailCandidat && (
        <DetailCandidat
          open={openDetailCandidat}
          onClose={handleCloseDetailCandidat}
          onSuccess={handleCloseDetailCandidatAndRefresh}
          candidat={candidat}
          companyId={besoinEntreprise?.entreprise?.id}
          besoinEntrepriseId={besoinEntreprise?.id}
          poste={besoinEntreprise?.poste?.titre}
        />
      )}
    </>
  )
}

export default MatchingCandidate
