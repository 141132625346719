import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { AdminHeading } from '../../../components/headings'
import lightTheme from '../../../styles/theme/lightTheme'
import {
  SelectBasic,
  SelectInput,
  SelectInputBasic,
} from '../../../components/inputs'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { duree, dureeEntre, horaires, days } from './config'
import {
  BorderButton,
  BorderButtonPurple,
  CustomButton,
} from '../../../components/buttons'

const TitleSection = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  color: lightTheme.palette.primary.main,
  letterSpacing: '0.2px',
  textTransform: 'uppercase',
}))

const SelectBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: '10px',
  width: '100%',
}))

const SelectControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  /*justifyContent: "center",
  alignItems: "center",
  columnGap: "20px",*/
  /*flexDirection: "row",*/

  flexDirection: 'column',

  '& .day-label': {
    fontFamily: 'Century Gothic Bold',
    fontSize: 15,
    lineHeight: '140%',
    letterSpacing: '0.2px',
    color: lightTheme.palette.text.primary,
  },
}))

const SlotLayout = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '15px',
}))

const SlotContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const SlotSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '20px',
}))

const SlotBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '10px',
}))

const schema = yup.object({
  dureeRdv: yup.string().notOneOf([''], 'Merci de sélectionner une valeur'),
  dureeEntreRdv: yup
    .string()
    .notOneOf([''], 'Merci de sélectionner une valeur'),
  disponibilites: yup.mixed().required(),
})

const Disponibilities = () => {
  const [loading, setLoading] = useState(true)
  const { data } = useSelector((state) => state.userAuth.user)
  const [snapShot, setSnapShot] = useState(null)
  const [isDuree, setIsDuree] = useState('')
  const [isDureeEntre, setIsDureeEntre] = useState('')

  /*const [isLundiHoraireStart, setIsLundiHoraireStart] = useState("");
  const [isLundiHoraireEnd, setIsLundiHoraireEnd] = useState("");
  const [isMardiHoraireStart, setIsMardiHoraireStart] = useState("");
  const [isMardiHoraireEnd, setIsMardiHoraireEnd] = useState("");
  const [isMercrediHoraireStart, setIsMercrediHoraireStart] = useState("");
  const [isMercrediHoraireEnd, setIsMercrediHoraireEnd] = useState("");
  const [isJeudiHoraireStart, setIsJeudiHoraireStart] = useState("");
  const [isJeudiHoraireEnd, setIsJeudiHoraireEnd] = useState("");
  const [isVendrediHoraireStart, setIsVendrediHoraireStart] = useState("");
  const [isVendrediHoraireEnd, setIsVendrediHoraireEnd] = useState("");
  const [isSamediHoraireStart, setIsSamediHoraireStart] = useState("");
  const [isSamediHoraireEnd, setIsSamediHoraireEnd] = useState("");
  const [isDimancheHoraireStart, setIsDimancheHoraireStart] = useState("");
  const [isDimancheHoraireEnd, setIsDimancheHoraireEnd] = useState("");*/
  /*const [isVisibleIcon1, setIsVisibleIcon1] = useState(true);
  const [isVisibleIcon2, setIsVisibleIcon2] = useState(false);
  const [showButton1, setShowButton1] = useState(true);
  const [showButton2, setShowButton2] = useState(true);*/

  const [slotDefaultValue, setSlotDefaultValue] = useState([])

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' })

  const handleDureeSelect = (event) => {
    const inputValue = event.target.value
    setIsDuree(inputValue)
  }

  const handleDureeEntreSelect = (event) => {
    const inputValue = event.target.value
    setIsDureeEntre(inputValue)
  }

  const handleChangeSelect = (event) => {
    const inputValue = event.target.value
    const slotDay = event.target.dataset.day
    const slotTime = event.target.dataset.time
    slotDefaultValue.forEach((slot, index) => {
      if (slot[0] === slotDay) {
        switch (slotTime) {
          case '1':
            slot[1][0] = inputValue + '-' + slot[1][0].split('-')[1]
            break
          case '2':
            slot[1][0] = slot[1][0].split('-')[0] + '-' + inputValue
            break
          case '3':
            slot[1][1] = inputValue + '-' + slot[1][1].split('-')[1]
            break
          case '4':
            slot[1][1] = slot[1][1].split('-')[0] + '-' + inputValue
            break
        }
      }
    })
    setSlotDefaultValue(slotDefaultValue)
  }

  useEffect(() => {
    getInfosAdmin()
  }, [data.id, reset])

  const getInfosAdmin = async () => {
    try {
      const result = await Api.user.detail(data.id)
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoading(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setSlotDefaultValue(res.admin.disponibilites)
        setIsDuree(res.admin.dureeRdv)
        setIsDureeEntre(res.admin.dureeEntreRdv)
        reset(res)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const addDisponibilites = async () => {
    const result = await Api.admin.editDisponibilites(data.id, {
      disponibilites: slotDefaultValue,
      dureeRdv: parseInt(isDuree),
      dureeEntreRdv: parseInt(isDureeEntre),
    })
    const handling = handlingErrors(result)

    if (handling.status === 401) {
      toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
      return
    }

    if (handling.ok) {
      toast.success('Vos disponibilités ont bien été mise à jour !', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleCheck}
            fontSize={25}
            color={lightTheme.palette.success.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  /* const fetchData = async () => {
    try {
      let result = await Api.user.detail(data.id)
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoading(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoading(false)
      } else {
        setLoading(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    fetchData()
  }, [data.id]) */

  const userData = useSelector((state) => state.userAuth.user)
  if (userData.data.role !== 'ROLE_SUPER_ADMIN')
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        Désolé, vous n'êtes pas autorisé à accéder à cette page
      </Box>
    )

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading>Disponibilités</AdminHeading>
        <form>
          <Box
            className={'BoxContainer'}
            sx={{
              padding: '20px 25px',
              display: 'flex',
              flexDirection: 'column',
              rowGap: { xs: '30px', sm: '55px', lg: '70px' },
            }}
          >
            <TitleSection>Choix temporelles</TitleSection>
            <Grid container spacing={2} className={'gridContainer'}>
              <Grid item xs={3}>
                <SelectControl fullWidth>
                  <FormLabel className={'day-label'}>Durée des RDV</FormLabel>
                  {snapShot &&
                    snapShot.admin &&
                    snapShot.admin.dureeEntreRdv && (
                      <SelectBasic
                        items={duree}
                        defaultValue={snapShot.admin.dureeRdv}
                        handleChange={handleDureeSelect}
                      />
                    )}
                </SelectControl>
              </Grid>
              <Grid item xs={3}>
                <SelectControl fullWidth>
                  <FormLabel className={'day-label'}>
                    Durée entre les RDV
                  </FormLabel>

                  {snapShot &&
                    snapShot.admin &&
                    snapShot.admin.dureeEntreRdv && (
                      <SelectBasic
                        items={dureeEntre}
                        defaultValue={snapShot.admin.dureeEntreRdv}
                        handleChange={handleDureeEntreSelect}
                      />
                    )}
                </SelectControl>
              </Grid>
            </Grid>
          </Box>

          <Box
            className={'BoxContainer'}
            sx={{
              padding: '20px 25px',
              display: 'flex',
              flexDirection: 'column',
              rowGap: { xs: '30px', sm: '55px', lg: '70px' },
              marginTop: '25px',
            }}
          >
            <TitleSection>Jours et horaires de disponibilités</TitleSection>
            <Grid container spacing={3} className={'gridContainer'}>
              {snapShot &&
                snapShot.admin &&
                snapShot.admin.disponibilites.map((item) => (
                  <Grid item xs={6}>
                    <SelectControl fullWidth>
                      <FormLabel className={'day-label'}>{item[0]}</FormLabel>
                      <SlotLayout>
                        <SlotContainer>
                          <Typography>matin :</Typography>
                          <SlotSection>
                            <SlotBox>
                              <FormControl>
                                <SelectBasic
                                  items={horaires}
                                  dataDay={item[0]}
                                  dataTime={1}
                                  defaultValue={
                                    item[1][0].split('-')[0] &&
                                    item[1][0].split('-')[0].substring(0, 5)
                                  }
                                  handleChange={handleChangeSelect}
                                />
                              </FormControl>
                              <p>à</p>
                              <FormControl>
                                <SelectBasic
                                  items={horaires}
                                  dataDay={item[0]}
                                  dataTime={2}
                                  defaultValue={
                                    item[1][0].split('-')[1] &&
                                    item[1][0].split('-')[1].substring(0, 5)
                                  }
                                  handleChange={handleChangeSelect}
                                />
                              </FormControl>
                            </SlotBox>

                            {/* <Box onClick={handleChangeIcon}>
                          {showIcon ? (
                            <FontAwesomeIcon
                              icon={faCircleXmark}
                              style={{
                                fontSize: 33,
                                color: lightTheme.palette.primary.b2,
                                cursor: "pointer"
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCirclePlus}
                              style={{
                                fontSize: 33,
                                color: lightTheme.palette.primary.b3,
                                cursor: "pointer"
                              }}
                            />
                          )}
                        </Box> */}
                          </SlotSection>
                        </SlotContainer>

                        <SlotContainer>
                          <Typography>après-midi :</Typography>
                          <SlotSection>
                            <SlotBox>
                              <FormControl>
                                <SelectBasic
                                  items={horaires}
                                  dataDay={item[0]}
                                  dataTime={3}
                                  defaultValue={
                                    item[1][1].split('-')[0] &&
                                    item[1][1].split('-')[0].substring(0, 5)
                                  }
                                  handleChange={handleChangeSelect}
                                />
                              </FormControl>
                              <p>à</p>
                              <FormControl>
                                <SelectBasic
                                  items={horaires}
                                  dataDay={item[0]}
                                  dataTime={4}
                                  defaultValue={
                                    item[1][1].split('-')[1] &&
                                    item[1][1].split('-')[1].substring(0, 5)
                                  }
                                  handleChange={handleChangeSelect}
                                />
                              </FormControl>
                            </SlotBox>
                            {/* <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} /> */}
                          </SlotSection>
                        </SlotContainer>
                      </SlotLayout>
                    </SelectControl>
                  </Grid>
                ))}
              {/*<Grid item xs={6}>
                <SelectControl fullWidth>
                  <FormLabel className={"day-label"}>Mardi</FormLabel>
                  <SlotLayout>
                    <SlotContainer>
                      <Typography>matin :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>

                    <SlotContainer>
                      <Typography>après-midi :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>
                  </SlotLayout>
                </SelectControl>
              </Grid>

              <Grid item xs={6}>
                <SelectControl fullWidth>
                  <FormLabel className={"day-label"}>Mercredi</FormLabel>
                  <SlotLayout>
                    <SlotContainer>
                      <Typography>matin :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>

                    <SlotContainer>
                      <Typography>après-midi :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>
                  </SlotLayout>
                </SelectControl>
              </Grid>

              <Grid item xs={6}>
                <SelectControl fullWidth>
                  <FormLabel className={"day-label"}>Jeudi</FormLabel>
                  <SlotLayout>
                    <SlotContainer>
                      <Typography>matin :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>

                    <SlotContainer>
                      <Typography>après-midi :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>
                  </SlotLayout>
                </SelectControl>
              </Grid>

              <Grid item xs={6}>
                <SelectControl fullWidth>
                  <FormLabel className={"day-label"}>vendredi</FormLabel>
                  <SlotLayout>
                    <SlotContainer>
                      <Typography>matin :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>

                    <SlotContainer>
                      <Typography>après-midi :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>
                  </SlotLayout>
                </SelectControl>
              </Grid>*/}

              {/*<Grid item xs={6}>
                <SelectControl fullWidth>
                  <FormLabel className={"day-label"}>Samedi</FormLabel>
                  <SlotLayout>
                    <SlotContainer>
                      <Typography>matin :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>

                    <SlotContainer>
                      <Typography>après-midi :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>
                  </SlotLayout>
                </SelectControl>
              </Grid>

              <Grid item xs={6}>
                <SelectControl fullWidth>
                  <FormLabel className={"day-label"}>Dimanche</FormLabel>
                  <SlotLayout>
                    <SlotContainer>
                      <Typography>matin :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>

                    <SlotContainer>
                      <Typography>après-midi :</Typography>
                      <SlotSection>
                        <SlotBox>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                          <p>à</p>
                          <FormControl>
                            <SelectInput
                              type={"border"}
                              items={horaires}
                              width={110}
                              value={isLundiHoraireStart}
                              handleChange={handleLundiStartHoraireSelect}
                            />
                          </FormControl>
                        </SlotBox>
                        <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: 33, color: lightTheme.palette.primary.b2}} />
                      </SlotSection>
                    </SlotContainer>
                  </SlotLayout>
                </SelectControl>
              </Grid>*/}
            </Grid>
          </Box>
          <Box
            className={'BoxContainer'}
            sx={{
              padding: '20px 25px',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '25px',
            }}
          >
            <Box
              sx={{
                width: '50%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                columnGap: '15px',
              }}
            >
              <BorderButtonPurple
                type={'basic'}
                displayIconLeft={'none'}
                displayIconRight={'none'}
                width={{ xs: 100, sm: 125 }}
                height={45}
              >
                Annuler
              </BorderButtonPurple>
              {loading ? (
                <CircularProgress />
              ) : (
                <CustomButton
                  displayIcon={'none'}
                  handleClick={addDisponibilites}
                >
                  Enregistrer mes disponibilités
                </CustomButton>
              )}
            </Box>
          </Box>
        </form>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default Disponibilities
