import { Box, CircularProgress, Container, styled } from '@mui/material'
import Slider from 'react-slick'
import { useEffect, useState } from 'react'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'
import { FILE_URL } from '../../../../../config'
import { SectionsHeading } from '../../../../../components/headings'
import { useTranslation } from 'react-i18next'

const Wrap = styled('div')(({ theme }) => ({
  padding: '30px 0',
  backgroundColor: '#FFFFFF',

  '.slick-track': {
    display: 'flex',
    alignItems: 'center',
  },
  '.list-items': {
    display: 'grid',
    gap: '2rem',
    gridTemplateColumns: 'repeat(4,1fr)',

    '& .item-custom': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}))

const Item = styled('div')(({ theme }) => ({
  img: {
    width: '100%',
    maxHeight: '100px',
    objectFit: 'contain',
    objectPosition: 'center',
  },
}))

const CustomSlider = styled(Slider)(({ theme }) => ({
  '.slick-slide': {
    backgroundColor: '#FFFFFF',
  },

  '.slick-slide div': {
    outline: 'none',
    backgroundColor: 'transparent',
    width: '150px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const PartnershipsHome = () => {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [partners, setPartners] = useState([])

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: partners.length > 5,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  useEffect(() => {
    getPartners()
  }, [])

  const getPartners = async () => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.getPartners()
      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }
      if (res.ok) {
        setPartners(res.data)
      }
      setLoading(false)
    } catch (error) {
      console.log('erreur')
    }
  }

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <CircularProgress />
      </div>
    )
  }

  return (
    <div
      style={{
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Container maxWidth={'xl'} sx={{ paddingBottom: '25px' }}>
        {partners && (
          <SectionsHeading
            type={'animation'}
            display={'flex'}
            justifyContent={'center'}
            textAlign={'center'}
          >
            {t('home.partners')}
          </SectionsHeading>
        )}
      </Container>
      <Wrap>
        <CustomSlider {...settings}>
          {partners.map((partner, index) => (
            <Item className={'item'} key={partner.id}>
              <Box component={'a'} href={partner.lien} target={'_blank'}>
                <Box component={'img'} src={`${FILE_URL}${partner.image}`} />
              </Box>
            </Item>
          ))}
        </CustomSlider>
      </Wrap>
    </div>
  )
}

export default PartnershipsHome
