import CalendarFull from '../../../components/CalendarFull'
import { AdminHeading } from '../../../components/headings'
import { Box, CircularProgress, Typography } from '@mui/material'
import '../../../styles/components/_box.scss'
import React, { useEffect, useState } from 'react'
import { handlingErrors } from '../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../styles/theme/lightTheme'
import Api from '../../../services/api.service'
import {
  capitalizeFirstLetter,
  getDayName,
} from '../../../components/CalendarFull/config'
import moment from 'moment-timezone'
import { EventBox } from '../../../components/CalendarFull/styles'
import { EventDetail } from './parts/EventDetail'
import { ROLE_CANDIDAT, ROLE_ENTREPRISE } from '../../../data'

const Calendar = () => {
  const [loading, setLoading] = useState(false)
  const [currentEvents, setCurrentEvents] = useState([])
  const [data, setData] = useState([])
  const [showDetailEvent, setShowDetailEvent] = useState(false)
  const [showDetail, setShowDetail] = useState({})
  const [typeEvent, setTypeEvent] = useState('')
  const [isCompany, setIsCompany] = useState()
  const [calendriers, setCalendriers] = useState([])
  const [newCal, setNewCal] = useState([])
  const [events, setEvents] = useState([])
  const [idEvent, setIdEvent] = useState(['null'])
  const [role, setRole] = useState('')

  const getNewCal = () => {
    for (let i = 0; i < calendriers.length; i++) {
      newCal[i] = calendriers[i][0]
    }
  }

  useEffect(() => {
    getNewCal()
  })

  useEffect(() => {
    setTypeEvent('rdv_decouverte')
  }, [])

  const fetchData = async () => {
    setLoading(true)
    try {
      const result = await Api.calendar.list()
      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setData([...res.data])
        const datas = res.data
        setEvents(datas)

        datas.map((item, i) => {
          if (item.entreprise !== null) {
            setRole(ROLE_ENTREPRISE)
          } else {
            setRole(ROLE_CANDIDAT)
          }
        })
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [idEvent])

  useEffect(() => {
    handleData()
  }, [events])

  const handleData = () => {
    const eventData = events.map((item, i) => {
      return {
        id: item.id,
        title: item.detail,
        start: item.dateDebut,
        end: item.dateFin,
        status: item.status,
        extendedProps: {
          nomCandidat: item.candidat?.profile?.nom,
          nomEntreprise: item.entreprise?.raisonSocial,
          candidat: item.candidat,
          entreprise: item.entreprise,
        },
      }
    })

    setCurrentEvents(eventData)
  }

  const handleDateSelect = (selectInfo) => {
    const day = getDayName(selectInfo.dateStr)
    const date = `${capitalizeFirstLetter(day)}, ${selectInfo.dateStr
      .split('T')[0]
      .replaceAll('-', '/')}`
    const splited = selectInfo.dateStr.split('T')

    if (selectInfo.allDay) {
      const current = splited[0] + 'T08:00:00'
    } else {
      const current = moment(selectInfo.dateStr)
        .tz('Europe/Paris')
        .format('YYYY-MM-DDTHH:mm:ss')
    }
  }

  const handleEventClick = async (clickInfo) => {
    const day = getDayName(clickInfo.event.extendedProps.date_debut)
    const date = `${capitalizeFirstLetter(day)}, aaa`

    setShowDetailEvent(true)
    setShowDetail(clickInfo.event)
  }

  function renderEventContent(eventInfo) {
    if (loading)
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      )

    return (
      <EventBox type={typeEvent === 'rdv_decouverte'}>
        {typeEvent === 'rdv_decouverte' ? (
          <>
            <Box
              className={'heading__services'}
              sx={{
                borderBottom: {
                  xs: 'none !important',
                  sm: `1px solid "#4564AD"`,
                },
              }}
            >
              <Typography className={'hour'}>
                {eventInfo.timeText.replace(/:/g, 'H')}
              </Typography>
              <Typography className={'name'}>
                {eventInfo.event.extendedProps.candidat === null ? (
                  <>{eventInfo.event.extendedProps.nomEntreprise}</>
                ) : (
                  <>{eventInfo.event.extendedProps.nomCandidat}</>
                )}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box className={'healing__list'}>
              <Typography className="hour">{eventInfo.timeText}</Typography>
            </Box>
            <Box className="body__list">
              <Box className="desc">{eventInfo.event.title}</Box>
            </Box>
          </>
        )}
      </EventBox>
    )
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading displayIconReturn={'none'}>Calendrier</AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: { xs: '25px', sm: '50px' },
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          <CalendarFull
            currentEvents={currentEvents}
            dateClick={handleDateSelect}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
          />
        </Box>
      </div>

      {/*<EventDetail
        onClose={() => {
          setShowDetailEvent(false);
        }}
        showDetailEvent={showDetailEvent}
        setShowDetailEvent={setShowDetailEvent}
        showDetail={showDetail}
        role={role}
      />*/}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default Calendar
