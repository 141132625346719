import React, { useEffect, useState } from 'react'
import { Box, Container, Skeleton, Typography } from '@mui/material'
import { SectionsHeading } from '../../../../../components/headings'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { AboutImageLogo } from '../../../../../components/images'
import { CustomButton } from '../../../../../components/buttons'
import AboutSection from '../../about/components/AboutSection'
import AnimateHeight from 'react-animate-height'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'

export default function AboutHome(data) {
  const { t } = useTranslation()
  const [height, setHeight] = useState(0)
  const [sections, setSections] = useState([])

  useEffect(() => {
    setSections(data.apropos)
  }, [data.apropos])

  return (
    <Box
      width={'100%'}
      component={'section'}
      sx={{
        padding: { xs: '110px 0 130px 0', md: '100px 0 ', lg: '140px 0' },
      }}
    >
      <Box width={'100%'} position={'relative'}>
        <Container
          maxWidth={'lg'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '40px',
          }}
        >
          <>
            {sections.map((section, index) => (
              <div key={index}>
                {section.titre?.length > 0 ? (
                  <SectionsHeading
                    type={'animation'}
                    /*justifyContent={"flex-start"}
                        width={350}*/
                    /*key={index}*/
                  >
                    {section.titre}
                  </SectionsHeading>
                ) : (
                  <Skeleton
                    variant="text"
                    sx={{ width: '30%' }}
                    animation={'wave'}
                  />
                )}
              </div>
            ))}
          </>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
            }}
          >
            <Box
              sx={{
                /*display: "flex",*/
                justifyContent: 'flex-start',

                alignItems: 'center',
                columnGap: { xs: 0, sm: '100px' },
                width: { xs: '100%', sm: '60%' },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '20px',
                }}
              >
                <>
                  {sections.map((section, index) => (
                    <div key={index}>
                      {section.detail?.length > 0 ? (
                        <Typography
                          variant={'body1'}
                          color={lightTheme.palette.grey.dark}
                          /*key={index}*/
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Markup content={section.detail} />
                        </Typography>
                      ) : (
                        <Box width={'100%'} display={'block'}>
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            height={14}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            height={14}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            width={'50%'}
                            height={14}
                          />
                          <br />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            height={14}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            height={14}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            width={'50%'}
                            height={14}
                          />
                          <br />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            height={14}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            height={14}
                          />
                          <Skeleton
                            variant={'text'}
                            animation={'wave'}
                            width={'50%'}
                            height={14}
                          />
                        </Box>
                      )}
                    </div>
                  ))}
                </>
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <AboutImageLogo />
              </Box>
            </Box>
          </Box>

          <AnimateHeight id="example-panel" duration={700} height={height}>
            <AboutSection about={data.about} />
          </AnimateHeight>

          <CustomButton
            width={250}
            displayIcon={'none'}
            alignSelf={'center'}
            handleClick={() => {
              setHeight(height === 0 ? 'auto' : 0)
            }}
          >
            {height === 0 ? `${t('button.more')}` : `${t('button.less')}`}
          </CustomButton>
        </Container>
      </Box>
    </Box>
  )
}
