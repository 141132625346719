import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Container,
  styled,
  Skeleton,
  Grid,
  CircularProgress,
} from '@mui/material'
import Steps from './Steps/index.js'
import { useTranslation } from 'react-i18next'
import Api from '../../../services/api.service.js'
import { handlingErrors } from '../../../utils/index.js'
import { Markup } from 'interweave'
import { useNavigate, useParams } from 'react-router-dom'
import BorderButton from '../../../components/buttons/BorderButton.js'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import Login from '../../Auth/Login.js'
import { useSelector } from 'react-redux'

const ContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '50px',

  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}))

const GridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
}))

const ContentBody = styled(Box)(({ theme }) => ({
  display: 'none',
  flexDirection: 'column',
  rowGap: '30px',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const InscriptionAtelier = () => {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [loadingLog, setLoadingLog] = useState(false)
  const [atelier, setAtelier] = useState([])
  const [atelierUser, setAtelierUser] = useState(null)
  const [user, setUser] = useState(null)
  const [step, setStep] = useState(0)
  const [login, setLogin] = useState(true)
  const params = useParams()
  const navigate = useNavigate()
  moment.locale(i18n.language)
  const userData = useSelector((state) => state.userAuth.user)

  /*   if (sessionStorage.getItem('atelierSelected'))
    sessionStorage.removeItem('atelierSelected') */
  useEffect(() => {
    if (params.atelierId) {
      getAtelier(params.atelierId)
    }
  }, [params.atelierId])

  const getAtelier = async (atelierId) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.getAtelier(atelierId)

      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setAtelier(res.data)
        //console.log(userData.data)
        if (userData.data) setUser(userData.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onChangeUser = (currentUser) => {
    setUser(currentUser)
  }

  useEffect(() => {
    if (user) {
      getAtelierUser(user)
    }
  }, [user])

  const getAtelierUser = async (user) => {
    setLoadingLog(true)
    try {
      //return
      const atelierUser = {
        utilisateur: `${user.id}`,
        atelier: `${atelier.id}`,
      }

      const result = await Api.ateliersUtilisateurs.get(atelierUser)

      //const result = await Api.user.edit(user.id, obj)
      const handling = handlingErrors(result)

      if (handling.ok) {
        if (handling.data.length > 0) {
          setAtelierUser(handling.data[0])
          if (handling.data[0].status === 'payer') {
            setStep(4)
            setLogin(false)
            setLoadingLog(false)
          } else {
            setStep(2)
            setLogin(false)
            setLoadingLog(false)
          }
        } else {
          addAtelierUser(user)
        }
      }
    } catch (error) {
      console.log(error)
      setLoadingLog(false)
    }
  }

  const addAtelierUser = async (user) => {
    setLoadingLog(true)
    try {
      //return
      const atelierUser = {
        utilisateur: `/api/utilisateurs/${user.id}`,
        atelier: `/api/ateliers/${atelier.id}`,
        prix: atelier.prix,
      }

      const result = await Api.ateliersUtilisateurs.add(atelierUser)

      //const result = await Api.user.edit(user.id, obj)
      const handling = handlingErrors(result)

      if (handling.ok) {
        console.log(handling.data)
        setAtelierUser(handling.data)
        setLoadingLog(false)
        setLogin(false)
        if (handling.data.status === 'payer') {
          setStep(4)
          setLogin(false)
          setLoadingLog(false)
        } else {
          setStep(2)
        }
      }
    } catch (error) {
      console.log(error)
      setLoadingLog(false)
    }
  }

  return (
    <Container
      maxWidth={'lg'}
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: { xs: 'center', lg: 'space-between' },
        marginTop: { xs: '30px', md: '80px', lg: '50px' },
        rowGap: { xs: '30px', md: '80px' },
      }}
    >
      <ContentBox>
        {!loading ? (
          <>
            <BorderButton
              type={'basic'}
              displayIconRight={'none'}
              width={'fit-content'}
              height={45}
              handleClick={() => {
                navigate('/atelier/' + atelier.id)
              }}
            >
              {t('button.backToEvents')}
            </BorderButton>
            <Typography variant={'h1'} textAlign={{ xs: 'center', lg: 'left' }}>
              {atelier.titre}
            </Typography>
            <ContentBody>
              <Typography variant={'h3'}>“{atelier.sousTitre}“</Typography>
              <Typography>
                <Markup content={atelier.apropos} />
              </Typography>
            </ContentBody>
            {/* detail atelier */}
            <Container maxWidth={'lg'} sx={{ padding: '0px !important' }}>
              <Grid container>
                <GridItem item sm={12} lg={6} alignItems={'center'} order={1}>
                  <Typography
                    sx={{
                      width: 'fit-content',
                      borderRadius: '100px',
                      background: '#fff',
                      zIndex: '1',

                      fontSize: {
                        xs: '10px !important',
                        sm: '12px !important',
                        lg: '14px !important',
                      },
                      fontFamily: 'Century Gothic',
                      color: '#000',
                      float: 'left',
                      width: '90%',
                      marginBottom: '20px',
                      textAlign: 'left',
                    }}
                  >
                    <Typography
                      sx={{
                        width: 'fit-content',
                        zIndex: '1',
                        fontWeight: '400',
                        fontFamily: 'Century Gothic Bold',
                        color: '#808BF8',
                        float: 'left',
                        marginRight: '10px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('profile.date')}
                    </Typography>
                    {moment(atelier.date)
                      .tz('Europe/Paris')
                      .format('DD/MM/YYYY')}
                  </Typography>
                </GridItem>
                <GridItem item sm={12} lg={6} alignItems={'center'} order={2}>
                  <Typography
                    sx={{
                      width: 'fit-content',
                      borderRadius: '100px',
                      background: '#fff',
                      zIndex: '1',

                      fontSize: {
                        xs: '10px !important',
                        sm: '12px !important',
                        lg: '14px !important',
                      },
                      fontFamily: 'Century Gothic',
                      color: '#000',
                      float: 'left',
                      width: '90%',
                      marginBottom: '20px',
                      textAlign: 'left',
                    }}
                  >
                    <Typography
                      sx={{
                        width: 'fit-content',
                        zIndex: '1',
                        fontWeight: '400',
                        fontFamily: 'Century Gothic Bold',
                        color: '#808BF8',
                        float: 'left',
                        marginRight: '10px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('profile.hour')}
                    </Typography>
                    {moment(atelier.date).tz('Europe/Paris').format('HH:mm')}
                  </Typography>
                </GridItem>
                <GridItem item sm={12} lg={6} alignItems={'center'} order={3}>
                  <Typography
                    sx={{
                      width: 'fit-content',
                      borderRadius: '100px',
                      background: '#fff',
                      zIndex: '1',

                      fontSize: {
                        xs: '10px !important',
                        sm: '12px !important',
                        lg: '14px !important',
                      },
                      fontFamily: 'Century Gothic',
                      color: '#000',
                      float: 'left',
                      width: '90%',
                      marginBottom: '20px',
                      textAlign: 'left',
                    }}
                  >
                    <Typography
                      sx={{
                        width: 'fit-content',
                        zIndex: '1',
                        fontWeight: '400',
                        fontFamily: 'Century Gothic Bold',
                        color: '#808BF8',
                        float: 'left',
                        marginRight: '10px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('profile.place')}
                    </Typography>
                    {atelier.lieu}
                  </Typography>
                </GridItem>
                <GridItem
                  item
                  sm={12}
                  lg={6}
                  alignItems={'center'}
                  order={{ xs: 5, md: 4 }}
                >
                  <Typography
                    sx={{
                      width: 'fit-content',
                      borderRadius: '100px',
                      background: '#fff',
                      zIndex: '1',

                      fontSize: {
                        xs: '10px !important',
                        sm: '12px !important',
                        lg: '14px !important',
                      },
                      fontFamily: 'Century Gothic',
                      color: '#000',
                      float: 'left',
                      width: '90%',
                      marginBottom: '20px',
                      textAlign: 'left',
                    }}
                  >
                    <Typography
                      sx={{
                        width: 'fit-content',
                        zIndex: '1',
                        fontWeight: '400',
                        fontFamily: 'Century Gothic Bold',
                        color: '#808BF8',
                        float: 'left',
                        marginRight: '10px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('profile.language')}
                    </Typography>
                    {atelier.langues &&
                      Object.values(atelier.langues).join(' / ')}
                  </Typography>
                </GridItem>
                <GridItem
                  item
                  sm={12}
                  lg={6}
                  alignItems={'center'}
                  order={{ xs: 4, md: 5 }}
                >
                  <Typography
                    sx={{
                      width: 'fit-content',
                      borderRadius: '100px',
                      background: '#fff',
                      zIndex: '1',

                      fontSize: {
                        xs: '10px !important',
                        sm: '12px !important',
                        lg: '14px !important',
                      },
                      fontFamily: 'Century Gothic',
                      color: '#000',
                      float: 'left',
                      width: '90%',
                      marginBottom: '20px',
                      textAlign: 'left',
                    }}
                  >
                    <Typography
                      sx={{
                        width: 'fit-content',
                        zIndex: '1',
                        fontWeight: '400',
                        fontFamily: 'Century Gothic Bold',
                        color: '#808BF8',
                        float: 'left',
                        marginRight: '10px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('profile.price')}
                    </Typography>
                    {(
                      atelier.prix *
                      JSON.parse(sessionStorage.getItem('currency')).currentRate
                    ).toFixed(2)}{' '}
                    {JSON.parse(sessionStorage.getItem('currency')).devise}
                  </Typography>
                </GridItem>
              </Grid>
            </Container>
          </>
        ) : (
          <>
            <Skeleton animation={'wave'} variant={'text'} sx={{ height: 24 }} />
            <Skeleton
              animation={'wave'}
              variant={'text'}
              sx={{ height: 24, width: '50%' }}
            />
            <div>
              <Skeleton
                animation={'wave'}
                variant={'text'}
                sx={{ height: 14 }}
              />
              <Skeleton
                animation={'wave'}
                variant={'text'}
                sx={{ height: 14 }}
              />
              <Skeleton
                animation={'wave'}
                variant={'text'}
                sx={{ height: 14 }}
              />
              <Skeleton
                animation={'wave'}
                variant={'text'}
                sx={{ height: 14 }}
              />
              <Skeleton
                animation={'wave'}
                variant={'text'}
                sx={{ height: 14, width: '60%' }}
              />
            </div>
          </>
        )}
      </ContentBox>
      <Box
        width={{ xs: '100%', lg: '50%' }}
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        {loading || loadingLog ? (
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {login ? (
              <Login
                atelier={atelier}
                onChangeUser={onChangeUser}
                setLogin={setLogin}
              />
            ) : (
              <Steps
                atelier={atelier}
                step={step}
                user={user}
                setLogin={setLogin}
                atelierUser={atelierUser}
                setAtelierUser={setAtelierUser}
              />
            )}
          </>
        )}
      </Box>
    </Container>
  )
}

export default InscriptionAtelier
