import React, { useEffect, useState } from 'react'
import { Box, Skeleton, styled, Typography } from '@mui/material'
import { Markup } from 'interweave'
import lightTheme from '../../../../../styles/theme/lightTheme'

const CustomBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '40px',
  order: 2,
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    order: 1,
    width: '60%',
  },
}))

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
}))

export default function AboutSection(data) {
  const [more, setMore] = useState([])

  useEffect(() => {
    setMore(data.about)
  }, [data.about])

  return (
    <Box component={'section'} position={'relative'} overflow={'hidden'}>
      {more.map((more, index) => (
        <CustomBox key={index}>
          <ContentContainer>
            {more.detail?.length > 0 ? (
              <Typography>
                <Markup content={more.detail} />
              </Typography>
            ) : (
              <Box width={'100%'} display={'block'}>
                <Skeleton variant={'text'} animation={'wave'} height={14} />
                <Skeleton variant={'text'} animation={'wave'} height={14} />
                <Skeleton
                  variant={'text'}
                  animation={'wave'}
                  width={'50%'}
                  height={14}
                />
                <br />
                <Skeleton variant={'text'} animation={'wave'} height={14} />
                <Skeleton variant={'text'} animation={'wave'} height={14} />
                <Skeleton
                  variant={'text'}
                  animation={'wave'}
                  width={'50%'}
                  height={14}
                />
                <br />
                <Skeleton variant={'text'} animation={'wave'} height={14} />
                <Skeleton variant={'text'} animation={'wave'} height={14} />
                <Skeleton
                  variant={'text'}
                  animation={'wave'}
                  width={'50%'}
                  height={14}
                />
              </Box>
            )}
          </ContentContainer>
          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: 'Great Vibes',
              fontSize: { xs: '25px' },
              lineHeight: '140%',
              letterSpacing: '0.2px',
              color: lightTheme.palette.text.primary,
            }}
          >
            {more.sousTitre?.length > 0 ? (
              <>{more.sousTitre}</>
            ) : (
              <Skeleton
                variant={'text'}
                animation={'wave'}
                width={'20%'}
                height={14}
              />
            )}
          </Typography>
        </CustomBox>
      ))}
    </Box>
  )
}
