import React, { useEffect, useState } from 'react'
import { Box, Container, Skeleton } from '@mui/material'
import SearchBar from '../../../../../components/SearchBar'
import HeaderCardHome from '../../../../../components/cards/HeaderCardHome'

export default function BannerPictureHome(data) {
  const [infos, setInfos] = useState([])

  useEffect(() => {
    setInfos(data.accueil)
  }, [data.accueil])

  return (
    <Box component={'section'}>
      <Container
        maxWidth={'lg'}
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: { xs: '130px', sm: '50px' },
          width: { xs: '100vw' },
        }}
      >
        {/*<HeaderCard>
          {i18n.language === "fr" ? (
            <>
              {title.map((item, index) => (
                <span key={index}>
                  {item.titre}
                </span>
              ))}
            </>
          ) : (
              <>
                {titleEn.map((item, index) => (
                  <span key={index}>
                  {item.titre}
                </span>
                ))}
              </>
            )}
        </HeaderCard>*/}

        {infos.map((info, index) => (
          <HeaderCardHome
            key={index}
            title={info.titre}
            alt={info.photos[0].titre}
            img={info.photos[0].lien}
          />
        ))}

        <SearchBar />
      </Container>
    </Box>
  )
}
