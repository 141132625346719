import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  ListItem,
  Skeleton,
  styled,
  Typography,
} from '@mui/material'
import {
  HeadingMethod,
  HeadingNumber,
  SectionsHeading,
} from '../../../../../components/headings'
import { ImageBorderRadius } from '../../../../../components/images'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { CustomButton, WhiteButton } from '../../../../../components/buttons'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'
import '../../../../../styles/components/_skeleton.scss'
import { useNavigate } from 'react-router-dom'

const Section = styled(Box)(({ theme }) => ({
  paddingTop: '120px',
}))

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '15px',
  width: '70%',
}))

const LiItem = styled(ListItem)(({ theme }) => ({
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  color: lightTheme.palette.text.primary,
  letterSpacing: '0.2px',
  display: 'list-item',
  listStyleType: 'disc',
  listStyle: 'inside',

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const CustomBoxMobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const TextBox = styled(Box)(({ theme }) => ({
  display: 'none',
  justifyContent: 'space-between',
  columnGap: '30px',
  /*alignItems: "end"*/
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}))

const Text = styled(Typography)(({ theme }) => ({
  color: lightTheme.palette.text.primary,
}))

export default function OptimizationSection(data) {
  const { t } = useTranslation()
  const [searchOptimization, setSearchOptimization] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    setSearchOptimization(data.optimisationRecherche)
  }, [data.optimisationRecherche])

  return (
    <Section
      id={'sec2'}
      component={'section'}
      width={'100%'}
      pt={'100px'}
      pb={'170px'}
      sx={{
        paddingBottom: { xs: '90px', sm: '100px', lg: '125px' },
        paddingTop: { xs: '75px' },
      }}
    >
      <HeadingMethod
        right={0}
        type={'right'}
        textAlign={{ xs: 'center', md: 'left' }}
        /*marginRight={0}*/
        displayLogo={'none'}
        /*maxWidthText={{xs: 300, md: 600}}*/
        maxWidthText={'100%'}
        /*justifyContent={"flex-start"}*/
        sx={{
          justifyContent: { xs: 'center', md: 'flex-start' },
          alignItems: 'center',
          /*paddingLeft: {xs: 0, md: "100px" }*/
        }}
        rightContent={0}
        heightCustomBox={140}
        heightContentBox={140}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: { xs: 'space-around', sm: 'space-between' },
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Markup content={t('company.offersTitle')} />
          <WhiteButton handleClick={() => navigate('/contact')}>
            {t('button.rdv')}
          </WhiteButton>
        </Box>
      </HeadingMethod>

      <>
        {searchOptimization.map((item, index) => (
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '65px',
              paddingTop: { xs: '300px', sm: '200px', lg: '250px' },
            }}
            key={index}
          >
            {!item.titre ? (
              <Skeleton
                variant={'text'}
                animation={'wave'}
                className={'skeleton-title'}
              />
            ) : (
              <SectionsHeading
                type={'animation'}
                justifyContent={{ xs: 'center', sm: 'flex-start' }}
                width={{ xs: 265, sm: 450, lg: '100%' }}
                /*lineHeight={"150%"}
                    top={{xs: "1px", sm: "2px", lg: "9px"}}*/
                textAlign={{ xs: 'center', sm: 'left' }}
                display={{ xs: 'flex', sm: 'none' }}
              >
                {item.titre}
              </SectionsHeading>
            )}

            <CustomBoxMobile>
              {!item.photos[0] ? (
                <Skeleton
                  variant={'rounded'}
                  animation={'wave'}
                  className={'image-border-radius'}
                />
              ) : (
                <ImageBorderRadius image={item.photos[0].lien} />
              )}

              <Box
                sx={{
                  width: '100%',
                }}
              >
                {!item.detail ? (
                  <Box width={'100%'}>
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text-50'}
                    />
                    <br />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text-50'}
                    />
                    <br />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text-50'}
                    />
                  </Box>
                ) : (
                  <Text variant={'body2'}>
                    <Markup content={item.detail} />
                  </Text>
                )}
              </Box>
            </CustomBoxMobile>
            <TextBox>
              <ContentBox>
                {!item.titre ? (
                  <Skeleton
                    variant={'text'}
                    animation={'wave'}
                    className={'skeleton-title'}
                  />
                ) : (
                  <HeadingNumber type={'animation'} number={'3'}>
                    {item.titre}
                  </HeadingNumber>
                )}

                {!item.detail ? (
                  <Box width={'100%'}>
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text-50'}
                    />
                    <br />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text-50'}
                    />
                    <br />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text'}
                    />
                    <Skeleton
                      variant={'text'}
                      animation={'wave'}
                      className={'skeleton-text-50'}
                    />
                  </Box>
                ) : (
                  <Text variant={'body2'} display={{ xs: 'none', sm: 'flex' }}>
                    <Markup content={item.detail} />
                  </Text>
                )}

                {/*<Box
                      sx={{
                        display: {xs: "none", lg: "flex" },
                        flexDirection: {xs: "column", sm: "row", lg: "column"},
                        rowGap: "25px",
                        justifyContent: {xs: "center", sm: "space-between"},
                        alignItems: {xs: "center", lg: "start"},
                        marginTop: "50px"
                      }}
                    >
                      <Typography
                        variant={"subtitle2"}
                        color={lightTheme.palette.text.primary}
                        textAlign={{xs: "center", sm: "left"}}
                      >
                        <Markup content={t('company.interestOffers')}/>
                      </Typography>
                      <CustomButton
                        displayIcon={"none"}
                        width={300}
                      >
                        {t('button.rdv')}
                      </CustomButton>
                    </Box>*/}
              </ContentBox>
              <Box
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  width: '30%',
                }}
              >
                {!item.photos[0] ? (
                  <Skeleton
                    variant={'text'}
                    animation={'wave'}
                    className={'image-border-radius'}
                  />
                ) : (
                  <ImageBorderRadius image={item.photos[0].lien} />
                )}
              </Box>
            </TextBox>

            <Box
              sx={{
                flexDirection: { xs: 'column', sm: 'row', lg: 'column' },
                rowGap: '25px',
                justifyContent: { xs: 'center', sm: 'space-between' },
                alignItems: { xs: 'center', lg: 'start' },
                width: { xs: '100%', sm: 420, lg: 420 },
              }}
            >
              <CustomButton
                displayIcon={'none'}
                handleClick={() => {
                  navigate('/contact')
                }}
              >
                {t('button.improveRecruitmentProcess')}
              </CustomButton>
            </Box>
          </Container>
        ))}
      </>
    </Section>
  )
}
