import React, { useEffect, useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useParams,
} from 'react-router-dom'
import PublicRouter from './PublicRouter'
import AdminRouter from './AdminRouter'
import AuthRouter from './AuthRouter'
import UserRouter from './UserRouter'
import ContactRouter from './ContactRouter'
import InscriptionRouter from './InscriptionRouter'
import Api from '../services/api.service'
import { handlingErrors } from '../utils'
import InscriptionAtelierRouter from './InscriptionAtelierRouter'
//import AppGlobal from "../data/AppGlobal";

function ScrollToTop() {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default function AppNavigation() {
  /* const getRateCurrency = async () => {
    try {
      const result = await Api.rate.list()
      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        sessionStorage.setItem(
          'currency',
          JSON.stringify({
            devise: 'CHF',
            currentRate: 1,
            rate: res.data[0].rate,
          })
        )
      }
    } catch (error) {
      console.log(error)
    }
  } */

  useEffect(() => {
    if (!sessionStorage.getItem('currency')) {
      sessionStorage.setItem(
        'currency',
        JSON.stringify({ devise: 'CHF', currentRate: 1, rate: 1 })
      )
      //getRateCurrency()
    }
    if (!localStorage.getItem('codePays')) {
      localStorage.setItem('codePays', 'ch')
    }
  }, [])
  return (
    <BrowserRouter>
      {/* <AppGlobal/> */}
      <ScrollToTop />
      <Routes>
        <Route path={'/admin/*'} element={<AdminRouter />} />
        <Route path={'/*'} element={<PublicRouter />} />
        <Route path={'/auth/*'} element={<AuthRouter />} />

        <Route path={'/user/*'} element={<UserRouter />} />
        <Route path={'/contact'} element={<ContactRouter />} />
        <Route
          path={'/atelier/inscription/:atelierId'}
          element={<InscriptionAtelierRouter />}
        />
        <Route path={`/inscription/*`} element={<InscriptionRouter />} />
      </Routes>
    </BrowserRouter>
  )
}
