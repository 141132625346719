import ApiRequest from '../request/ApiRequest'

export default class SiteVitrine {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post('utilisateurs', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`utilisateurs/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`utilisateurs/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }
  async list() {
    try {
      const res = await this.ApiRequest.get('utilisateurs')
      return res
    } catch (error) {
      return error.response
    }
  }
  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`utilisateurs/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get('sites.json', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listTemoignagesWithFilter(data) {
    try {
      const res = await this.ApiRequest.get('temoignages.json', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async getContenu(data) {
    try {
      const res = await this.ApiRequest.get('sites.json', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async getContenuEn(data) {
    try {
      const res = await this.ApiRequest.get('sites.json?lang=en', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async getOffers(data = {}, lang, categorie = '') {
    try {
      const res = await this.ApiRequest.get(
        'offres.json?lang=' + lang + '&categorie=' + categorie,
        data
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async getOffersEntreprise(data = {}) {
    try {
      const res = await this.ApiRequest.get(
        'offres_entreprises.json',
        data
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async getOffersEn(data) {
    try {
      const res = await this.ApiRequest.get('offres.json?lang=en', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async getPartners() {
    try {
      const res = await this.ApiRequest.get('partenariats.json')
      return res
    } catch (error) {
      return error.response
    }
  }

  async getConsultants() {
    try {
      const res = await this.ApiRequest.get('consultants.json')
      return res
    } catch (error) {
      return error.response
    }
  }

  async getArticles(page, data = {}, order, orderType) {
    try {
      const res = await this.ApiRequest.get(
        'articles.jsonld?order[' + order + ']=' + orderType + '&page=' + page,
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async getArticle(idArticle, data) {
    try {
      const res = await this.ApiRequest.get('articles/' + idArticle, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async getAteliers(page, data = {}, order, orderType) {
    try {
      const res = await this.ApiRequest.get(
        'ateliers.jsonld?order[' + order + ']=' + orderType + '&page=' + page,
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async getAtelier(idAtelier, data) {
    try {
      const res = await this.ApiRequest.get('ateliers/' + idAtelier, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async getCodePromo(code) {
    try {
      const res = await this.ApiRequest.get('promo/' + code)
      return res
    } catch (error) {
      return error.response
    }
  }
}
