import {
  Box,
  Container,
  Grid,
  Skeleton,
  styled,
  Typography,
} from '@mui/material'
import { SectionsHeading } from '../../../../../components/headings'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { keyframes } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { Markup } from 'interweave'

const GridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
}))

const ImageContainer = styled(Box)(({ theme }) => ({
  height: 100,
  borderRadius: '25px',
  position: 'relative',
  overflow: 'hidden',

  '& .image': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  [theme.breakpoints.up('sm')]: {
    height: 135,
  },

  [theme.breakpoints.up('lg')]: {},
}))

const BadgeButton = styled(Box)(({ theme }) => ({
  width: 95,
  height: 25,
  borderRadius: '25px',
  backgroundColor: lightTheme.palette.error.main,
  animation: `${colorEffect} 15s linear infinite`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFFFFF',
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  textTransform: 'uppercase',
  lineHeight: '140%',
  letterSpacing: '0.2px',

  [theme.breakpoints.up('sm')]: {
    width: 130,
    fontSize: 12,
  },

  [theme.breakpoints.up('lg')]: {
    width: 170,
    height: 35,
    fontSize: 14,
  },
}))

const colorEffect = keyframes`
    0% { background-color: #F76E11;}
    25% { background-color: #96CEB4; }
    50% { background-color: #808BF8; }
    75% { background-color: #96CEB4; }
    100% { background-color: #F76E11;}
`

const SpecialityHome = (data) => {
  const { t } = useTranslation()
  const [contentCandidat, setContentCandidat] = useState([])
  const [contentEntreprise, setContentEntreprise] = useState([])

  useEffect(() => {
    setContentCandidat(data.nosSpecialitesCandidats)
  }, [data.nosSpecialitesCandidats])

  useEffect(() => {
    setContentEntreprise(data.nosSpecialitesEntreprises)
  }, [data.nosSpecialitesEntreprises])

  return (
    <Box
      component={'section'}
      width={'100%'}
      sx={{
        padding: { xs: '90px 0', sm: '110px 0', lg: '160px 0 80px 0' },
      }}
    >
      <Container
        maxWidth={'md'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '80px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '40px', sm: '5px', lg: 0 },
          }}
        >
          <SectionsHeading
            type={'animation'}
            display={'flex'}
            justifyContent={'center'}
            textAlign={'center'}
            leftCircle={{ xs: '47px', sm: 0 }}
            /*display={"flex"}
            justifyContent={"center"}
            textAlign={"center"}
            top={{xs: "4px", lg: "12px" }}*/
          >
            {t('home.specialist')}
          </SectionsHeading>
          <Typography
            textAlign={'center'}
            fontFamily={'Century Gothic Bold'}
            fontSize={{ xs: 16, sm: 18, lg: 24 }}
            textTransform={'uppercase'}
          >
            {t('home.makeSens')}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {contentCandidat.map((item, index) => (
            <GridItem item xs={12} lg={6} key={index}>
              {!item.photos[0] ? (
                <Skeleton
                  variant={'rounded'}
                  animation={'wave'}
                  width={'100%'}
                  height={{ xs: 100, sm: 135 }}
                />
              ) : (
                <ImageContainer>
                  <img
                    src={item.photos[0].lien}
                    alt={`Jobertech - ${item.photos[0].titre}`}
                    className={'image'}
                  />
                </ImageContainer>
              )}

              {!item.titre ? (
                <Skeleton variant={'text'} animation={'wave'} height={14} />
              ) : (
                <BadgeButton>{item.titre}</BadgeButton>
              )}

              {!item.detail ? (
                <Box width={'100%'} display={'block'}>
                  <Skeleton variant={'text'} animation={'wave'} height={14} />
                  <Skeleton
                    variant={'text'}
                    animation={'wave'}
                    width={'50%'}
                    height={14}
                  />
                </Box>
              ) : (
                <Typography>
                  <Markup content={item.detail} />
                </Typography>
              )}
            </GridItem>
          ))}

          {contentEntreprise.map((item, index) => (
            <GridItem item xs={12} lg={6} key={index}>
              {!item.photos[0] ? (
                <Skeleton
                  variant={'rounded'}
                  width={'100%'}
                  height={{ xs: 100, sm: 135 }}
                />
              ) : (
                <ImageContainer>
                  <img
                    src={item.photos[0].lien}
                    alt={`Jobertech - ${item.photos[0].titre}`}
                    className={'image'}
                  />
                </ImageContainer>
              )}

              {!item.titre ? (
                <Skeleton variant={'text'} animation={'wave'} height={14} />
              ) : (
                <BadgeButton>{item.titre}</BadgeButton>
              )}

              {!item.detail ? (
                <Box width={'100%'} display={'block'}>
                  <Skeleton variant={'text'} animation={'wave'} height={14} />
                  <Skeleton
                    variant={'text'}
                    animation={'wave'}
                    width={'50%'}
                    height={14}
                  />
                </Box>
              ) : (
                <Typography>
                  <Markup content={item.detail} />
                </Typography>
              )}
            </GridItem>
          ))}
          <Grid item xs={0} sm={12}>
            <Typography
              sx={{
                textAlign: 'center',
                textTransform: 'uppercase',
                fontFamily: 'Century Gothic Bold',
                fontSize: { sm: 12, lg: 14 },
                color: lightTheme.palette.text.primary,
                lineHeight: '140%',
                letterSpacing: '0.2px',
                paddingTop: '50px',
              }}
            >
              {t('home.jobertechAccompagne')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default SpecialityHome
