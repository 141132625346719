import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, styled } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import UserProfilHeading from '../../../components/headings/UserProfilHeading'
import { JaaSMeeting } from '@jitsi/react-sdk'
import { JITSI_APP_ID } from '../../../config'
import { useSelector } from 'react-redux'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons'
import lightTheme from '../../../styles/theme/lightTheme'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { ROLE_ENTREPRISE } from '../../../data'

const Meeting = () => {
  const { t, i18n } = useTranslation()
  const userData = useSelector((state) => state.userAuth.user)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [reunion, setReunion] = useState({})

  const params = useParams()

  useEffect(() => {
    if (params.meetingId) {
      fetchData()
    }
  }, [params.meetingId])

  useEffect(() => {
    fetchData()
  }, [userData.data.id])

  const fetchData = async () => {
    try {
      let result = await Api.calendar.detail(params.meetingId)

      const handling = handlingErrors(result)
      if (handling.status === 401) {
        setLoading(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
      if (handling.ok) {
        const res = handling.data
        setReunion(res)
        /*  res.role === ROLE_ENTREPRISE
          ? setName(res.entreprise.raisonSocial)
          : setName(res.nom + ' ' + res.prenom) */
        setLoading(false)
      } else {
        setReunion(null)
        setLoading(false)
        /* setMsg(handling.message);
        setErr(true);
        setOpen(true);
        window.scrollTo(0, 0); */
      }
    } catch (error) {
      setLoading(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  const handleClickIcon = () => {
    userData ? navigate(`/user`) : navigate(`/`)
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'absolute',
          top: '52px',
          zIndex: '9999',
          background: '#fff',
        }}
      >
        {/*{!openCard ? (
          <>*/}
        <UserProfilHeading
          displayIconReturn={'display'}
          handleClickIcon={handleClickIcon}
        >
          {t('box.meeting')}
          {!loading &&
            reunion &&
            ' ' +
              t('box.of') +
              ' ' +
              moment(reunion.dateDebut)
                .tz('Europe/Paris')
                .format('DD/MM/YYYY') +
              ' ' +
              t('box.in') +
              ' ' +
              moment(reunion.dateDebut).tz('Europe/Paris').format('HH:mm')}
        </UserProfilHeading>

        <Box
          className={'BoxContainer'}
          sx={{
            minHeight: '900px',
            marginTop: '25px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                marginTop: '125px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : reunion ? (
            <JaaSMeeting
              appId={JITSI_APP_ID}
              roomName={reunion.type + ' N' + params.meetingId}
              /* jwt={JITSI_VALID_JWT} */
              configOverwrite={{
                disableThirdPartyRequests: true,
                disableLocalVideoFlip: true,
                backgroundAlpha: 0.5,
              }}
              interfaceConfigOverwrite={{
                VIDEO_LAYOUT_FIT: 'nocrop',
                MOBILE_APP_PROMO: false,
                TILE_VIEW_MAX_COLUMNS: 4,
              }}
              userInfo={{
                displayName:
                  userData.data.role === ROLE_ENTREPRISE
                    ? reunion.entreprise.raisonSocial
                    : userData.data.nom,
              }}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.height = '900px'
              }}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                marginTop: '125px',
                fontWeight: 'bold',
              }}
            >
              {t('errorMessage.noAccessMeeting')}
            </Box>
          )}
        </Box>
        {/*</>
        ) : (
          <CandidateProfil id={id} />
        )}*/}
      </div>
    </>
  )
}

export default Meeting
