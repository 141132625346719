import React, { useEffect, useState } from 'react'
/*import {useTranslation} from "react-i18next";*/
import BannerPictureHome from './components/BannerPictureHome'
import AboutHome from './components/AboutHome'
import OffersHome from './components/OffersHome'
import CommunityHome from './components/CommunityHome'
import SpecialityHome from './components/SpecialityHome'
import StrategyHome from './components/StrategyHome'
import CommentsHome from './components/CommentsHome'
import PartnershipsHome from './components/PartnershipsHome'
import { CircularProgress } from '@mui/material'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { useTranslation } from 'react-i18next'
import HowSection from './components/HowSection'
/*import ContentsHome from "./components/ContentsHome";
import WorkshopsHome from "./components/WorkshopsHome";*/

export default function Home() {
  const { i18n } = useTranslation()
  const [homeData, setHomeData] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getHomeData(i18n.language)
  }, [i18n.language])

  const getHomeData = async (lang) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: lang,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setHomeData(res.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) {
    return (
      <main style={{ marginTop: '80px' }}>
        <CircularProgress />
      </main>
    )
  }

  return (
    <main>
      <BannerPictureHome
        accueil={homeData?.filter((data) => data.section === 'accueil')}
      />
      <SpecialityHome
        nosSpecialitesCandidats={homeData?.filter(
          (data) => data.section === 'nos-specialites-candidats'
        )}
        nosSpecialitesEntreprises={homeData?.filter(
          (data) => data.section === 'nos-specialites-entreprises'
        )}
      />
      <StrategyHome
        candidat={homeData?.filter((data) => data.section === 'candidat')}
        entreprises={homeData?.filter((data) => data.section === 'entreprises')}
      />
      <HowSection />
      <AboutHome
        apropos={homeData?.filter((data) => data.section === 'apropos')}
        about={homeData?.filter((data) => data.section === 'en-savoir-plus')}
      />
      <OffersHome
        offresCandidat={homeData?.filter(
          (data) => data.section === 'offres-candidat'
        )}
        offresEntreprises={homeData?.filter(
          (data) => data.section === 'offres-entreprises'
        )}
      />
      <CommunityHome
        commentRejoindre={homeData?.filter(
          (data) => data.section === 'comment-rejoindre'
        )}
      />
      <CommentsHome />
      <PartnershipsHome />
      {/*<ContentsHome/>*/}
      {/*<WorkshopsHome/>*/}
      {/*<div style={{height: "100px"}}/>*/}
    </main>
  )
}
