import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { BorderButton } from '../../../../components/buttons'
import StripeAppTest from '../../../../components/Stripe/StripeAppTest'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import lightTheme from '../../../../styles/theme/lightTheme'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../../../../store/slices/userAuthReducer'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'

const ContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '50px',

  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}))

const ContentBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))
const CardContainer = styled(Box)(({ theme }) => ({
  width: '80%',
  height: 600,
  margin: '0 10%',
  backgroundColor: lightTheme.palette.primary.b6,
  borderRadius: '25px',
  padding: '25px',
  position: 'relative',

  [theme.breakpoints.up('xs')]: {
    width: '80%',
    height: 600,
    padding: '40px 20px',
    margin: '0 10%',
  },

  [theme.breakpoints.up('sm')]: {
    width: '80%',
    height: 520,
    padding: '40px 20px',
    margin: '0 10%',
  },

  [theme.breakpoints.up('lg')]: {
    width: 470,
    height: 540,
    padding: '25px',
    margin: 0,
  },
}))
export default function CompanyOfferPayement() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.userAuth.user)
  if (!userData) {
    navigate('/')
  }
  const [currentStep, setCurrentStep] = useState(1)
  const [offreSelect, setOffreSelect] = useState('')
  const [periodeSelect, setPeriodeSelect] = useState(0)
  const [codePromo, setCodePromo] = useState('')
  const [codePromoObject, setCodePromoObject] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorCodePromo, setErrorCodePromo] = useState(false)
  const [refresh, setRefresh] = useState(1)

  if (sessionStorage.getItem('offerSelected')) {
    if (!offreSelect)
      setOffreSelect(JSON.parse(sessionStorage.getItem('offerSelected')))
    if (periodeSelect === 0)
      setPeriodeSelect(JSON.parse(sessionStorage.getItem('periodeSelected')))
  }

  /*  if ($codePromo->getType() === 1) {
    $total = $result->getPrix() - (($result->getPrix() * $codePromo->getRemise()) / 100);
} else {
    $total = $result->getPrix() - $codePromo->getRemise();
} */

  function onClose() {
    setCurrentStep(1)
  }

  function onSuccess() {
    setCurrentStep(2)
  }

  function nextStep() {
    setCurrentStep(currentStep + 1)
  }

  const handleCodePromo = async () => {
    setLoading(true)
    setErrorCodePromo(false)
    console.log(codePromo)
    try {
      const result = await Api.siteVitrine.getCodePromo(codePromo)

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        setCodePromoObject(res)
        setCodePromo('')
        //onSuccess()
      } else {
        setErrorCodePromo(true)
      }
    } catch (error) {
      console.log(error)
      setErrorCodePromo(true)
    }
    setLoading(false)
  }

  return (
    <div>
      <main>
        <Container maxWidth={'lg'}>
          <Grid container mt={2} mb={2} justifyContent="center">
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
                justifyContent: { xs: 'center', lg: 'space-between' },
                marginTop: { xs: '10px', lg: '50px' },
                rowGap: { xs: '10px', lg: '20px' },
                width: '100%',
                height: '100%',
              }}
            >
              <ContentBox>
                <Typography
                  variant={'h1'}
                  textAlign={{ xs: 'center', lg: 'left' }}
                >
                  {i18n.language === 'fr'
                    ? offreSelect.titre
                    : offreSelect.titreEn}
                </Typography>
                <ContentBody>
                  <Typography variant={'h2'}>
                    {' '}
                    {t('button.period')} : {periodeSelect} {t('box.mounth')}
                  </Typography>
                  <Typography variant={'h2'}>
                    {codePromoObject?.code && (
                      <>
                        {t('profile.price')} :{' '}
                        {(
                          offreSelect[`prix${periodeSelect}`] *
                          JSON.parse(sessionStorage.getItem('currency'))
                            .currentRate
                        ).toFixed(2)}{' '}
                        {sessionStorage.getItem('currency')
                          ? JSON.parse(sessionStorage.getItem('currency'))
                              .devise
                          : 'CHF'}
                        <br></br>
                      </>
                    )}
                    {codePromoObject?.code
                      ? t('profile.firstPrice')
                      : t('profile.price')}{' '}
                    :{' '}
                    {codePromoObject?.code && codePromoObject?.type === 1
                      ? (
                          (offreSelect[`prix${periodeSelect}`] -
                            (offreSelect[`prix${periodeSelect}`] *
                              codePromoObject?.remise) /
                              100) *
                          JSON.parse(sessionStorage.getItem('currency'))
                            .currentRate
                        ).toFixed(2)
                      : codePromoObject?.code && codePromoObject?.type === 1
                      ? (
                          (offreSelect[`prix${periodeSelect}`] -
                            codePromoObject?.remise) *
                          JSON.parse(sessionStorage.getItem('currency'))
                            .currentRate
                        ).toFixed(2)
                      : (
                          offreSelect[`prix${periodeSelect}`] *
                          JSON.parse(sessionStorage.getItem('currency'))
                            .currentRate
                        ).toFixed(2)}{' '}
                    {sessionStorage.getItem('currency')
                      ? JSON.parse(sessionStorage.getItem('currency')).devise
                      : 'CHF'}
                  </Typography>

                  <Typography variant={'subtitle2'}>
                    {t('profile.offerContent')} :
                  </Typography>

                  <ul>
                    {offreSelect?.permissions
                      ?.sort((a, b) => a.id - b.id)
                      .map((permission, index) => (
                        <li>
                          {i18n.language === 'fr'
                            ? permission.nom
                            : permission.nomEn}
                        </li>
                      ))}
                  </ul>
                </ContentBody>
              </ContentBox>
              <Box width={{ xs: '100%', lg: '40%', position: 'relative' }}>
                <CardContainer style={{ position: 'relative !important' }}>
                  {/* {currentStep === 1 && (
                  <form style={{ height: '100%', position: 'relative' }}>
                    <Grid
                      container
                      spacing={2}
                      className={'gridContainerRegister'}
                    >
                      
                    </Grid>
                  </form>)} */}
                  {currentStep === 1 && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '30px',
                          position: 'absolute',
                          top: '20px',
                          width: 'calc(100% - 47px)',
                          height: 'calc(100% - 47px)',
                        }}
                      >
                        <Typography
                          variant={'subtitle2'}
                          textTransform={'uppercase'}
                        >
                          {t('box.title.paimentOffer')} "
                          {i18n.language === 'fr'
                            ? offreSelect.titre
                            : offreSelect.titreEn}
                          " -{' '}
                          {codePromoObject?.code && codePromoObject?.type === 1
                            ? (
                                (offreSelect[`prix${periodeSelect}`] -
                                  (offreSelect[`prix${periodeSelect}`] *
                                    codePromoObject?.remise) /
                                    100) *
                                JSON.parse(sessionStorage.getItem('currency'))
                                  .currentRate
                              ).toFixed(2)
                            : codePromoObject?.code &&
                              codePromoObject?.type === 1
                            ? (
                                (offreSelect[`prix${periodeSelect}`] -
                                  codePromoObject?.remise) *
                                JSON.parse(sessionStorage.getItem('currency'))
                                  .currentRate
                              ).toFixed(2)
                            : (
                                offreSelect[`prix${periodeSelect}`] *
                                JSON.parse(sessionStorage.getItem('currency'))
                                  .currentRate
                              ).toFixed(2)}{' '}
                          {sessionStorage.getItem('currency')
                            ? JSON.parse(sessionStorage.getItem('currency'))
                                .devise
                            : 'CHF'}
                        </Typography>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            width: 250,
                            maxWidth: '100%',
                            textAlign: 'center',
                          }}
                        >
                          {codePromoObject?.code ? (
                            <Typography variant={'p'}>
                              {t('profile.applyCodePromo')}
                            </Typography>
                          ) : (
                            <TextField
                              fullWidth
                              id="standard-name"
                              label={t('profile.haveYouCodePromo')}
                              placeholder={t('profile.enterCodePromo')}
                              variant="standard"
                              value={codePromo}
                              error={errorCodePromo && true}
                              helperText={
                                errorCodePromo && t('errorMessage.codePromo')
                              }
                              onChange={(event) => {
                                setCodePromo(event.target.value)
                                setErrorCodePromo(false)
                              }}
                              InputProps={{
                                endAdornment:
                                  codePromo && !loading ? (
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      fontSize={20}
                                      style={{ cursor: 'pointer' }}
                                      onClick={handleCodePromo}
                                    />
                                  ) : loading ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <></>
                                  ),
                              }}
                            />
                          )}
                        </Grid>
                        <Box sx={{ height: '100%' }}>
                          {loading ? (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          ) : (
                            <StripeAppTest
                              userId={userData?.data.id}
                              type={'newOfferEntreprise'}
                              offreId={offreSelect.id}
                              offrePeriode={periodeSelect}
                              codePromoObject={codePromoObject}
                              offrePrice={
                                codePromoObject?.code &&
                                codePromoObject?.type === 1
                                  ? (
                                      (offreSelect[`prix${periodeSelect}`] -
                                        (offreSelect[`prix${periodeSelect}`] *
                                          codePromoObject?.remise) /
                                          100) *
                                      JSON.parse(
                                        sessionStorage.getItem('currency')
                                      ).currentRate
                                    ).toFixed(2)
                                  : codePromoObject?.code &&
                                    codePromoObject?.type === 1
                                  ? (
                                      (offreSelect[`prix${periodeSelect}`] -
                                        codePromoObject?.remise) *
                                      JSON.parse(
                                        sessionStorage.getItem('currency')
                                      ).currentRate
                                    ).toFixed(2)
                                  : (
                                      offreSelect[`prix${periodeSelect}`] *
                                      JSON.parse(
                                        sessionStorage.getItem('currency')
                                      ).currentRate
                                    ).toFixed(2)
                              }
                              onSuccess={onSuccess}
                              onClose={onClose}
                            />
                          )}
                        </Box>
                        <Box
                          sx={{
                            width: 'calc(100% - 10px)',
                            display: 'flex',
                            flexDirection: 'row',
                            columnGap: '15px',
                            justifyContent: 'flex-end',
                            alignItems: 'end',
                          }}
                        >
                          <Box width={'100%'} zIndex={1}>
                            <BorderButton
                              type={'basic'}
                              displayIconRight={'none'}
                              handleClick={() =>
                                navigate('/entreprises/offres')
                              }
                            >
                              {t('button.viewOffers')}
                            </BorderButton>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}

                  {currentStep === 2 && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '50px',
                          position: 'absolute',
                          top: '20px',
                          width: 'calc(100% - 47px)',
                          height: 'calc(100% - 47px)',
                        }}
                      >
                        <Typography
                          variant={'subtitle2'}
                          textTransform={'uppercase'}
                        >
                          {t('successMessage.offerPayementOk')}
                        </Typography>
                        <Box sx={{ height: '100%' }}>
                          <Typography>
                            {t('successMessage.offerTextPayementOk')}{' '}
                            <b>{offreSelect.titre}</b>.
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: 'calc(100% - 10px)',
                            display: 'flex',
                            flexDirection: 'row',
                            columnGap: '15px',
                          }}
                        >
                          <Box width={'100%'} zIndex={1}>
                            <BorderButton
                              type={'basic'}
                              displayIconLeft={'none'}
                              handleClick={() => {
                                dispatch(userLogout())
                                navigate('/auth/se-connecter')
                              }}
                            >
                              {t('button.connect')}
                            </BorderButton>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}
                </CardContainer>
              </Box>
            </Box>
          </Grid>
        </Container>
      </main>
    </div>
  )
}
