import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import { Box, Container, Typography } from '@mui/material'
import { SectionsHeading } from '../../../../../components/headings'
import { useTranslation } from 'react-i18next'

export default function HowSection() {
  const { t } = useTranslation()
  return (
    <Box
      width={'100%'}
      component={'section'}
      sx={{
        padding: { xs: '110px 0 130px 0', md: '100px 0 ', lg: '140px 0' },
      }}
    >
      <Box width={'100%'} position={'relative'}>
        <Container
          maxWidth={'lg'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '40px',
          }}
        >
          <SectionsHeading
            type={'animation'}
            display={'flex'}
            justifyContent={'center'}
            textAlign={'center'}
            leftCircle={{ xs: '47px', sm: 0 }}
          >
            {t('page.howItWork.title')}
          </SectionsHeading>
          <Timeline position="alternate">
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="h1" component="span">
                  {t('page.howItWork.talent.title')}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="h1" component="span">
                  {t('page.howItWork.recruiter.title')}
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body1" component="span">
                  {t('page.howItWork.recruiter.subTitle')}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="body1" component="span">
                  {t('page.howItWork.talent.subTitle')}
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
                {t('page.howItWork.talent.step1')}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    width: 30,
                    height: 30,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#96A0FF',
                  }}
                >
                  1
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
                {t('page.howItWork.recruiter.step1')}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
                {t('page.howItWork.recruiter.step2')}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    width: 30,
                    height: 30,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#96A0FF',
                  }}
                >
                  2
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
              {t('page.howItWork.talent.step2')}
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              ></TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineConnector />
                <Typography
                  sx={{
                    color: '#96A0FF',
                    fontWeight: 'bold',
                  }}
                >
                  Match
                </Typography>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              ></TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
                {t('page.howItWork.recruiter.step3')}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    width: 30,
                    height: 30,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#96A0FF',
                  }}
                >
                  3
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
                {t('page.howItWork.talent.step3')}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
                {t('page.howItWork.talent.step4')}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    width: 30,
                    height: 30,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#96A0FF',
                  }}
                >
                  4
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
                {t('page.howItWork.recruiter.step4')}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
              {t('page.howItWork.recruiter.step5')}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    width: 30,
                    height: 30,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#96A0FF',
                  }}
                >
                  5
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
              {t('page.howItWork.talent.step5')}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
              {t('page.howItWork.talent.step6')}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    width: 30,
                    height: 30,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#96A0FF',
                  }}
                >
                  6
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
              >
              {t('page.howItWork.recruiter.step6')}
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Container>
      </Box>
    </Box>
  )
}
