import React, { useEffect, useState } from 'react'
import { Box, Container } from '@mui/material'
import { SectionsHeading } from '../../../../../components/headings'
import { DivideCard } from '../../../../../components/cards'
import { SectionLayout } from '../../../../../components/layouts'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function OffersHome(data) {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [offerCandidats, setOfferCandidats] = useState([])
  const [offerCompanys, setOfferCompanys] = useState([])

  useEffect(() => {
    setOfferCandidats(data.offresCandidat)
  }, [data.offresCandidat])

  useEffect(() => {
    setOfferCompanys(data.offresEntreprises)
  }, [data.offresEntreprises])

  return (
    <SectionLayout component={'section'}>
      <Container
        maxWidth={'xl'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          rowGap: { xs: '50px', md: '70px' },
        }}
      >
        <SectionsHeading
          type={'basic'}
          display={'flex'}
          justifyContent={'center'}
          width={{ xs: 210, sm: 240, lg: 320 }}
        >
          {t(`home.offersTitle`)}
        </SectionsHeading>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            columnGap: '30px',
            rowGap: '50px',
          }}
        >
          <DivideCard
            items={offerCandidats}
            clickButton={() => navigation('/candidats')}
          />
          <DivideCard
            items={offerCompanys}
            clickButton={() => navigation('/entreprise')}
          />
        </Box>
      </Container>
    </SectionLayout>
  )
}
