import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, styled, MenuItem } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'

const CustomSelect = styled(Select)(({ theme }) => ({
  width: '100%',
  height: 45,
  borderWidth: 0,
  borderRadius: 0,
  backgroundColor: lightTheme.palette.primary.main,
  color: '#FFFFFF',
  fontFamily: 'Century Gothic Bold',
  textTransform: 'uppercase',
  cursor: 'pointer',
  position: 'relative',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .css-1irc8sz-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper': {
    background: 'red',
  },

  [theme.breakpoints.up('lg')]: {
    height: 50,
  },
}))

export default function SelectPays() {
  const { t } = useTranslation()
  const [codePays, setCodePays] = useState(
    localStorage.getItem('codePays') ? localStorage.getItem('codePays') : 'ch'
  )

  const handlePaysChange = (evt) => {
    const codePays = evt.target.value
    setCodePays(codePays)
    localStorage.setItem('codePays', codePays)
    handleDeviseChange(codePays)
  }

  const handleDeviseChange = (codePays) => {
    const currency = {
      devise: codePays === "ch" ? "CHF" : "EUR",
      currentRate: 1,
      rate: 1,
    }
    sessionStorage.setItem('currency', JSON.stringify(currency))
    window.location.reload(false)
  }

  return (
    <CustomSelect
      onChange={handlePaysChange}
      /* value={codePays} */
      defaultValue={'county'}
      IconComponent={() => (
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            position: 'absolute',
            right: '28px',
            display: 'none'
          }}
          onChange={handlePaysChange}
        />
      )}
    >
      <MenuItem sx={{ display: 'none' }} value="county">
        {t('contact.country')} <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            position: 'absolute',
            right: '25px',
            marginTop: '2px'
          }}
          onChange={handlePaysChange}
        />
      </MenuItem>
      <MenuItem sx={{ background: codePays === 'ch' ? '#808bf814' : '' }} value="ch">
        <img
          src="https://flagsapi.com/CH/flat/16.png"
          style={{ verticalAlign: 'text-bottom', marginRight: '5px' }}
        />
        {t('header.chCountry')}
      </MenuItem>
      <MenuItem sx={{ background: codePays === 'fr' ? '#808bf814' : '' }} value="fr">
        <img
          src="https://flagsapi.com/FR/flat/16.png"
          style={{ verticalAlign: 'text-bottom', marginRight: '5px' }}
        />
        {t('header.frCountry')}
      </MenuItem>
    </CustomSelect>
  )
}
