import {
  Box,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment/moment'
import lightTheme from '../../styles/theme/lightTheme'
import * as React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCreditCard,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const BodyTab = styled(Tab)(({ theme }) => ({
  color: lightTheme.palette.primary.main,
  fontFamily: 'Century Gothic Bold',
  fontSize: 12,
  letterSpacing: '0.2px',
  lineHeight: '140%',

  '&.Mui-selected': {
    background: lightTheme.palette.grey.light,
    color: lightTheme.palette.grey.main,
  },
}))

const TablePanelBox = styled(TabPanel)(({ theme }) => ({
  height: 280,
  backgroundColor: 'rgb(227, 230, 255, 0.5)',
  '& .MuiBox-root': {
    padding: 0,
  },
}))

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 50,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleHead = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const BodyTable = styled(TableBody)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '2px solid rgb(227, 230, 255, 0.5)',
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const IconBox = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 auto',

  '&:hover': {
    borderWidth: '2px',
  },
  '&:active': {
    borderWidth: '1px',
  },
}))

const AtelierTable = ({ ateliers, handlePayementAtelier }) => {
  const { t } = useTranslation()
  return (
    <TablePanelBox>
      {ateliers && (
        <Table>
          <HeaderTab>
            <TableRow>
              <TitleHead>{t('profile.date')}</TitleHead>
              <TitleHead>{t('header.atelier')}</TitleHead>
              <TitleHead>{t('profile.price')}</TitleHead>
              <TitleHead>{t('profile.firstPrice')}</TitleHead>
              <TitleHead>{t('profile.status')}</TitleHead>
            </TableRow>
          </HeaderTab>
          {ateliers &&
            ateliers.map((atelier, i) => (
              <BodyTable key={i}>
                <TableRow>
                  <StyledTableCell>
                    {moment(atelier.date).tz("Europe/Paris").format('DD/MM/YYYY H:mm')}
                  </StyledTableCell>
                  <StyledTableCell>{atelier.atelier.titre}</StyledTableCell>
                  <StyledTableCell>{(atelier.prix * JSON.parse(sessionStorage.getItem("currency")).currentRate).toFixed(2)} {(sessionStorage.getItem("currency")) ? JSON.parse(sessionStorage.getItem("currency")).devise : 'CHF'}</StyledTableCell>
                  <StyledTableCell>{(atelier.prixPromo * JSON.parse(sessionStorage.getItem("currency")).currentRate).toFixed(2)} {(sessionStorage.getItem("currency")) ? JSON.parse(sessionStorage.getItem("currency")).devise : 'CHF'}</StyledTableCell>
                  <StyledTableCell>
                    {atelier.status === 'payer' ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        fontSize={20}
                        color={lightTheme.palette.success.main}
                      />
                    ) : (
                      <Box
                        sx={{
                          cursor: 'pointer'
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          handlePayementAtelier(atelier)
                        }}>
                        <FontAwesomeIcon
                          icon={faCreditCard}
                          fontSize={20}
                        />
                      </Box>
                    )}
                  </StyledTableCell>
                </TableRow>
              </BodyTable>
            ))}
        </Table>
      )}
    </TablePanelBox>
  )
}

export default AtelierTable
