import React, { useEffect, useState } from "react";
import { Box, CircularProgress, FormLabel, Grid, Modal, styled, Typography } from "@mui/material";
import { BorderButtonPurple, CustomButton } from "../buttons";
import lightTheme from "../../styles/theme/lightTheme";
import "../../styles/components/_box.scss";
import "../../styles/components/_grids.scss";
import "../../styles/components/_inputs.scss";
import { TagsInput, TextInputAdmin, TextInputClassic } from "../inputs";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Api from "../../services/api.service";
import { handlingErrors } from "../../utils";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

const ModalContainer = styled(Box)(({theme}) => ({
  width: "calc(100% - 50px)",
  height: 612,
  backgroundColor: "#FFFFFF",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "35px",

  "&:focus-visible": {
    outline: "none"
  },

  [theme.breakpoints.up("sm")]: {
    width: 620,
    height: 450,
  },


}));

const BoxContainer =  styled(Box)(({theme}) => ({
  width: "100%",
  height: 350,
  backgroundColor: lightTheme.palette.primary.b6,
  padding:"20px",

  [theme.breakpoints.up("sm")]: {
    height: 250,
  },
}));

const schema = yup.object({
  titre: yup.string().required("Merci de rentrer le titre de votre poste/profil"),
  technos: yup.string().required()
})

const AddProfilModal = ({open, onClose}) => {
  const { t, i18n } = useTranslation()
  let navigate = useNavigate();
  const [id, setId] = useState(null);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
 /* const [open, setOpen] = useState(false);*/
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [loadingAddAdmin, setLoadingAddAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postes, setPostes] = useState([]);
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    getPostes();
  }, [id]);

  const getPostes = async () => {
    setLoading(true);

    try {
      const result = await Api.poste.listWithFilter({
        status: true,
      });
      const res = handlingErrors(result);

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
        window.scrollTo(0, 0);
      }

      if (res.ok) {
        setPostes(res.data);
      }

      if (res.status == 0) {
        setMsg(res.message);
        setErr(true);

      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
      });
      window.scrollTo(0, 0);
    }

    setLoading(false);
  };
  const addPoste = async (data) => {
    setLoadingAddAdmin(true);

    setErr(false);
    try {
      const result = await Api.user.add(data);
      const res = handlingErrors(result);
      if (res.ok) {
        toast.success("La création de l'admin à été réalisé avec succès !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleCheck} fontSize={25} color={lightTheme.palette.success.main}/>
        });
        await getPostes();

        setTimeout(() => {
          navigate("/admin");
        }, 1000);
      }

      if (res.status === 422) {
        setError("email", { message: res.detail });
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
        window.scrollTo(0, 0);
        return;
      }

      if (res.status == 0) {
        setMsg(res.message);
        setErr(true);

      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
      });
    }
    setLoadingAddAdmin(false);
  };

  const onSubmit = (data) => {
    const payload = {
      ...data,
      status: true,
      lang: "fr",
      technos: [data.technos]
    };
    addPoste(payload);
  };


  return (
    <Modal
      open={open}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(51, 51, 51, 0.65)",
        },
      }}
    >
      <ModalContainer>
        <Typography variant={"subtitle2"} textTransform={"uppercase"}>Ajouter un profil / poste</Typography>
        <BoxContainer>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} >
                <FormLabel className={"labelForm"}>Nom du poste / profil</FormLabel>
                <TextInputClassic
                  placeholder={"Nom du poste / profil"}
                  register={{...register("titre")}}
                />
              </Grid>
              <TagsInput
                maxTagCount="5"
                register={{...register("techno")}}
              />
            </Grid>
          </form>
        </BoxContainer>
        <Box className={"blockButtons"}>
          {loadingAddAdmin ? (
            <CircularProgress/>
          ) : (
            <>
              <BorderButtonPurple displayIcon={"none"} handleClick={onClose}>Annuler</BorderButtonPurple>
              <CustomButton
                displayIcon={"none"}
                handleClick={handleSubmit(onSubmit)}
              >
                Enregistrer
              </CustomButton>
            </>
          )}
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default AddProfilModal;