import React, { useEffect, useState } from 'react'
import { Box, Skeleton, styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { useTranslation } from 'react-i18next'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'

const CustomBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 205,
  height: 205,

  [theme.breakpoints.up('sm')]: {
    width: 350,
    height: 350,
  },

  [theme.breakpoints.up('lg')]: {
    width: 445,
    height: 445,
  },
}))

const ImageContainer = styled(Box)(({ theme }) => ({
  width: 167,
  height: 167,
  borderRadius: '100%',
  border: '4px solid #FFFFFF',
  overflow: 'hidden',
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: 1,

  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  '.skeleton-circular': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  [theme.breakpoints.up('sm')]: {
    width: 286,
    height: 286,
  },

  [theme.breakpoints.up('lg')]: {
    width: 367,
    height: 367,
  },
}))

const CircleColor = styled(Box)(({ theme }) => ({
  width: 167,
  height: 167,
  borderRadius: '100%',
  background: lightTheme.palette.gradients.gradientGreen,
  transform: 'rotate(-180deg)',
  position: 'absolute',
  right: 0,
  bottom: 0,

  [theme.breakpoints.up('sm')]: {
    width: 286,
    height: 286,
  },

  [theme.breakpoints.up('lg')]: {
    width: 367,
    height: 367,
  },
}))

export default function RoundedImage() {
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [aboutSection, setAboutSection] = useState([])

  useEffect(() => {
    getData(i18n.language)
  }, [i18n.language])

  const getData = async (lang) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.getContenu({
        section: 'mot-fondateur',
        lang: lang,
      })
      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setAboutSection(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CustomBox>
      <>
        {aboutSection
          .map((item, index) => (
            <div key={index}>
              {!item.photos[0] ? (
                <ImageContainer>
                  <Skeleton
                    variant="circular"
                    className={'skeleton-circular'}
                    animation={'wave'}
                  />
                </ImageContainer>
              ) : (
                <ImageContainer>
                  <img
                    src={item.photos[0].lien}
                    alt={'Jobertech - Louisa Halil'}
                  />
                </ImageContainer>
              )}
            </div>
          ))}
      </>
      <CircleColor />
    </CustomBox>
  )
}
