import React, { useEffect, useState } from 'react'
import { Box, Grid, styled } from '@mui/material'
import '../../../styles/components/_box.scss'
import { SearchInputBasic, SelectInputObject } from '../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../styles/theme/lightTheme'
import {
  DeleteGeneralModal,
  EditBesoinEntreprise,
} from '../../../components/modals'
import BesoinTable from '../../../components/tables/BesoinTable'
import DetailBesoinEntreprise from '../../../components/modals/DetailBesoinEntreprise'
import { debounce } from '../../../config'
import { userLogout } from '../../../store/slices/userAuthReducer'
import { AdminHeading } from '../../../components/headings'
import UserProfilHeading from '../../../components/headings/UserProfilHeading'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROLE_CANDIDAT } from '../../../data'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const Besoins = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  const [openDetailBesoin, setOpenDetailBesoin] = useState(false)
  const [besoins, setBesoins] = useState([])
  const [besoin, setBesoin] = useState([])
  const [loadingBesoin, setLoadingBesoin] = useState(true)

  const [filter, setFilter] = useState([
    /* { id: 0, titre: t('contact.company'), filtre: 'entreprise.raisonSocial' }, */
    { id: 1, titre: t('filter.poste'), filtre: 'poste.titre' },
    { id: 2, titre: t('filter.place'), filtre: 'search' },
  ])
  const [filterSelect, setFilterSelect] = useState(filter[0])

  useEffect(() => {
    getBesoinsCompanys(1)
  }, [search])

  const handleFilterSelect = (event) => {
    const inputValue = event.target.value
    setFilterSelect(inputValue)
  }

  const handleCloseDetailBesoin = () => {
    setOpenDetailBesoin(false)
    //fetchData()
  }
  const handleDetailBesoin = (besoin) => {
    setOpenDetailBesoin(true)
    setBesoin(besoin)
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getBesoinsCompanys(page)
  }

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  const handleClickIcon = () => {
    navigate(`/user`)
  }

  const getBesoinsCompanys = async (page) => {
    setLoadingBesoin(true)

    let payload = {
      search: search,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    if (filterSelect.id != 2) {
      payload = {
        [filterSelect.filtre]: search,
      }
    }

    try {
      const result = await Api.besoinEntreprise.listWithFilter({
        page: page,
        status: 'active',
        visible: true,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setBesoins(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data['hydra:member'].sort((a, b) =>
          a.date < b.date ? 1 : -1
        ) */
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingBesoin(false)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <UserProfilHeading
          displayIconReturn={'display'}
          handleClickIcon={handleClickIcon}
        >
          {t('profile.annoncesListTitle')}
        </UserProfilHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          {/*<Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <SearchInputBasic
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </Box>*/}

          <WrapperButton sx={{ columnGap: '15px' }}>
            <Grid item>
              <SearchInputBasic onChange={handleSearchChange} />
            </Grid>
            <Grid item>
              <SelectInputObject
                items={filter}
                value={filterSelect}
                handleChange={handleFilterSelect}
              />
            </Grid>
          </WrapperButton>

          <BesoinTable
            besoins={besoins}
            loading={loadingBesoin}
            handleDetailBesoin={handleDetailBesoin}
            handlePageChange={handlePageChange}
            count={count}
            page={page}
            role={ROLE_CANDIDAT}
            showDetail={true}
          />
        </Box>
      </div>

      {openDetailBesoin && (
        <DetailBesoinEntreprise
          open={openDetailBesoin}
          onClose={handleCloseDetailBesoin}
          besoin={besoin}
          role={ROLE_CANDIDAT}
        />
      )}
    </>
  )
}

export default Besoins
