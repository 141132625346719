import React, { useEffect, useState } from 'react'
import {
  Box,
  Radio,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
  Autocomplete,
  TextField,
  Chip,
  Switch,
  Slider,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { SelectInputBasic, TextInputClassic } from '../inputs'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { userLogout } from '../../store/slices/userAuthReducer'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import {
  AVANTAGES,
  LANGUES,
  ROLE_CANDIDAT,
  ROLE_ENTREPRISE,
  SOFTSKILLS,
  TYPE_CONTRAT,
  TYPE_DEVISE,
  VALEURS,
  niveauLanguesList,
} from '../../data'
import { countries } from '../../data'
import {
  faClose,
  faSquarePlus,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
//import isoCountries from "i18n-iso-countries";

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const useStyles = styled((theme) => ({
  root: {
    '& .ql-editor': {
      minHeight: 200, // Adjust the minimum height of the editor
      backgroundColor: '#f5f5f5', // Change background color
      border: '1px solid #d1d1d1', // Example border
      padding: '10px', // Example padding
    },
  },
}))

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const EditBesoinEntreprise = ({ open, onClose, onSave, id, besoin, role }) => {
  const { t, i18n } = useTranslation()

  const proSchema1 = yup.object({
    nbrPoste: yup.number().typeError(t('errorMessage.number')),
    salaire: yup.number().typeError(t('errorMessage.number')),
    horaires: yup.string().required(t('errorMessage.required')),
    descriptifPoste: yup.string().required(t('errorMessage.required')),
  })

  const proSchema = yup.object({
    nbrPoste: yup.number().typeError(t('errorMessage.number')),
    salaire: yup.number().typeError(t('errorMessage.number')),
    horaires: yup.string().required(t('errorMessage.required')),
    descriptifPoste: yup.string().required(t('errorMessage.required')),
    avantages: yup.string().required(t('errorMessage.required')),
    reportDirect: yup.string().required(t('errorMessage.required')),
    teletravailPropose: yup.number().typeError(t('errorMessage.number')),
  })

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [langues, setLangues] = useState(besoin ? besoin.besoinLangues : [])
  const [refresh, setRefresh] = useState(1)
  const [competences, setCompetences] = useState(
    besoin ? besoin.competences : []
  )
  const [competencesAnnexes, setCompetencesAnnexes] = useState(
    besoin ? besoin.competencesAnnexes : []
  )
  const [softskils, setSoftskils] = useState(besoin ? besoin.softskils : [])
  const [valeurs, setValeurs] = useState(besoin ? besoin.valeurs : [])
  const [avantagesEntreprise, setAvantagesEntreprise] = useState(besoin ? besoin.avantagesEntreprise : [])
  const [typesContrat, setTypesContrat] = useState(TYPE_CONTRAT)
  const [typesDevise, setTypesDevise] = useState(TYPE_DEVISE)
  const [typeContratSelect, setTypeContratSelect] = useState(
    besoin ? besoin.typeContrat : TYPE_CONTRAT[0].id
  )
  const [typeDeviseSelect, setTypeDeviseSelect] = useState(
    besoin ? besoin.devise : TYPE_DEVISE[0].id
  )
  const [selectedTypeValue, setSelectedTypeValue] = useState(
    besoin ? besoin.type : t('box.jobReplacement')
  )
  const [selectedSalaireSur, setSelectedSalaireSur] = useState(
    besoin ? besoin.salaireSur : 12
  )
  const [deplacementValue, setDeplacementValue] = useState(
    besoin ? besoin.deplacement : false
  )
  const [permisValue, setPermisValue] = useState(
    besoin ? besoin.permisRequis : false
  )
  const [vehiculeValue, setVehiculeValue] = useState(
    besoin ? besoin.vehiculeRequis : false
  )
  const [loadingPoste, setLoadingPoste] = useState(false)
  const [postes, setPostes] = useState([])
  const [posteSelect, setPosteSelect] = useState(
    besoin ? besoin.poste : { titre: t('box.jobTitle') }
  )
  const [posteOpen, setPosteOpen] = React.useState(false)
  const [dateValue, setDateValue] = useState(
    besoin ? besoin.dateDebut : dayjs(new Date())
  )
  const [dateChange, setDateChange] = useState(false)
  const [step, setStep] = useState(1)
  const [selectedCountry, setSelectedCountry] = useState(
    besoin ? { label: besoin.pays } : { label: 'Switzerland' }
  )
  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState(
    besoin ? besoin.lieu : 'Genève'
  )
  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      nbrPoste: besoin ? besoin.nbrPoste : '',
      horaires: besoin ? besoin.horaires : '',
      lieu: besoin ? besoin.lieu : '',
      salaire: besoin ? besoin.salaire : '',
      pays: besoin ? besoin.pays : '',
      descriptifPoste: besoin ? besoin.descriptifPoste : '',
      avantages: besoin ? besoin.avantages : '',
      teletravailPropose: besoin ? besoin.teletravailPropose : '',
      reportDirect: besoin ? besoin.reportDirect : '',
    },
    resolver: yupResolver(step === 1 ? proSchema1 : proSchema),
  })

  useEffect(() => {
    handleCountryChange(selectedCountry, besoin)
  }, ['selectedCountry'])

  const handleCountryChange = async (country, besoin = null) => {
    setSelectedCountry(country)

    try {
      const result = await Api.countriesCities.getCitiesByCountry(country.label)

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCities(res.data.data)
        if (!besoin) setSelectedCity(res.data.data[0])
      }
    } catch (error) {
      console.debug(error)
    }
  }

  const handleChangeDeplacement = (event) => {
    setDeplacementValue(!deplacementValue)
  }

  const handleChangeVisible = (event) => {
    setVisible(!visible)
  }

  const handleChangePermis = (event) => {
    setPermisValue(!permisValue)
  }

  const handleChangeVehicule = (event) => {
    setVehiculeValue(!vehiculeValue)
  }

  const handleChangeType = (event) => {
    const inputValue = event.target.value
    setSelectedTypeValue(inputValue)
  }

  const handleChangeSalaireSur = (event) => {
    const inputValue = event.target.value
    setSelectedSalaireSur(parseInt(inputValue))
  }

  const handleTypeContratSelect = (event) => {
    const inputValue = event.target.value
    setTypeContratSelect(inputValue)
  }

  const handleTypeDeviseSelect = (event) => {
    const inputValue = event.target.value
    setTypeDeviseSelect(inputValue)
  }

  const changeDate = (dateValue) => {
    setDateValue(dateValue)
    setDateChange(true)
  }

  const nextStep = () => {
    setStep(step + 1)
  }

  const previousStep = () => {
    setStep(step - 1)
  }

  useEffect(() => {
    getPostes()
  }, [''])

  const getPostes = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingPoste(true)

    try {
      const result = await Api.poste.listWithFilter({
        status: true,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setPostes(res.data)
        const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1))
        if (besoin) {
          setPosteSelect(besoin.poste)
        } else {
          setPosteSelect(res.data[0])
        }
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingPoste(false)
  }

  const onSubmit1 = async () => {
    if (
      step === 2 &&
      (errorComp || errorCompAnn || errorSoftskills || errorValeurs)
    ) {
      return
    }
    nextStep()
  }

  const onSubmit = async (data) => {
    setLoading(true)
    let datas = {
      entreprise: '/api/entreprises/' + id,
      dateDebut: dateValue,
      typeContrat: typeContratSelect,
      devise: typeDeviseSelect,
      poste: '/api/postes/' + posteSelect.id,
      pays: selectedCountry.label,
      lieu: selectedCity,
      type: selectedTypeValue,
      salaireSur: selectedSalaireSur,
      besoinLangues: langues,
      langues: langues.map((item) => item.langue),
      deplacement: deplacementValue,
      permisRequis: permisValue,
      vehiculeRequis: vehiculeValue,
      competences: competences,
      competencesAnnexes: competencesAnnexes,
      softskils: softskils,
      valeurs: valeurs,
      avantagesEntreprise: avantagesEntreprise,
      typeTempsTravail: selectedTypeTempsTravail,
      tempPartiel: tempPartiel,
      visible: visible,
      ...data,
    }

    datas.lieu = selectedCity
    datas.pays = selectedCountry.label

    //return
    if (besoin) {
      try {
        const result = await Api.besoinEntreprise.edit(besoin.id, datas)
        const handling = handlingErrors(result)
        if (handling.status === 401) {
          dispatch(userLogout())
          return
        }

        if (handling.ok) {
          const res = handling.data
          setLoading(false)
          onSave()
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.debug('erreur submit !')
      }
    } else {
      try {
        const result = await Api.besoinEntreprise.add(datas, {})
        const handling = handlingErrors(result)
        if (handling.status === 401) {
          dispatch(userLogout())
          return
        }

        if (handling.ok) {
          const res = handling.data
          setLoading(false)
          onSave()
        } else {
          setLoading(false)
        }
      } catch (error) {
        console.debug('erreur submit !')
      }
    }
  }

  const handleAddLangue = () => {
    const lng = { langue: LANGUES[24], niveau: niveauLanguesList[0].id }
    langues.push(lng)
    setLangues(langues)
    setRefresh(Math.random())
  }

  const [selectedTypeTempsTravail, setSelectedTypeTempsTravail] = useState(
    besoin ? besoin.typeTempsTravail : 'plein'
  )
  const handleChangeTypeTempsTravail = (event) => {
    const inputValue = event.target.value
    setSelectedTypeTempsTravail(inputValue)
  }

  const MAX = 100
  const MIN = 20
  const marks = [
    {
      value: MIN,
      label: MIN + '%',
    },
    {
      value: MIN + 10,
      label: '',
    },
    {
      value: MIN + 20,
      label: MIN + 20 + '%',
    },
    {
      value: MIN + 30,
      label: '',
    },
    {
      value: MIN + 40,
      label: MIN + 40 + '%',
    },
    {
      value: MIN + 50,
      label: '',
    },
    {
      value: MIN + 60,
      label: MIN + 60 + '%',
    },
    {
      value: MIN + 70,
      label: '',
    },
    {
      value: MAX,
      label: MAX + '%',
    },
  ]
  const [tempPartiel, setTempPartiel] = useState(
    besoin ? besoin.tempPartiel : MIN
  )
  const [visible, setVisible] = useState(besoin ? besoin.visible : true)
  const handleChangeTime = (_, newValue) => {
    setTempPartiel(newValue)
  }

  const [errorComp, setErrorComp] = useState(
    competences.length === 0 ? true : false
  )

  const [errorCompAnn, setErrorCompAnn] = useState(
    competencesAnnexes.length === 0 ? true : false
  )

  const [errorSoftskills, setErrorSoftskills] = useState(
    softskils.length < 6 ? true : false
  )
  const [errorValeurs, setErrorValeurs] = useState(
    valeurs.length < 6 ? true : false
  )

  const classes = useStyles()
  const [editorHtml, setEditorHtml] = useState(
    besoin ? besoin.descriptifPoste : ''
  )

  const handleChange = (html) => {
    setEditorHtml(html)
    setValue('descriptifPoste', html)
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
        overflow: 'auto',
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {besoin ? t('box.title.editJob') : t('box.title.addJob')}

          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <BoxContainer>
          <form>
            <Grid
              container
              spacing={2}
              sx={{
                display: step === 1 ? 'flex' : 'none',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                  alignContent: 'left',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.visible')}
                  {'  '}
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={handleChangeVisible}
                        defaultChecked={visible}
                      />
                    }
                  />
                </FormLabel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobTitle')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <Autocomplete
                  disableClearable
                  value={posteSelect ? posteSelect : { titre: '' }}
                  id="asynchronous-postes"
                  sx={{ width: '100%', height: '45px' }}
                  open={posteOpen}
                  onChange={(event, newValue) => {
                    setPosteSelect(newValue)
                  }}
                  onKeyDown={(event, newInputValue) => {
                    clearTimeout(typingTimer)
                    if (event.target.value) {
                      typingTimer = setTimeout(function () {
                        getPostes(event.target.value)
                      }, doneTypingInterval)
                    }
                  }}
                  onOpen={() => {
                    setPosteOpen(true)
                  }}
                  onClose={() => {
                    setPosteOpen(false)
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.titre === value.titre
                  }
                  getOptionLabel={(option) =>
                    role !== ROLE_ENTREPRISE && role !== ROLE_CANDIDAT
                      ? option.titre + ' (' + option.titreEn + ')'
                      : i18n.language === 'fr'
                      ? option.titre
                      : option.titreEn
                  }
                  options={postes}
                  loading={loadingPoste}
                  renderInput={(params) => (
                    <TextField
                      id="text-asynchronous-postes"
                      {...params}
                      sx={{ backgroundColor: '#fff' }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingPoste ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobNumber')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <TextInputClassic
                  placeholder={t('box.jobNumber')}
                  type={'text'}
                  register={{ ...register('nbrPoste') }}
                />
                {errors.nbrPoste && (
                  <Typography className={'error-message'}>
                    {errors.nbrPoste.message}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobContractType')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <SelectInputBasic
                  type={'border'}
                  items={typesContrat}
                  value={typeContratSelect}
                  handleChange={handleTypeContratSelect}
                  //register={{ ...register('typeContrat') }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={9}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobPositionType')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    onChange={handleChangeType}
                    checked={selectedTypeValue === 'Remplacement de poste'}
                    value="Remplacement de poste"
                    control={<Radio />}
                    label={t('box.jobReplacement')}
                  />
                  <FormControlLabel
                    onChange={handleChangeType}
                    checked={selectedTypeValue === 'Création de poste'}
                    value="Création de poste"
                    control={<Radio />}
                    label={t('box.jobCreation')}
                  />
                </RadioGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobExpectedStartDate')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'fr'}
                >
                  <DatePicker
                    onChange={changeDate}
                    value={dateValue}
                    inputFormat={'DD/MM/YYYY'}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          height: 45,
                          backgroundColor: lightTheme.palette.primary.b2,
                          borderRadius: '5px',

                          '& .css-1laqsz7-MuiInputAdornment-root': {
                            height: '100%',
                            width: '50px',
                            margin: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                            '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root':
                              {
                                margin: 0,
                                color: '#FFFFFF',
                              },
                          },
                        }}
                      >
                        <input
                          ref={inputRef}
                          {...inputProps}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px 0 0 5px',
                            border: '1px solid #9E9E9E',
                            padding: '10px 15px',
                            fontFamily: 'Century Gothic',
                            fontSize: 14,
                            lineHeight: '140%',
                            letterSpacing: '0.2px',
                            color: lightTheme.palette.text.primary,
                            backgroundColor: '#FFFFFF',
                          }}
                        />
                        {InputProps?.endAdornment}
                      </Box>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobPositionHours')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <TextInputClassic
                  placeholder={'Ex : 9h-16h'}
                  type={'text'}
                  register={{ ...register('horaires') }}
                />
                {errors.horaires && (
                  <Typography className={'error-message'}>
                    {errors.horaires.message}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobSalary')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <TextInputClassic
                  placeholder={'Ex : 10000'}
                  type={'text'}
                  register={{ ...register('salaire') }}
                />
                {errors.salaire && (
                  <Typography className={'error-message'}>
                    {errors.salaire.message}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobCurrency')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <SelectInputBasic
                  type={'border'}
                  items={typesDevise}
                  value={typeDeviseSelect}
                  handleChange={handleTypeDeviseSelect}
                  //register={{ ...register('typeContrat') }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobSalaryOn')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    onChange={handleChangeSalaireSur}
                    checked={selectedSalaireSur === 12}
                    value="12"
                    control={<Radio />}
                    label={'12 ' + t('box.mounth')}
                  />
                  <FormControlLabel
                    onChange={handleChangeSalaireSur}
                    checked={selectedSalaireSur === 13}
                    value="13"
                    control={<Radio />}
                    label={'13 ' + t('box.mounth')}
                  />
                  <FormControlLabel
                    onChange={handleChangeSalaireSur}
                    checked={selectedSalaireSur === 14}
                    value="14"
                    control={<Radio />}
                    label={'14 ' + t('box.mounth')}
                  />
                </RadioGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobCountry')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <Autocomplete
                  sx={{
                    width: '100%',
                    backgroundColor: '#fff',
                  }}
                  value={selectedCountry ? selectedCountry : { label: '' }}
                  onChange={(event, newValue) => {
                    handleCountryChange(newValue)
                  }}
                  id="country-select"
                  options={countries}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobLocation')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>

                <Autocomplete
                  sx={{
                    width: '100%',
                    backgroundColor: '#fff',
                  }}
                  value={selectedCity ? selectedCity : ''}
                  freeSolo={true}
                  onChange={(event, newValue) => {
                    setSelectedCity(newValue)
                  }}
                  id="country-select"
                  options={cities}
                  autoHighlight
                  //getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      onChange={(event) => {
                        setSelectedCity(event.target.value)
                      }}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>Langues</FormLabel>
                <Autocomplete
                  sx={{
                    width: '100%',
                    backgroundColor: '#fff',
                  }}
                  multiple
                  options={LANGUES}
                  defaultValue={langues}
                  freeSolo
                  onChange={(event, newValue) => {
                    setLangues(newValue)
                  }}
                  renderTags={(langues, getTagProps) =>
                    langues.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ option })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Ajouter les langues" />
                  )}
                />
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobDescription')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>

                <div
                  className={classes.root}
                  style={{ backgroundColor: '#fff' }}
                >
                  <ReactQuill
                    value={editorHtml}
                    onChange={handleChange}
                    backgroundColor={'#fff'}
                  />
                </div>
                {errors.descriptifPoste && (
                  <Typography className={'error-message'}>
                    {errors.descriptifPoste.message}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                display: step === 2 ? 'flex' : 'none',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobBenefits')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <TextInputClassic
                  placeholder={t('box.jobBenefits')}
                  type={'text'}
                  register={{ ...register('avantages') }}
                />
                {errors.avantages && (
                  <Typography className={'error-message'}>
                    {errors.avantages.message}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobTelework')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <TextInputClassic
                  placeholder={'0'}
                  type={'text'}
                  register={{ ...register('teletravailPropose') }}
                />
                {errors.teletravailPropose && (
                  <Typography className={'error-message'}>
                    {errors.teletravailPropose.message}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.timeWorkType')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    onChange={handleChangeTypeTempsTravail}
                    checked={selectedTypeTempsTravail === 'plein'}
                    value="plein"
                    control={<Radio />}
                    label={t('box.timeWorkType1')}
                  />
                  <FormControlLabel
                    onChange={handleChangeTypeTempsTravail}
                    checked={selectedTypeTempsTravail === 'partiel'}
                    value="partiel"
                    control={<Radio />}
                    label={t('box.timeWorkType2')}
                  />
                </RadioGroup>
              </Grid>
              {selectedTypeTempsTravail === 'partiel' && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <FormLabel className={'labelForm'}>
                    {t('box.timeWorkType2')}
                    <span
                      style={{
                        color: lightTheme.palette.error.main,
                      }}
                    >
                      *
                    </span>
                  </FormLabel>
                  <Box sx={{ width: '90%', margin: '0' }}>
                    <Slider
                      marks={marks}
                      step={10}
                      value={tempPartiel}
                      valueLabelDisplay="auto"
                      min={MIN}
                      max={MAX}
                      onChange={handleChangeTime}
                    />
                  </Box>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              ></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.jobReport')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <TextInputClassic
                  placeholder={''}
                  type={'text'}
                  register={{ ...register('reportDirect') }}
                />
                {errors.reportDirect && (
                  <Typography className={'error-message'}>
                    {errors.reportDirect.message}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('filter.competences')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>{' '}
                  (max 6)
                </FormLabel>
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  defaultValue={competences}
                  options={[]}
                  onChange={(event, newValue) => {
                    if (newValue.length > 6) newValue.slice(0, 6)

                    if (newValue.length === 0) {
                      setErrorComp(true)
                    } else {
                      setErrorComp(false)
                    }
                    setCompetences(newValue.slice(0, 6))
                  }}
                  freeSolo
                  renderTags={(competences, getTagProps) =>
                    competences
                      .slice(0, 6)
                      .map((competence, index) => (
                        <Chip
                          variant="outlined"
                          label={competence}
                          {...getTagProps({ index })}
                        />
                      ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('filter.competencesTextCandidat')}
                    />
                  )}
                />
                {errorComp && (
                  <Typography className={'error-message'}>
                    {t('errorMessage.listComp')}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('filter.competencesAnnexes')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  defaultValue={competencesAnnexes}
                  options={[]}
                  onChange={(event, newValue) => {
                    if (newValue.length === 0) {
                      setErrorCompAnn(true)
                    } else {
                      setErrorCompAnn(false)
                    }
                    setCompetencesAnnexes(newValue)
                  }}
                  freeSolo
                  renderTags={(competencesAnnexes, getTagProps) =>
                    competencesAnnexes.map((competenceAnnexe, index) => (
                      <Chip
                        variant="outlined"
                        label={competenceAnnexe}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('filter.competencesTextCandidat')}
                    />
                  )}
                  /* disabled={selectedValues.length === 5} */
                />
                {errorCompAnn && (
                  <Typography className={'error-message'}>
                    {t('errorMessage.listCompAnn')}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('filter.softskils')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  defaultValue={softskils}
                  options={SOFTSKILLS[i18n.language].sort()}
                  onChange={(event, newValue) => {
                    if (newValue.length < 6) {
                      setErrorSoftskills(true)
                    } else {
                      setErrorSoftskills(false)
                    }
                    setSoftskils(newValue)
                  }}
                  renderTags={(softskils, getTagProps) =>
                    softskils.map((softskill, index) => (
                      <Chip
                        variant="outlined"
                        label={softskill}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder={' '} />
                  )}
                />
                {errorSoftskills && (
                  <Typography className={'error-message'}>
                    {t('errorMessage.listCompSoftskills')}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('filter.values')}
                  <span
                    style={{
                      color: lightTheme.palette.error.main,
                    }}
                  >
                    *
                  </span>
                </FormLabel>
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  defaultValue={valeurs}
                  options={VALEURS[i18n.language]}
                  onChange={(event, newValue) => {
                    if (newValue.length < 6) {
                      setErrorValeurs(true)
                    } else {
                      setErrorValeurs(false)
                    }
                    setValeurs(newValue)
                  }}
                  renderTags={(valeurs, getTagProps) =>
                    valeurs.map((valeur, index) => (
                      <Chip
                        variant="outlined"
                        label={valeur}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder={' '} />
                  )}
                />
                {errorValeurs && (
                  <Typography className={'error-message'}>
                    {t('errorMessage.list')}
                  </Typography>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('filter.benefits')}
                </FormLabel>
                <Autocomplete
                  sx={{ width: '100%', backgroundColor: '#fff' }}
                  multiple
                  freeSolo
                  defaultValue={avantagesEntreprise}
                  options={AVANTAGES[i18n.language]}
                  onChange={(event, newValue) => {
                    /* if (newValue.length < 6) {
                      setErrorValeurs(true)
                    } else {
                      setErrorValeurs(false)
                    } */
                    setAvantagesEntreprise(newValue)
                  }}
                  renderTags={(avantagesEntreprise, getTagProps) =>
                    avantagesEntreprise.map((avantage, index) => (
                      <Chip
                        variant="outlined"
                        label={avantage}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder={' '} />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeDeplacement}
                      defaultChecked={deplacementValue}
                    />
                  }
                  label={t('box.jobTrip')}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangePermis}
                      defaultChecked={permisValue}
                    />
                  }
                  label={t('box.jobPermit')}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={handleChangeVehicule}
                      defaultChecked={vehiculeValue}
                    />
                  }
                  label={t('box.jobVehicle')}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                display: step === 3 ? 'flex' : 'none',
                flexDirection: { xs: 'column', sm: 'column' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '5px',
                  minHeight: '400px',
                }}
              >
                <FormLabel className={'labelForm'}>
                  {t('filter.languages')}
                </FormLabel>
                {langues.map((lng, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      sx={{
                        padding: 1,
                      }}
                    >
                      <Autocomplete
                        sx={{
                          width: '100%',
                          backgroundColor: '#fff',
                        }}
                        options={LANGUES}
                        value={lng.langue}
                        freeSolo
                        onChange={(event, newValue) => {
                          langues[index].langue = newValue
                          setLangues(langues)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t('box.addLanguages')}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      sx={{
                        padding: 1,
                      }}
                    >
                      <Autocomplete
                        disableClearable
                        value={niveauLanguesList[lng.niveau - 1]}
                        sx={{ width: '100%', height: '45px' }}
                        onChange={(event, newValue) => {
                          langues[index].niveau = newValue.id
                          setLangues(langues)
                          setRefresh(Math.random())
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.titre === value.titre
                        }
                        getOptionLabel={(option) => option.titre}
                        options={niveauLanguesList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ backgroundColor: '#fff' }}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      sx={{
                        padding: 1,
                        alignContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        fontSize={20}
                        style={{ marginTop: '10px', cursor: 'pointer' }}
                        onClick={(e) => {
                          e.preventDefault()
                          langues.splice(index, 1)
                          setLangues(langues)
                          setRefresh(Math.random())
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
                <Box
                  width={'100%'}
                  border={'1px dotted #666'}
                  padding={1}
                  borderRadius={1}
                  sx={{ cursor: 'pointer' }}
                  onClick={handleAddLangue}
                >
                  <FontAwesomeIcon
                    icon={faSquarePlus}
                    fontSize={20}
                    color={'#666'}
                    style={{ margin: '0 5px -2px 0' }}
                  />
                  {t('box.addOtherLanguage')}
                </Box>
              </Grid>
            </Grid>
          </form>
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple
            displayIcon={'none'}
            handleClick={step === 1 ? onClose : previousStep}
          >
            {step === 1 ? t('button.cancel') : t('button.back')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={
                step < 3 ? handleSubmit(onSubmit1) : handleSubmit(onSubmit)
              }
            >
              {step < 3 ? t('button.next') : t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditBesoinEntreprise
