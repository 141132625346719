import React, { useEffect, useState } from 'react'
import SearchTalentSection from './components/SearchTalentSection'
import RecruitmentSection from './components/RecruitmentSection'
import MethodsSection from './components/MethodsSection'
import OptimizationSection from './components/OptimizationSection'
import StickyNavbar from '../../../../components/navbar/StickyNavbar'
import { useTranslation } from 'react-i18next'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { CircularProgress } from '@mui/material'
import OffresSection from './components/OffresSection'

export default function Company() {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [companyData, setCompanyData] = useState([])

  const data = [
    {
      id: 1,
      title: `${t('stickyNav.companyService')}`,
      list: [
        {
          text: `${t('stickyNav.recruitment')}`,
        },
        {
          text: `${t('stickyNav.searchOptimization')}`,
        },
      ],
      button: `${t('button.discuss')}`,
    },
  ]

  useEffect(() => {
    getCompanyData(i18n.language)
  }, [i18n.language])

  const getCompanyData = async (lang) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: lang,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setCompanyData(res.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) {
    return (
      <div>
        <StickyNavbar items={data} width={400} />
        <main style={{ marginTop: '80px' }}>
          <CircularProgress />
        </main>
      </div>
    )
  }

  return (
    <div>
      <StickyNavbar items={data} width={400} />
      <main style={{ marginTop: '-80px' }}>
        <SearchTalentSection
          rechercheTalents={companyData.filter(
            (data) => data.section === 'recherche-talents'
          )}
        />
        <RecruitmentSection
          recrutement={companyData.filter(
            (data) => data.section === 'recrutement'
          )}
        />
        <MethodsSection
          accesReseau={companyData.filter(
            (data) => data.section === 'acces-reseau'
          )}
          recrutementResultat={companyData.filter(
            (data) => data.section === 'recrutement-resultat'
          )}
        />
        <OptimizationSection
          optimisationRecherche={companyData.filter(
            (data) => data.section === 'optimisation-recherche'
          )}
        />
      </main>
    </div>
  )
}
