import React  from "react";
import { Box, Skeleton, styled, Typography } from "@mui/material";
import smallLogo from "../../assets/images/logos/smallLogo.svg";
import lightTheme from "../../styles/theme/lightTheme";
import {CustomButton} from "../buttons";
import { useNavigate } from "react-router-dom";
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";

const CardContainer = styled(Box)(({theme}) => ({
    width: 350,
    height: "auto",
    minHeight: 380,
    borderRadius: "25px",
    boxShadow: lightTheme.palette.shadow.cardOffers,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#FFFFFF",

    [theme.breakpoints.up("sm")]: {
        width: 650,
        height: 620,
    },

    [theme.breakpoints.up("lg")]: {
        /*width: 510,
        height: 520,*/
        width: "50%",
        height: "auto"
    },
}));

const ImageContainer = styled(Box)(({theme}) => ({
    width: 25,
    height: 25,
    position: "absolute",
    left: 0,

    img: {
        position: "absolute",
        width: "100%"
    },

    [theme.breakpoints.up("sm")]: {
        width: 30,
        height: 30,
    },
}));

const ListWrap = styled('ul')(({theme}) => ({
    padding: 0,
    display: "flex",
    flexDirection: "column",
    rowGap: "15px",
    position: "relative",

    [theme.breakpoints.up("sm")]: {
        rowGap: "30px",
    },
}));

const ListItem = styled('li')(({theme}) => ({
    display: "flex",
    columnGap: "25px",
    minHeight: 25,
    alignItems: "start",
    paddingLeft: "50px",

    [theme.breakpoints.up("sm")]: {
        minHeight: 30,
        paddingLeft: "55px"
    },
}));

const Card = ({items}) => {
    const navigation = useNavigate();
    const { t, i18n } = useTranslation();

    return (
      <>
          {items.points ? (
            <>
                <Box
                  sx={{
                      width: {xs: "60%", sm: "50%"},
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                  }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "20px"
                        }}
                    >
                        {!items.titre ? (
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-title"}/>
                        ) : (
                          <Typography variant={"h3"} textTransform={"uppercase"}>{items.titre}</Typography>
                        )}


                        {!items.detail ? (
                          <Box width={"100%"}>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text-50"}/>
                          </Box>
                        ) : (
                          <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>
                              <Markup content={items.detail}/>
                          </Typography>
                        )}


                        <Box>
                            <List lists={items.points} />
                        </Box>
                    </Box>


                    <Box>
                        <CustomButton
                          displayIcon={"none"}
                          type={"button"}
                          handleClick={() => navigation(`/${items.titre}`)}
                        >
                            {t('button.findMore')}
                        </CustomButton>
                    </Box>

                </Box>
                <Box
                  sx={{
                      width: {xs: "40%", sm: "50%"},
                      height: "100%",
                      borderRadius: "25px",
                      overflow: "hidden",
                      position: "relative",

                      "& .roundImage": {
                          borderRadius: "25px",
                      },

                      img: {
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover"
                      }
                  }}
                >

                    {!items.photos[0] ? (
                      <Skeleton variant={"rounded"} width={"100%"} height={"100%"}/>
                    ) : (
                      <img
                        src={items.photos[0].lien}
                        alt={`Jobertech - ${items.photos[0].titre}`}
                        className="roundImage"
                      />
                      )}

                    <Box
                      sx={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          background: lightTheme.palette.gradients.gradientGreen,
                          opacity: "0.5",
                          zIndex: 1,
                      }}
                    />
                </Box>
            </>
          ) : (
            <></>
          )}
      </>
    )
}


const List = ({lists}) => {

    return (
      <ListWrap>

          {lists[1]?.length > 0 ? (
            <ListItem>
                <ImageContainer>
                    <img src={smallLogo} alt={"Jobertech"}/>
                </ImageContainer>

                <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>{lists[1]}</Typography>
            </ListItem>
          ):(
            <></>
          )}

          {lists[2]?.length > 0 ? (
            <ListItem>
                <ImageContainer>
                    <img src={smallLogo} alt={"Jobertech"}/>
                </ImageContainer>

                <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>{lists[2]}</Typography>
            </ListItem>
          ):(
            <></>
            )}

          {lists[3]?.length > 0  ? (
            <ListItem>
                <ImageContainer>
                    <img src={smallLogo} alt={"Jobertech"}/>
                </ImageContainer>

                <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>{lists[3]}</Typography>
            </ListItem>
          ):(
            <></>
          )}

          {lists[4]?.length > 0  ? (
            <ListItem>
                <ImageContainer>
                    <img src={smallLogo} alt={"Jobertech"}/>
                </ImageContainer>

                <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>{lists[4]}</Typography>
            </ListItem>
          ):(
            <></>
          )}

          {lists[5]?.length > 0  ? (
            <ListItem>
                <ImageContainer>
                    <img src={smallLogo} alt={"Jobertech"}/>
                </ImageContainer>

                <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>{lists[5]}</Typography>
            </ListItem>
          ):(
            <></>
          )}

          {lists[6]?.length > 0  ? (
            <ListItem>
                <ImageContainer>
                    <img src={smallLogo} alt={"Jobertech"}/>
                </ImageContainer>

                <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>{lists[6]}</Typography>
            </ListItem>
          ):(
            <></>
          )}

          {lists[7]?.length > 0  ? (
            <ListItem>
                <ImageContainer>
                    <img src={smallLogo} alt={"Jobertech"}/>
                </ImageContainer>

                <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>{lists[7]}</Typography>
            </ListItem>
          ):(
            <></>
          )}

          {lists[8]?.length > 0  ? (
            <ListItem>
                <ImageContainer>
                    <img src={smallLogo} alt={"Jobertech"}/>
                </ImageContainer>

                <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>{lists[8]}</Typography>
            </ListItem>
          ):(
            <></>
          )}

          {lists[9]?.length > 0  ? (
            <ListItem>
                <ImageContainer>
                    <img src={smallLogo} alt={"Jobertech"}/>
                </ImageContainer>

                <Typography variant={"body2"} color={lightTheme.palette.grey.dark}>{lists[9]}</Typography>
            </ListItem>
          ):(
            <></>
          )}

      </ListWrap>
    )
}

export default function DivideCard ( props ) {
    return (
      <>
          {props.items  && props.items.map((item, index) => (
            <CardContainer key={index}>
                <Card items={item}/>
            </CardContainer>
          ))}
      </>
    )
}

